import {
  UseFieldArrayReturn,
  UseFormRegister,
  FieldErrors,
  FieldArrayWithId,
  useFormContext,
  UseFieldArrayAppend,
} from "react-hook-form";
import { FormValues } from ".";
import { CloneButton, DeleteButton, PlusButton } from "../../Button";
import { useState } from "react";

export const SetTable: React.FC<{
  setsFields: UseFieldArrayReturn<FormValues, "sets", "id">;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  isEditMode: boolean;
}> = ({ setsFields, isEditMode, register, errors }) => {
  const { fields: sets, append, remove } = setsFields;

  return (
    <table className="w-full m-auto text-sm text-gray-500 dark:text-gray-400 text-center">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="text-md  text-center">
          <th scope="col" className="px-6 py-3">
            Set
          </th>
          <th scope="col" className="px-6 py-3 text-center">
            Reps
          </th>
          <th scope="col" className="px-6 py-3">
            Rest
          </th>
        </tr>
      </thead>
      <tbody>
        {sets?.map((set, i) => (
          <SetRow
            key={set.id}
            set={set}
            index={i}
            register={register}
            isEditMode={isEditMode}
            remove={remove}
            append={append}
          />
        ))}
        {isEditMode && (
          <tr className="p-5 text-lg font-semibold text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <td className="px-6 py-3">
              <PlusButton
                onClick={() =>
                  append({
                    reps: 0,
                    rest: 0,
                  })
                }
                variant="icon"
              />
              <p>{errors.sets?.root?.message}</p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const SetRow: React.FC<{
  set: FieldArrayWithId<FormValues, "sets", "id">;
  index: number;
  register: UseFormRegister<FormValues>;
  isEditMode: boolean;
  remove: (index: number) => void;
  append: UseFieldArrayAppend<FormValues, "sets">;
}> = ({ set, index, isEditMode, remove, append, register }) => {
  const i = index;
  const [showRange, setShowRange] = useState(
    // @ts-ignore
    set.repsRange !== undefined && set.repsRange !== null && set.repsRange > 0
  );
  const {
    getValues,
    watch,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const errorRepsRange =
    // @ts-ignore
    (errors.sets && errors.sets[i] && errors.sets[i].repsRange) || null;
  const watchRepRange = watch(`sets.${i}.repsRange`);

  return (
    <>
      <tr key={set.id}>
        <td className="px-6 py-4">
          {isEditMode ? (
            <>
              <DeleteButton variant="icon" onClick={() => remove(i)} />
              <CloneButton
                variant="icon"
                onClick={() => append(getValues("sets")[i])}
              />
            </>
          ) : (
            i + 1
          )}
        </td>
        <td className="px-6 py-4">
          <div className="inline-flex flex-col justify-center space-y-1">
            <input
              type="text"
              disabled={!isEditMode}
              {...register(`sets.${i}.reps`, {
                valueAsNumber: true,
                min: 0,
                max: 999,
              })}
              className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            {showRange && (
              <div className="relative">
                <input
                  disabled={!isEditMode}
                  value={isNaN(watchRepRange) ? 0 : watchRepRange}
                  {...register(`sets.${i}.repsRange`, {
                    valueAsNumber: true,
                    required: false,
                    value: isNaN(watchRepRange) ? 0 : watchRepRange,
                    max: {
                      value: 999,
                      message: "Deve essere < di 999",
                    },
                    validate: (value) =>
                      // @ts-ignore
                      value >= set.reps ||
                      value === 0 ||
                      "Deve essere > delle reps",
                  })}
                  className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <div className="text-[0.5rem] leading-[0.6rem] text-ellipses">
                  <p className="">RANGE</p>
                </div>
                {errorRepsRange && isSubmitted && (
                  <span className="text-red-500 text-xs inline-block absolute left-0">
                    {errorRepsRange.message}
                  </span>
                )}
              </div>
            )}
            {!showRange && isEditMode && (
              <button
                onClick={() => setShowRange(true)}
                className="text-xs text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.6rem] px-2 py-1.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
              >
                + Range
              </button>
            )}
          </div>
        </td>
        <td className="px-6 pr-8 py-4 relative">
          <input
            type="number"
            step={15}
            max={1000}
            min={0}
            disabled={!isEditMode}
            {...register(`sets.${i}.rest`, {
              valueAsNumber: true,
              min: 0,
              max: 999,
            })}
            className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <span className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
            sec
          </span>
        </td>
      </tr>
    </>
  );
};
