import { createServer } from "miragejs";
import usersGet from "./users/users.GET.json";
import userProfileGet from "./users/user.GET.json";
import userCreatePost from "./users/create-user.POST.json";

import workoutGet from "./workouts/workout.GET.json";
import allWorkoutsGet from "./workouts/workout_all.GET.json";

import exercisesGet from "./exercises/exercises.GET.json";
import exercisesPost from "./exercises/exercises.POST.json";

import nutritionGet from "./nutrition/nutrition.GET.json";
import bodyGet from "./measures/body.GET.json";
import weightGet from "./measures/weight.GET.json";

import dietGet from "./diet/dietALT.GET.json";
import historyGet from "./history/history.GET.json";
// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return createServer({
    routes() {
      this.urlPrefix = "http://localhost:19001";
      this.namespace = "/api";
      this.get("/user", () => usersGet, { timing: 1000 });
      this.post("/user", () => userCreatePost, { timing: 1000 });
      this.get("/user/*", () => userProfileGet, { timing: 1000 });
      this.put("/user/*", () => userProfileGet, { timing: 1000 });
      this.delete("/user/*", () => userProfileGet, { timing: 1000 });

      this.post("/user/login", () => userProfileGet, { timing: 1000 });

      this.get("/workout/*", () => workoutGet, { timing: 1000 });
      this.post("/workout", () => workoutGet, { timing: 1000 });
      this.put("/workout", () => workoutGet, { timing: 1000 });
      this.delete("/workout/*", () => workoutGet, { timing: 1000 });

      this.get("/nutrition", () => nutritionGet, { timing: 1000 });

      this.get("/measures/body", () => bodyGet, { timing: 1000 });
      this.get("/measures/weight", () => weightGet, { timing: 1000 });

      this.get("/diet/*", () => dietGet, { timing: 1000 });
      this.post("/diet", () => dietGet, { timing: 1000 });
      this.put("/diet/*", () => dietGet, { timing: 1000 });
      this.delete("/diet/*", () => dietGet, { timing: 1000 });

      this.get("/workout", () => allWorkoutsGet, { timing: 1000 });

      this.get("/exercise", () => exercisesGet, { timing: 1000 });
      this.post("/exercise", () => exercisesPost, { timing: 1000 });
      this.put("/exercise/*", () => exercisesPost, { timing: 1000 });
      this.delete("/exercise/*", () => exercisesPost, { timing: 1000 });

      this.get("/history/*", () => historyGet, { timing: 1000 });
    },
  });
}
