import { DietFromApiDay, DietTableMacros } from "../../libs/diet/types";
import { getKcal } from "../../libs/utils/diet";

type AverageTableProps = {
  weeksAverage: {
    CARB: number;
    PROTEIN: number;
    FAT: number;
  }[];
};
export const AverageTable: React.FC<AverageTableProps> = ({ weeksAverage }) => {
  const rowBgColorDark = "bg-gray-50 dark:bg-gray-700";
  const rowBgColorLight = "bg-white dark:bg-gray-900";
  const rowBgColor = (index: number) =>
    index % 2 !== 0 ? rowBgColorDark : rowBgColorLight;

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
      <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="text-md">
            <th scope="col" className="px-6 py-3">
              Media Macro
            </th>
            {weeksAverage.map((week, i) => (
              <th
                key={"Week-" + (i + 1)}
                scope="col"
                className="px-6 py-3 text-center "
              >
                Week {i + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(DietTableMacros).map((row, index) => (
            <>
              <tr
                key={row + index}
                className={`${rowBgColor(index)} dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {row}
                </th>
                {weeksAverage.map((week, i) => {
                  const weekAvg = week;
                  const value = weekAvg[row as keyof typeof DietTableMacros];
                  const kcal = getKcal(
                    value,
                    row as keyof typeof DietTableMacros
                  );
                  return (
                    <td className="px-6 py-4">
                      {value} gr / {kcal} kcal
                    </td>
                  );
                })}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};
