import React, { useEffect, useState } from "react";
import { DeleteButton } from "../Button";

// Define a functional component named UploadAndDisplayImage
export const UploadAndDisplayImage: React.FC<{
  setImg: (img: string | null) => void;
  index: number;
}> = ({ setImg, index }) => {
  const [selectedImage, setSelectedImage] = useState<null | File>(null);
  const [sizeError, setSizeError] = useState(false);

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target?.result as string;
        image.onload = () => {
          console.log(image.width, image.height);
          if (image.width > 1000 || image.height > 1000) {
            setSizeError(true);
            setSelectedImage(null);
            return;
          }
          const res = e.target?.result;

          setImg(res as string);
          setSizeError(false);
        };
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage]);

  // Return the JSX for rendering
  return (
    <div>
      {/* Conditionally render the selected image if it exists */}
      {selectedImage && (
        <div className="flex flex-col items-center gap-4">
          {/* Display the selected image */}
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
          <DeleteButton onClick={() => setSelectedImage(null)}></DeleteButton>
        </div>
      )}

      <br />

      {/* Input element to select an image file */}
      <input
        type="file"
        name={"img-" + index}
        onChange={(event) => {
          const file = event?.target?.files && event.target.files[0];
          if (file) {
            setSelectedImage(file); // Update the state with the selected file
          }
        }}
      />
      {sizeError && (
        <div>L'immagine selezionata è troppo grande (max 4500x4500)</div>
      )}
    </div>
  );
};
