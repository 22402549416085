import {
  selectDietStartingMacros,
  updateStartingMacro,
} from "../../libs/diet/slice";
import {
  DietWeek,
  DietTableKeys,
  DietFromApiDay,
  DietTableMacrosItem,
  DietTableMacrosKeys,
  DietTableMacros,
} from "../../libs/diet/types";
import { formWeekToTable } from "../../libs/diet/utils";
import { getKcal } from "../../libs/utils/diet";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  getMacrosCellKeys,
  getMacrosCellInput,
  getMacrosCell,
  Input,
} from "./shared";

type DietRowProps = {
  weeks: DietFromApiDay[][];
  row: { label: string; key: string };
  index: number;
  isEditMode: boolean;
};
export const DietRow: React.FC<DietRowProps> = ({
  weeks,
  row,
  index,
  isEditMode,
}) => {
  const dispatch = useAppDispatch();
  const dietStartingMacros = useAppSelector(selectDietStartingMacros);

  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";
  if (!dietStartingMacros) return null;

  const curRowMacrosArray = Object.keys(DietTableMacros);
  curRowMacrosArray.push("TOTAL");
  return (
    <>
      <tr
        key={row.label + index}
        className={`${rowBgColor} dark:border-gray-700`}
      >
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {row.label}
        </th>
        {weeks.map((week, i) => (
          <td className="px-6 py-4">
            {/* <tr key={i + "keys"}>
              {getMacrosCellKeys(
                dietStartingMacros[row.key as DietTableKeys]
              ).map((cell) => (
                <th className="px-6">{cell}</th>
              ))}
            </tr>

            <tr key={i + "values"}>
              {isEditMode && i === 0
                ? getMacrosCellInput(
                    dietStartingMacros[row.key as DietTableKeys],
                    (e, macroKey) => {
                      dispatch(
                        updateStartingMacro({
                          tableKey: row.key as DietTableKeys,
                          macro: macroKey,
                          value: e.target.value || "0",
                        })
                      );
                    }
                  ).map((cell) => <>{cell}</>)
                : getMacrosCell(
                    formWeekToTable(i, row.key as DietTableKeys)
                  ).map((cell) => <>{cell}</>)}
            </tr> */}
            <div className="flex-column">
              {curRowMacrosArray.map((macroKey) => {
                const table = formWeekToTable(week, row.key as DietTableKeys);
                const value =
                  parseInt(table[macroKey as DietTableMacrosKeys] || "") || 0;
                const kcal =
                  macroKey === "TOTAL"
                    ? value
                    : getKcal(value, macroKey as DietTableMacrosKeys);
                return (
                  <div
                    key={i + row.key + macroKey + "macro-key"}
                    className={`flex-column  ${
                      macroKey === "TOTAL" ? "text-primary-500 mt-2" : ""
                    }`}
                  >
                    <div className="px-6 uppercase font-bold text-xs">
                      {macroKey}
                    </div>

                    {isEditMode && i === 0 && macroKey !== "TOTAL" ? (
                      <Input
                        value={value}
                        label="gr"
                        // @ts-ignore
                        onChange={(e) => {
                          dispatch(
                            updateStartingMacro({
                              tableKey: row.key as DietTableKeys,
                              macro: macroKey as DietTableMacrosKeys,
                              value: e.target.value || "0",
                            })
                          );
                        }}
                      />
                    ) : (
                      <div className="px-6 py-1 text-xs">
                        {macroKey !== "TOTAL" && value + " gr /"}

                        {kcal + " kcal"}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </td>
        ))}
      </tr>
    </>
  );
};
