import { PersonalProfileFormType } from "../../components/UserDetails/UserProfileForm/fields";
import { api } from "../api";
import { showInvitationForm } from "../auth/utils";
import {
  User,
  UserCreateRequest,
  UserCreateResponse,
  UserFormData,
  UserNote,
  UserRequest,
  UserResponse,
} from "./types";

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: "GET",
      }),
      transformResponse: transformUserResponse,

      providesTags: ["User"],
    }),
    createUser: builder.mutation<string, UserCreateRequest>({
      query: (user) => ({
        url: `/user`,
        method: "POST",
        body: user,
        responseHandler: "text",
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation<UserResponse, User>({
      query: (user) => ({
        url: `/user/`,
        method: "PUT",
        body: transformUserRequest(user),
      }),
      invalidatesTags: ["User"],
    }),
    updateUserProfile: builder.mutation<
      UserResponse,
      {
        profileInfo: Partial<PersonalProfileFormType> & {
          showInitialForm?: boolean;
        };
        currentUser: UserRequest;
      }
    >({
      query: ({ profileInfo, currentUser }) => ({
        url: `/user/`,
        method: "PUT",
        body: transformUpdateUserProfileRequest(profileInfo, currentUser),
      }),
      invalidatesTags: ["User"],
    }),
    updateUserForm: builder.mutation<
      void,
      { userFormData: UserFormData; currentUser: UserRequest }
    >({
      query: ({ userFormData, currentUser }) => ({
        url: `/userform/`,
        method: "POST",
        body: transformUpdateUserFormDataRequest(userFormData, currentUser),
      }),
      invalidatesTags: ["User"],
    }),
    setUserMessages: builder.mutation<
      void,
      {
        id?: number;
        idUser: number;
        text: UserNote;
      }
    >({
      query: (message) => ({
        url: `/messages/`,
        method: "POST",
        body: { ...message, text: JSON.stringify(message.text) },
      }),
      invalidatesTags: ["User"],
    }),
    deleteUserMessages: builder.mutation<
      void,
      {
        id?: number;
      }
    >({
      query: (message) => ({
        url: `/messages/`,
        method: "DELETE",
        body: { ...message },
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation<UserResponse, User>({
      query: (user) => ({
        url: `/user/${user.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
  overrideExisting: false,
});

export function transformUserResponse(user: UserResponse): User {
  let userData = null;
  let messages = null;
  try {
    if (typeof user.userData === "string") {
      userData = JSON.parse(user.userData) as User["userData"];
    }
    if (user.messages) {
      messages = user.messages.map((message) => ({
        ...message,
        text: JSON.parse(message.text) as UserNote,
      }));
    }
  } catch (error) {
    console.error(error);
  }
  return {
    ...user,
    userData: userData || ({} as User["userData"]),
    messages: messages || [],
    form: user.form
      ? {
          id: user.form.id,
          userId: user.form.userId,
          data: JSON.parse(user.form.data || "") as UserFormData,
        }
      : undefined,
  };
}

const transformUserRequest = (user: User): UserResponse => {
  const userRequest: UserResponse = {
    ...user,
    userData: user.userData ? JSON.stringify(user.userData) : "",
    form: {
      id: user.form?.id,
      userId: user.form?.userId,
      data: JSON.stringify(user.form?.data),
    },
    messages:
      user.messages && user.messages.length > 0
        ? user.messages.map((message) => {
            return {
              id: message.id,
              idUser: message.idUser,
              text: JSON.stringify(message.text),
            };
          })
        : [],
  };
  return userRequest;
};

const transformUpdateUserProfileRequest = (
  profileInfo: Partial<
    PersonalProfileFormType & {
      showInitialForm?: boolean;
    }
  >,
  user: User
): UserResponse => {
  const userPersonalProfileUpdated = {
    ...user,
    height: profileInfo.height ? profileInfo.height : user.height,
    name: profileInfo.name ? profileInfo.name : user.name,
    surname: profileInfo.surname ? profileInfo.surname : user.surname,
    email: profileInfo.email ? profileInfo.email : user.email,
    phoneNumber: profileInfo.phoneNumber
      ? profileInfo.phoneNumber
      : user.phoneNumber,
    birthday: profileInfo.birthdate ? profileInfo.birthdate : user.birthday,
    gender: profileInfo.gender ? profileInfo.gender : user.gender,
    userData: {
      ...user.userData,
      leanBodyMass: profileInfo.leanBodyMass,
      weight: profileInfo.weight,
      showInitialForm: profileInfo.showInitialForm,
      paymentNotes: profileInfo.paymentNotes,
      profileNotes: profileInfo.profileNotes,
      status: profileInfo.status,
    },
  };
  const userRequest = transformUserRequest(userPersonalProfileUpdated);
  return userRequest;
};

const transformUpdateUserFormDataRequest = (
  formData: UserFormData,
  user: User
): UserResponse["form"] => {
  const userFormDataUpdated = {
    ...user.form,
    userId: user.id || 0,
    data: JSON.stringify({
      ...user.form?.data,
      ...formData,
    }),
  };
  return userFormDataUpdated;
};

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserProfileMutation,
  useUpdateUserFormMutation,
  useSetUserMessagesMutation,
  endpoints,
} = userApi;
