type ModalProps = {
  openModal?: boolean | string;
  title?: string;
  setOpenModal: (openModal?: string) => void;
  children: React.ReactNode;
  fullScreen?: boolean;
};
export const Modal: React.FC<ModalProps> = ({
  title,
  openModal,
  setOpenModal,
  children,
  fullScreen,
}) => {
  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      className={` ${
        openModal ? "" : "hidden overflow-x-hidden "
      } bg-slate-500 bg-opacity-75 mx-auto overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full `}
    >
      <div
        className={` ${
          fullScreen ? "" : "max-w-2xl"
        }mx-auto mt-8 p-4 w-full  h-full md:h-auto`}
      >
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
              onClick={() => setOpenModal(undefined)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Chiudi</span>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
