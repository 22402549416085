import { useGetAllExercisesQuery } from "../../libs/exercises/api";
import {
  FEEDBACK_QUESTION_LABELS,
  FEEDBACK_QUESTION_OPTIONS,
} from "../../libs/workout/constants";
import { FEEDBACK_QUESTION_IDS, Workout } from "../../libs/workout/types";

type FeedbackTableProps = {
  workout: Workout;
  weeksColumns: string[];
};
export const FeedbackTable: React.FC<FeedbackTableProps> = ({
  workout,
  weeksColumns,
}) => {
  const wrkWeekList = workout.data;

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
      <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="text-md">
            <th scope="col" colSpan={1} className="px-6 py-3">
              Feedback
            </th>
            {weeksColumns.map((column, i) => (
              <th
                key={column + i + "feedback"}
                scope="col"
                colSpan={1}
                className="px-6 py-3 text-center"
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"></thead>
        <tbody>
          {Object.keys(FEEDBACK_QUESTION_IDS).map((feedbackQuestionId, i) => (
            <tr
              key={feedbackQuestionId + "-" + i}
              className="even:bg-gray-100 odd:bg-white"
            >
              <th scope="col" className="px-6 py-3">
                {
                  FEEDBACK_QUESTION_LABELS[
                    feedbackQuestionId as keyof typeof FEEDBACK_QUESTION_IDS
                  ]
                }
              </th>
              {weeksColumns.map((column, index) => (
                <th key={index} scope="col" className="px-6 py-3 text-center">
                  {getSetWeekAmountForFeedbackQuestionId(
                    workout,
                    feedbackQuestionId as keyof typeof FEEDBACK_QUESTION_IDS,
                    index
                  )}
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getSetWeekAmountForFeedbackQuestionId = (
  workout: Workout,
  feedbackQuestionId: keyof typeof FEEDBACK_QUESTION_IDS,
  weekIndex: number
) => {
  if (
    !workout.data[weekIndex].feedbackForm ||
    !workout.data[weekIndex].feedbackForm?.questions
  ) {
    return "-";
  }

  const question = workout.data[weekIndex].feedbackForm?.questions.find(
    (question) => {
      return question.id === feedbackQuestionId;
    }
  );

  if (!question || !question.value) {
    return "-";
  }

  return (
    <span>
      {FEEDBACK_QUESTION_OPTIONS[feedbackQuestionId][parseInt(question?.value)]}
    </span>
  );
};
