export interface CalendarItem {
  date: string;
}
export function convertTimestampToDate(ts: number): string {
  return new Date(ts * 1000).toISOString().split("T")[0];
}

export function convertDateToTimestamp(date: string): number {
  return Math.floor(new Date(date).getTime() / 1000);
}

export function getLastSunday(specificDate?: Date) {
  const date = specificDate || new Date();
  const today = date.getDate();
  const currentDay = date.getDay();
  const newDate = date.setDate(today - (currentDay || 7));
  return new Date(newDate);
}

export const currentYear = new Date().getFullYear();
export const currentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);

export const isSunday = (date: string) => {
  return new Date(date).getDay() === 0;
};

export const getDay = (date: string) => {
  const days = [
    "Domenica",
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
  ];
  const day = new Date(date).getDay();
  return days[day];
};

export const MONTH_NAMES = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
] as const;

export const getMonth = (date: string) => {
  const month = new Date(date).getMonth();
  return MONTH_NAMES[month];
};
export const getListByWeekFromSunday = (list: CalendarItem[]) =>
  list.reduce((acc, item) => {
    const week = new Date(item.date).getDay();
    if (!acc[week]) {
      acc[week] = [];
    }
    acc[week].push(item);
    return acc;
  }, [] as CalendarItem[][]);

export const filterListByYear = (list: CalendarItem[], year: number) =>
  list.filter((item) => new Date(item.date).getFullYear() === year);

export const getToday = () => new Date().toISOString().split("T")[0];

export const get90DaysAgo = () => {
  const today = new Date();
  const ninetyDaysAgo = new Date(today.setDate(today.getDate() - 90));
  return ninetyDaysAgo.toISOString().split("T")[0];
};

export const getWeek = function (date: Date) {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

export const getFullYear = (date: Date) => date.getFullYear();

export const getListByWeek = (list: CalendarItem[]) =>
  list.reduce((acc, item) => {
    const dt = new Date(item.date);
    const weekYear = getWeekYear(dt);
    if (!acc[weekYear]) {
      acc[weekYear] = [];
    }
    acc[weekYear].push(item);
    return acc;
  }, [] as CalendarItem[][]);

export const getWeekYear = (date: Date) => {
  let week = getWeek(date);

  const year = getFullYear(date);
  if (week < 10) {
    return parseInt(`${year}0${week}`);
  }
  return parseInt(`${year}${week}`);
};

export const getWeekYearBefore = (date: Date) => {
  let week = getWeek(date);

  const year = getFullYear(date);
  if (week < 11) {
    if (week === 1) {
      return parseInt(`${year - 1}52`);
    }
    return parseInt(`${year}0${week - 1}`);
  }
  return parseInt(`${year}${week - 1}`);
};

export const getAge = (date: Date) => {
  const today = new Date();
  const birthDate = date;
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
