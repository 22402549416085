import { get } from "http";
import { useGetDietQuery } from "../../libs/diet/api";
import { useEditDiet } from "../../libs/diet/hooks";
import {
  addWeek,
  removeWeek,
  selectDietCurrent,
  selectDietDifference,
} from "../../libs/diet/slice";
import { DietFromApiDay } from "../../libs/diet/types";
import {
  formWeekToTable,
  getWeekAverage,
  reduceMacrosDays,
} from "../../libs/diet/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import { EditButton, SubmitButton } from "../Button";
import { Skeleton } from "../Skeleton";
import { WeeksCounter } from "../WeeksCounter";
import { AverageTable } from "./AverageTable";
import { DietRow } from "./DietRow";
import { DifferenceRow } from "./DifferenceRow";
import { ExtraTable } from "./ExtraTable";
import { LeanBodyMassTable } from "./PerLeanBodyMassTable";
import { PerWeightTable } from "./PerWeightTable";

type DietTableProps = {
  showDietSetMode?: boolean;
  dietId: string | null;
  userId?: number | null;
  weight?: number | null;
  leanBodyMass?: number | null;
};
const rows = [
  { label: "Giorni ON", key: "ON" },
  { label: "Giorni OFF", key: "OFF" },
  { label: "Giorni REFEED", key: "REFEED" },
];

export const DietTable: React.FC<DietTableProps> = ({
  showDietSetMode,
  dietId,
  userId,
  weight,
  leanBodyMass,
}) => {
  const {
    data: diet,
    isLoading,
    error,
  } = useGetDietQuery(dietId || "", {
    skip: !dietId,
  });
  const dispatch = useAppDispatch();
  const currentDiet = useAppSelector(selectDietCurrent);
  const dietDifferenceList = useAppSelector(selectDietDifference);

  const { isEditMode, toggleEditMode, isUpdatePending, updateError, saveDiet } =
    useEditDiet(diet, {
      userId: userId || -1,
      setDefault: showDietSetMode,
    });

  if (showDietSetMode && !isEditMode) {
    return (
      <div className="flex space-y-2 flex-col text-xs text-gray-700 uppercase dark:text-gray-400">
        <h2 className="font-bold center">Aggiungi una dieta all'utente</h2>
        <div className="max-w-2">
          <EditButton
            onClick={() => {
              toggleEditMode();
            }}
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <Skeleton />;
  }
  if (error) {
    return <div>Error...</div>;
  }
  if (!currentDiet) {
    return <div>Non ci sono diete</div>;
  }
  const onSave = (e: any) => {
    e.preventDefault();
    saveDiet();
  };
  const weeks = reduceMacrosDays(currentDiet);

  const weeksAverage = weeks.map((week) =>
    getWeekAverage(week, {
      on: currentDiet.daysOnAmount,
      off: currentDiet.daysOffAmount,
      refeed: currentDiet.daysRefeedAmount,
    })
  );

  const getTotalKcalWeek = (weekIndex: number): number => {
    const week = weeks[weekIndex];
    const { daysOnAmount, daysOffAmount, daysRefeedAmount } = currentDiet;
    const totalKcal =
      formWeekToTable(week, "ON").TOTAL * daysOnAmount +
      formWeekToTable(week, "OFF").TOTAL * daysOffAmount +
      formWeekToTable(week, "REFEED").TOTAL * daysRefeedAmount;
    return totalKcal;
  };
  return (
    <>
      <div className="flex items-center space-x-4">
        {isEditMode && currentDiet ? (
          <>
            <button onClick={toggleEditMode}>Annulla</button>

            <form onSubmit={onSave}>
              <SubmitButton isPending={isUpdatePending}>Salva</SubmitButton>
            </form>
            <WeeksCounter
              current={currentDiet.weeksAmount}
              increment={() => dispatch(addWeek())}
              decrement={() => dispatch(removeWeek())}
            />
          </>
        ) : (
          <>
            <EditButton
              onClick={() => {
                toggleEditMode();
              }}
            />
          </>
        )}
        {updateError && (
          <div>Ops! C'è stato un errore, riprova più tardi...</div>
        )}
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
        <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="text-md">
              <th scope="col" className="px-6 py-3">
                Tipologia
              </th>
              {weeks.map((week, i) => (
                <th
                  key={"Week-" + (i + 1)}
                  scope="col"
                  className="px-6 py-3 text-center"
                >
                  <div className="flex flex-col ">
                    <span>Week {i + 1}</span>
                    <span className="text-xs font-bold text-primary-500 mt-2 ">
                      TOTALE SETT:{" "}
                    </span>
                    <span className="text-xs font-normal text-primary-500">
                      {getTotalKcalWeek(i) / 7} kcal
                    </span>
                    <span className="text-xs font-normal mt-2">{`ON: ${currentDiet.daysOnAmount} OFF: ${currentDiet.daysOffAmount} REFEED: ${currentDiet.daysRefeedAmount}`}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <DietRow
                key={row.key}
                row={row}
                weeks={weeks || []}
                index={index}
                isEditMode={isEditMode}
              />
            ))}
            {isEditMode && dietDifferenceList && (
              <DifferenceRow dietDifferenceList={dietDifferenceList} />
            )}
          </tbody>
        </table>
      </div>
      <AverageTable weeksAverage={weeksAverage} />
      {weight && <PerWeightTable weeksAverage={weeksAverage} weight={weight} />}
      {leanBodyMass && (
        <LeanBodyMassTable
          weeksAverage={weeksAverage}
          leanBodyMass={leanBodyMass}
        />
      )}
      <ExtraTable diet={currentDiet} isEditMode={isEditMode} />
    </>
  );
};
