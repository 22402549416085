import { createSelector } from "@reduxjs/toolkit";
import { endpoints } from "./api";

export const selectGetUserResult = createSelector(
  [(state) => state, (_, userId) => userId],
  (state, userId) => endpoints.getUser.select(userId)(state)?.data ?? null
);

export const selectPaymentNotes = createSelector(
  selectGetUserResult,
  (user) => user?.userData?.paymentNotes
);

export const selectProfileNotes = createSelector(
  selectGetUserResult,
  (user) => user?.userData?.profileNotes
);

export const selectLeanBodyMass = createSelector(
  selectGetUserResult,
  (user) => user?.userData?.leanBodyMass
);

export const selectWeight = createSelector(
  selectGetUserResult,
  (user) => user?.userData?.weight
);

export const selectUserStatus = createSelector(
  selectGetUserResult,
  (user) => user?.userData?.status
);

export const selectHeight = createSelector(
  selectGetUserResult,
  (user) => user?.height
);
export const selectBirthDate = createSelector(
  selectGetUserResult,
  (user) => user?.birthday
);
export const selectFiscalCode = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.fiscalCode
);
export const selectAddress = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.address
);
export const selectEmail = createSelector(
  selectGetUserResult,
  (user) => user?.email
);
export const selectName = createSelector(
  selectGetUserResult,
  (user) => user?.name
);
export const selectSurname = createSelector(
  selectGetUserResult,
  (user) => user?.surname
);
export const selectGender = createSelector(
  selectGetUserResult,
  (user) => user?.gender
);
export const selectActivityLevel = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.activityLevel
);
export const selectWeeklyWorkoutDays = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.weeklyWorkoutDays
);
export const selectWorkoutDuration = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.workoutDuration
);
export const selectWorkoutTimeSlot = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.workoutTimeSlot
);
export const selectPhoneNumber = createSelector(
  selectGetUserResult,
  (user) => user?.phoneNumber
);
export const selectStepsPerDay = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.stepsPerDay
);
export const selectWorkoutSince = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.workoutSince
);
export const selectGoal = createSelector(
  selectGetUserResult,
  (user) => user?.form?.data?.goal
);

export const selectShouldShowProfileForm = createSelector(
  selectHeight,
  selectBirthDate,
  selectFiscalCode,
  selectAddress,
  selectEmail,
  selectName,
  selectSurname,
  selectGender,
  selectActivityLevel,
  selectWeeklyWorkoutDays,
  selectWorkoutDuration,
  selectWorkoutTimeSlot,
  selectPhoneNumber,
  (
    height,
    birthdate,
    fiscalCode,
    address,
    email,
    name,
    surname,
    gender,
    activityLevel,
    weeklyWorkoutDays,
    workoutDuration,
    workoutTimeSlot,
    phoneNumber
  ) => {
    return !(
      height &&
      birthdate &&
      fiscalCode &&
      address &&
      email &&
      name &&
      surname &&
      gender &&
      activityLevel &&
      weeklyWorkoutDays &&
      workoutDuration &&
      workoutTimeSlot &&
      phoneNumber
    );
  }
);
