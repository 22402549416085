"use client";

import { useForm } from "react-hook-form";
import { useCreateExerciseMutation } from "../../libs/exercises/api";
import { EXERCISE_TYPES } from "../../libs/exercises/types";
import { SuccessAlert } from "../Alerts";
import { SubmitButton } from "../Button";
import { Modal } from "../Modal";
import { ExerciseForm } from "./ExerciseForm";

type CreateUserFormProps = {
  openModal: string | undefined;
  setOpenModal: (value: string | undefined) => void;
};

type FormValues = {
  name: string;
  tags: string[];
  video: string;
  type: keyof typeof EXERCISE_TYPES;
  notes?: string;
};

export const CreateExerciseModal: React.FC<CreateUserFormProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [createExercise, { data: exercise, isLoading, error }] =
    useCreateExerciseMutation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>();
  const onSubmit = (data: FormValues) => {
    createExercise({
      name: data.name,
      tags: data.tags,
      videoUrls: [data.video],
      type: data.type,
      notes: data.notes,
    }).catch((err) => console.log(err));
  };

  const SubmitBtn = () => (
    <>
      {!exercise && (
        <div className="w-full">
          <SubmitButton isPending={isLoading}>
            Crea un nuovo esercizio
          </SubmitButton>
        </div>
      )}
    </>
  );
  return (
    <Modal
      title="Registra un nuovo esercizio"
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <ExerciseForm
        register={register}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        formBtn={SubmitBtn()}
        setValue={setValue}
      />

      {exercise && (
        <SuccessAlert>
          <div>
            <span className="font-medium">
              Creazione avventua con successo!
            </span>{" "}
            Ora puoi utilizzare questo nuovo esercizio nei tuoi workouts
          </div>
        </SuccessAlert>
      )}
      {error && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          OPS! La creazione non è andata a buon fine. Riprova
        </p>
      )}
    </Modal>
  );
};
