import {
  DietTableMacros,
  DietTableMacrosItem,
  DietTableMacrosKeys,
} from "../../libs/diet/types";
import { getKcal } from "../../libs/utils/diet";

export const getMacrosCellKeys = (macros?: DietTableMacrosItem) => {
  if (!macros) return [];
  return Object.keys(macros).map((key) => (
    <td className="px-6 uppercase">{key}</td>
  ));
};

export const getMacrosCell = (macros?: DietTableMacrosItem) => {
  if (!macros) return [];
  return Object.keys(macros).map((key) => (
    <td className="px-6 py-2">
      {macros[key as DietTableMacrosKeys]} gr /{" "}
      {getKcal(
        parseInt(macros[key as DietTableMacrosKeys] || ""),
        key as DietTableMacrosKeys
      )}{" "}
      kcal
    </td>
  ));
};
export const getMacrosCellInput = (
  macros?: DietTableMacrosItem,
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    key: DietTableMacrosKeys
  ) => void
) => {
  if (!macros) return [];
  return Object.keys(macros).map((key) => (
    <TableInput
      value={macros[key as DietTableMacrosKeys] || "0"}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(e, key as DietTableMacrosKeys)
      }
      label="gr"
    />
  ));
};

export const TableInput: React.FC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}> = ({ value, onChange, label }) => {
  return (
    <td className="px-6 py-1 flex-row">
      <input
        value={value}
        onChange={(e) => onChange(e)}
        className="w-[90px] my-1 text-center text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 px-2 py-1 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      {label && <span>{label}</span>}
    </td>
  );
};

export const Input: React.FC<{
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}> = ({ value, onChange, label }) => {
  return (
    <div className="px-2 py-1 relative inline-block ">
      <input
        value={value}
        onChange={(e) => onChange(e)}
        className={
          " w-[90px] text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 px-2 py-1 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        }
      />
      {label && <span className="absolute right-0 top-2">{label}</span>}
    </div>
  );
};
