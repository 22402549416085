import { Tooltip } from "flowbite-react";
import { updateNotes, updateDays, updateGoals } from "../../libs/diet/slice";
import {
  Diet,
  DietFromApiDay,
  DietTableIntegrationsItem,
} from "../../libs/diet/types";
import { useAppDispatch } from "../../store";
import { IntegratorsRow } from "./IntegratorsRow";
import { TableInput } from "./shared";

type ExtraTableProps = {
  diet: Diet;
  isEditMode: boolean;
};
export const ExtraTable: React.FC<ExtraTableProps> = ({ diet, isEditMode }) => {
  const { notes, daysOffAmount, daysOnAmount, daysRefeedAmount, goals } = diet;
  const integrations = (): DietTableIntegrationsItem[] => {
    if (!diet.dietsData.days[0]) {
      return [];
    }
    const hasInteg = (day: DietFromApiDay) =>
      day.dietParameters.find((dParam) => dParam.key === "integrations");
    const dayWithIntegration = diet.dietsData.days.find(hasInteg);
    return dayWithIntegration?.dietParameters.find(
      (dParam) => dParam.key === "integrations"
    )?.value as DietTableIntegrationsItem[];
  };
  const dispatch = useAppDispatch();

  const editNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateNotes({
        notes: e.target.value,
      })
    );
  };
  const editDays = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    dispatch(
      updateDays({
        days: e.target.value,
        type,
      })
    );
  };
  const editGoals = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    dispatch(
      updateGoals({
        goals: e.target.value,
      })
    );
  };

  const rowBgColorDark = "bg-gray-50 dark:bg-gray-700";
  const rowBgColorLight = "bg-white dark:bg-gray-900";

  const totalDays = daysOnAmount + daysOffAmount + daysRefeedAmount;
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
      <table className="w-full text-xs text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"></thead>
        <tbody>
          <IntegratorsRow
            classes={rowBgColorDark}
            integrations={integrations()}
            isEditMode={isEditMode}
          />
          <tr key="notes" className={`${rowBgColorLight} dark:border-gray-700`}>
            <th
              scope="row"
              className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              NOTE
            </th>
            <td className="px-6 py-4 text-sm">
              <tr>{getNotesCell(notes || "", isEditMode, editNotes)}</tr>
            </td>
          </tr>
          <tr
            key="divisions"
            className={`${rowBgColorDark} dark:border-gray-700`}
          >
            <th
              scope="row"
              className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              DIVISIONE SETTIMANALE
            </th>
            <td className="px-6 py-2">
              <tr>
                <th className="px-6 uppercase font-bold">ON</th>
                <th className="px-6 uppercase font-bold">OFF</th>
                <th className="px-6 uppercase font-bold">REFEED</th>
                <th className="px-6 uppercase font-bold">TOTALE</th>
              </tr>

              <tr>
                {isEditMode ? (
                  <TableInput
                    value={daysOnAmount + ""}
                    onChange={(e) => editDays(e, "daysOnAmount")}
                  />
                ) : (
                  <td className="px-6 py-2">{daysOnAmount}</td>
                )}
                {isEditMode ? (
                  <TableInput
                    value={daysOffAmount + ""}
                    onChange={(e) => editDays(e, "daysOffAmount")}
                  />
                ) : (
                  <td className="px-6 py-2">{daysOffAmount}</td>
                )}
                {isEditMode ? (
                  <TableInput
                    value={daysRefeedAmount + ""}
                    onChange={(e) => editDays(e, "daysRefeedAmount")}
                  />
                ) : (
                  <td className="px-6 py-2">{daysRefeedAmount}</td>
                )}
                <td className="px-6 py-2">
                  {totalDays}
                  {totalDays !== 7 && (
                    <div className="text-red-500">
                      Attenzione, i giorni totali non sono 7
                    </div>
                  )}
                </td>
              </tr>
            </td>
          </tr>
          <tr key="goal" className={`${rowBgColorLight} dark:border-gray-700`}>
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              GOAL - DIFFERENZA SETTIMANALE
            </th>
            <td className="px-6 py-4">
              <tr>
                <th className="px-6 uppercase font-bold">GOAL</th>
              </tr>

              <tr>
                {isEditMode ? (
                  <TableInput
                    value={goals + ""}
                    onChange={(e) => editGoals(e, "goals")}
                    label="gr"
                  />
                ) : (
                  goals && <td className="px-6 py-2">{goals} gr</td>
                )}
              </tr>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const getNotesCell = (
  notes: string,
  isEditMode: boolean,
  onChange: (e: any) => void
) => {
  return (
    <>
      {isEditMode ? (
        <td className="px-6 py-2 line-clamp-2 text-left">
          <textarea
            value={notes}
            onChange={onChange}
            className=" w-[500px] text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 px-2 py-1 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </td>
      ) : (
        <td className="px-6 py-4 line-clamp-2 text-left">
          <Tooltip className="p-2" content={notes}>
            {notes}
          </Tooltip>
        </td>
      )}
    </>
  );
};
