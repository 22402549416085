import { EXERCISE_TYPES } from "../exercises/types";

export type WorkoutsResponse = Omit<Workout, "data"> & {
  data: string;
};

export const WORKOUT_TYPES = {
  DEFAULT: "DEFAULT",
  TEMPLATE: "TEMPLATE",
} as const;

export interface Workout {
  data: WorkoutsWeek[];
  workoutType: keyof typeof WORKOUT_TYPES;
  name?: string;
  note?: string;
  id: number;
  userId?: number;
  trainer: {
    id: number;
    name: string;
    surname: string;
  };
}

export type WorkoutsWeek = {
  id: number | null;
  weekNumber: number;
  idWorkout: number;
  days: WorkoutsDay[];
  feedbackForm?: WorkoutWeekFeedbackForm | null;
};

export type WorkoutWeekFeedbackForm = {
  questions: FeedbackQuestion[];
  completed: boolean;
  date: number;
};

export type FeedbackQuestion = {
  id: keyof typeof FEEDBACK_QUESTION_IDS;
  value: string;
};

export const FEEDBACK_QUESTION_IDS = {
  MUSCLE_TARGETED: "MUSCLE_TARGETED",
  MUSCLE_SORENESS: "MUSCLE_SORENESS",
  PHYSICAL_TIREDNESS: "PHYSICAL_TIREDNESS",
  EXERCISE_WEIGHT: "EXERCISE_WEIGHT",
  DIET_QUANTITY: "DIET_QUANTITY",
  DIET_QUALITY: "DIET_QUALITY",
  SLEEP_QUALITY: "SLEEP_QUALITY",
} as const;

export type WorkoutsDay = {
  id: number | null;
  dayNumber: number;
  idWeek: number | null;
  exercises: WorkoutExercise[];
};

export type WorkoutExercise = {
  exercise: Exercise;
};

export type Exercise = {
  id: number | string;
  name: string;
  tags?: string[];
  videoUrls?: string[];
  subExercises?: { id: string; name: string }[];
  type: keyof typeof EXERCISE_TYPES;
  sets: ExerciseSetFromServer[];
  completed: boolean;
  notes: string;
};

export type Reps =
  | number
  | number[]
  | { id: string; name: string; value: number }[];

export type ExerciseSetFromServer = {
  reps: Reps;
  repsDone?: Reps;
  repsRange?: Reps;
  rest: number;
  weight?: number | number[] | { id: string; name: string; value: number }[];
  completed?: boolean;
};

export type WorkoutPreview = {
  id: string;
  workoutType: keyof typeof WORKOUT_TYPES;
  userId?: string;
  user?: {
    id: string;
    name: string;
    surname: string;
  };
  trainer?: {
    id: string;
    name: string;
    surname: string;
  };
};
export type WorkoutTemplatePreview = {
  id: string;
  name: string;
  note: string;
  workoutType: keyof typeof WORKOUT_TYPES;
  trainer?: {
    id: string;
    name: string;
    surname: string;
  };
};

export type GenericWorkout = WorkoutPreview | WorkoutTemplatePreview;

export type AllWorkoutResponse = GenericWorkout[];
