import def from "../../../package.json";
import { Skeleton } from "../../components/Skeleton";
import { UsersTable } from "../../components/UsersTable";
import { useGetUsersQuery } from "../../libs/users/api";

export default function Dashboard() {
  const { data: users, isLoading, error } = useGetUsersQuery();

  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !users) {
    return <div>Error...</div>;
  }
  return (
    <div>
      <UsersTable users={users} />
      <span className="text-xs pb-2 text-gray-300">V. {def.version}</span>
    </div>
  );
}
