import { Modal } from "flowbite-react";
import { useUpdateUserMutation } from "../../libs/user/api";

import { useGetUsersQuery } from "../../libs/users/api";
import { Workout } from "../../libs/workout/types";
import { AssignToUserForm } from "./AssignToUserForm";
import { Skeleton } from "../Skeleton";

type AssignToUserModalProps = {
  workoutId: number;
  isModalOpen: boolean;
  setOpenModal: (state: string | undefined) => void;
};
export const AssignToUserModal: React.FC<AssignToUserModalProps> = ({
  workoutId,
  isModalOpen,
  setOpenModal,
}) => {
  const { data: users, isLoading, error } = useGetUsersQuery();

  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !users) {
    return <div></div>;
  }

  const usersWithoutWorkout = users.filter((user) => {
    return !(user.workouts && user.workouts.length > 0);
  });
  return (
    <Modal popup show={!!isModalOpen} onClose={() => setOpenModal(undefined)}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4 ">
          <div className="text-2xl font-bold px-4 mt-4">
            Assegna allenamento
          </div>
          <AssignToUserForm
            workoutId={workoutId}
            users={usersWithoutWorkout}
            onClose={() => setOpenModal(undefined)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
