import { BodyMeasureKeys, PlicometryMeasureKeys } from "./types";

export const MEASURES_LABELS: Record<
  "weight" | keyof typeof BodyMeasureKeys | keyof typeof PlicometryMeasureKeys,
  string
> = {
  weight: "PESO",

  WAIST: "VITA",
  HIPS: "FIANCHI",
  CONTRACTED_ARM: "BRACCIO CONTRATTO",
  ARM: "BRACCIO",
  FOREARM: "AVAMBRACCIO",
  THIGH_ROOT: "RADICE COSCIA",
  THIGH_MID: "COSCIA MEDIANA",
  SUPRAPATELLAR: "SOPRAPATELLARE",
  CALF: "POLPACCIO",

  P_CALF: "POLPACCIO",
  P_THIGH: "COSCIA",
  P_BICEPS: "BICIPITE",
  P_TRICEPS: "TRICIPITE",
  P_CHEST: "PETTO",
  P_AXILLARY: "ASCELLARE",
  P_SUBSCAPULAR: "SOTTOSCAPOLARE",
  P_ABDOMINAL: "ADDOMINALE",
  P_SUPRAILIAC: "SOPRAILIACA",
} as const;
