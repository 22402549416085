import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  Diet,
  DietTableIntegrationsItem,
  DietTableKeys,
  DietTableMacrosItem,
  DietTableMacrosKeys,
  DietTables,
} from "./types";
import {
  calculateMacros,
  getMacrosDifference,
  getStartingMacros,
} from "./utils";

// Define the initial state using that type

type State = {
  original: Diet | null;
  current: Diet | null;
  difference: DietTableMacrosItem[] | null;
  startingMacros: DietTables | null;
};
const initialState: State = {
  original: null,
  current: null,
  difference: null,
  startingMacros: null,
};

export const dietSlice = createSlice({
  name: "diet",
  initialState,
  reducers: {
    init(state, action: PayloadAction<{ diet: Diet }>) {
      const difference = getMacrosDifference(action.payload.diet);
      const startingMacros = getStartingMacros(action.payload.diet);
      state.original = action.payload.diet;
      state.current = calculateMacros(
        action.payload.diet,
        startingMacros,
        difference
      );
      state.difference = difference;
      state.startingMacros = startingMacros;
    },
    addWeek(state) {
      if (!state.current) {
        console.error("[DIET][addWeek] Current diet is null");
        return;
      }
      const weeksAmount = state.current.weeksAmount;
      const newWeek = state.current.dietsData.days
        .filter((day) => day.weekNumber === weeksAmount)
        .map((day) => ({
          ...day,
          id: null,
          weekNumber: weeksAmount + 1,
          dietParameters: day.dietParameters
            .filter((dt) => dt.key !== "integrations")
            .map((dt) => ({
              ...dt,
              id: null,
              idDietDay: null,
            })),
        }));

      state.current.dietsData.days =
        state.current.dietsData.days.concat(newWeek);
      state.current.weeksAmount++;
      state.difference?.push(state.difference[state.difference.length - 1]);
    },
    removeWeek(state) {
      if (!state.current) {
        console.error("[DIET][removeWeek] Current diet is null");
        return;
      }
      state.current.dietsData.days = state.current.dietsData.days.filter(
        (day) => day.weekNumber !== state.current?.weeksAmount
      );
      state.current.weeksAmount--;
      state.difference?.pop();
    },

    updateStartingMacro(
      state,
      action: PayloadAction<{
        tableKey: DietTableKeys;
        macro: DietTableMacrosKeys;
        value: string;
      }>
    ) {
      if (
        !state.startingMacros ||
        !state.startingMacros[action.payload.tableKey] ||
        !state.difference ||
        !state.current
      ) {
        console.error("[DIET][updateStartingMacro] Starting macros is null");
        return;
      }
      // @ts-ignore
      state.startingMacros[action.payload.tableKey][action.payload.macro] =
        action.payload.value;

      state.current = calculateMacros(
        state.current,
        state.startingMacros,
        state.difference
      );
    },

    updateDifference(
      state,
      action: PayloadAction<{
        weekIndex: number;
        macro: DietTableMacrosKeys;
        value: string;
      }>
    ) {
      if (!state.difference || !state.current || !state.startingMacros) {
        console.error("[DIET][updateDifference] Difference is null");
        return;
      }
      state.difference[action.payload.weekIndex][action.payload.macro] =
        action.payload.value;
      state.current = calculateMacros(
        state.current,
        state.startingMacros,
        state.difference
      );
    },

    addIntegrator(
      state,
      action: PayloadAction<{ integratorItem: DietTableIntegrationsItem }>
    ) {
      if (!state.current || !state.current.dietsData.days[0]) {
        console.error("[DIET][addIntegrator] Current diet is null");
        return;
      }

      const integrations =
        state.current.dietsData.days[0].dietParameters.findIndex(
          (param) => param.key === "integrations"
        );

      if (integrations === -1) {
        state.current.dietsData.days[0].dietParameters.push({
          ...state.current.dietsData.days[0].dietParameters[0],
          key: "integrations",
          value: [action.payload.integratorItem],
          id: null,
        });
      } else {
        // @ts-ignore
        state.current.dietsData.days[0].dietParameters[integrations].value.push(
          action.payload.integratorItem
        );
      }
    },
    updateIntegrator(
      state,
      action: PayloadAction<{
        integratorItem: DietTableIntegrationsItem;
        index: number;
      }>
    ) {
      if (!state.current) {
        console.error("[DIET][addIntegrator] Current diet is null");
        return;
      }
      const integrationsIndex =
        state.current.dietsData.days[0].dietParameters?.findIndex(
          (param) => param.key === "integrations"
        );

      if (integrationsIndex < 0) {
        console.error("[DIET][addIntegrator] No integrations");
        return;
      }
      // @ts-ignore
      state.current.dietsData.days[0].dietParameters[integrationsIndex].value[
        action.payload.index
      ] = action.payload.integratorItem;
    },
    removeIntegrator(state, action: PayloadAction<{ index: number }>) {
      if (!state.current) {
        console.error("[DIET][addIntegrator] Current diet is null");
        return;
      }
      const integrations =
        state.current.dietsData.days[0].dietParameters.findIndex(
          (param) => param.key === "integrations"
        );
      // @ts-ignore
      state.current.dietsData.days[0].dietParameters[integrations].value.splice(
        action.payload.index,
        1
      );
    },

    updateNotes(state, action: PayloadAction<{ notes: string }>) {
      if (!state.current) {
        console.error("[DIET][updateNotes] Current diet is null");
        return;
      }
      state.current.notes = action.payload.notes;
    },
    updateGoals(state, action: PayloadAction<{ goals: string }>) {
      if (!state.current) {
        console.error("[DIET][updateGoals] Current diet is null");
        return;
      }
      state.current.goals = action.payload.goals;
    },

    updateDays(state, action: PayloadAction<{ type: string; days: string }>) {
      if (!state.current) {
        console.error("[DIET][updateDays] Current diet is null");
        return;
      }
      const days = parseInt(action.payload.days) || 0;
      if (isNaN(days) || days < 0) {
        return;
      }
      // @ts-ignore
      state.current[action.payload.type] = days;
    },
  },
});

const { actions } = dietSlice;

export const {
  init,
  addWeek,
  removeWeek,
  updateDifference,
  updateStartingMacro,
  updateDays,
  updateNotes,
  updateIntegrator,
  addIntegrator,
  removeIntegrator,
  updateGoals,
} = actions;

export const selectDietOriginal = (state: RootState) => state.diet.original;
export const selectDietCurrent = (state: RootState) => state.diet.current;
export const selectDietDifference = (state: RootState) => state.diet.difference;
export const selectDietStartingMacros = (state: RootState) =>
  state.diet.startingMacros;
