"use client";

import { useForm } from "react-hook-form";
import { useCreateWorkoutMutation } from "../../libs/workout/api";
import { SubmitButton, WhatsAppButton } from "../Button";
import { Modal } from "../Modal";
import { Workout } from "../../libs/workout/types";

type CreateTemplateFormProps = {
  openModal: string | { workout: Workout } | undefined;
  setOpenModal: (value: string | undefined) => void;
};

type FormValues = {
  name: string;
  desc: string;
};

export const CreateTemplateModal: React.FC<CreateTemplateFormProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [createWorkout, { data: workout, isLoading, error }] =
    useCreateWorkoutMutation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    // @ts-ignore
    let workout = { ...(openModal?.workout || {}) };
    createWorkout({
      ...workout,
      name: data.name,
      note: data.desc,
      workoutType: "TEMPLATE",
    })
      .then(() => {
        reset();
        setOpenModal(undefined);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      title="Crea un nuovo Workout"
      openModal={!!openModal}
      setOpenModal={setOpenModal}
    >
      <form className="space-y-6 p-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label
            htmlFor="text"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Nome
          </label>
          <input
            {...register("name", { required: "Inserisci un nome valido" })}
            aria-invalid={errors.name ? "true" : "false"}
            type="text"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Nome del template"
            required
          />
        </div>
        {errors.name && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.name?.message?.toString()}
          </p>
        )}
        <div>
          <label
            htmlFor="text"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Descrizione
          </label>
          <input
            {...register("desc")}
            aria-invalid={errors.desc ? "true" : "false"}
            type="text"
            id="desc"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Aggiungi una breve descrizione"
          />
        </div>
        {errors.name && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.desc?.message?.toString()}
          </p>
        )}
        {/* {error && (
          <p role="alert" className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500">
            OPS! La creazione non è andata a buon fine. Riprova
          </p>
        )} */}
        {/* {!user && ( */}
        <div className="w-full">
          <SubmitButton isPending={isLoading}>Crea</SubmitButton>
        </div>
        {/* )} */}
      </form>
    </Modal>
  );
};
