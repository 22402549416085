import { Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";

import { useGetAllExercisesQuery } from "../../libs/exercises/api";

import { ExerciseForm } from "./ExerciseForm";
import { searchExercise } from "../../libs/exercises/utils";
import { Skeleton } from "../Skeleton";
import { EXERCISE_TYPES } from "../../libs/exercises/types";

type ExerciseModalProps = {
  dayIndex: number | undefined;
  exToCloneIndex?: number;
  exType?: keyof typeof EXERCISE_TYPES;
  setOpenModal: (
    state:
      | {
          dayIndex: number;
          exToCloneIndex?: number;
          exType?: keyof typeof EXERCISE_TYPES;
        }
      | undefined
  ) => void;
};
export const ExerciseModal: React.FC<ExerciseModalProps> = ({
  dayIndex,
  exToCloneIndex,
  exType,
  setOpenModal,
}) => {
  const { data: exercises, isLoading, error } = useGetAllExercisesQuery();
  const [filteredResults, setFilteredResults] = useState(exercises);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    setSearchInput("");
    setFilteredResults(exercises);
  }, [exercises]);
  const searchItems = (searchValue: string) => {
    searchExercise(
      exercises || [],
      searchValue,
      setSearchInput,
      setFilteredResults
    );
  };
  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !exercises || (!dayIndex && dayIndex !== 0)) {
    return <></>;
  }
  return (
    <Modal
      popup
      show={dayIndex !== undefined}
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Header onSearch={searchItems} searchInput={searchInput} />
        <ExerciseForm
          dayIndex={dayIndex}
          onClose={() => setOpenModal(undefined)}
          exercises={exercises || []}
          filteredExercises={filteredResults || []}
          exToCloneIndex={exToCloneIndex}
          exType={exType}
        />
      </Modal.Body>
    </Modal>
  );
};

type HeaderProps = {
  onSearch: (value: string) => void;
  searchInput: string;
};

export const Header: React.FC<HeaderProps> = ({ onSearch, searchInput }) => {
  return (
    <div>
      <div className="relative bg-white shadow-md dark:bg-gray-800">
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          <div className="w-full">
            <div className="flex items-center">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Search"
                  required={false}
                  value={searchInput}
                  autoFocus
                  onChange={(e) => {
                    onSearch(e.target.value);
                    // don't loose focus on input
                    setTimeout(() => {
                      e.target.focus();
                    }, 0);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
