import {
  Diet,
  DietData,
  DietFromApiDay,
  DietRequestPUT,
  DietResponse,
  DietTableIntegrationsItem,
} from "./types";

export const mapIntegrationsFromServer = (
  days: DietFromApiDay[]
): DietTableIntegrationsItem[] => {
  return days
    .map((day) => {
      try {
        const integrationObj = day.dietParameters.find(
          (param) => param.key === "integrations"
        );
        const integrations = JSON.parse(integrationObj?.value as string);
        if (!integrations) {
          return;
        }
        return integrations as unknown as DietTableIntegrationsItem[];
      } catch (error) {
        console.error(error);
        return;
      }
    })
    .filter((item) => item && item)
    .reduce(
      // @ts-ignore
      (acc, item) => [...acc, ...item],
      []
    ) as DietTableIntegrationsItem[];
};

export function transformDietResponse(diet: DietResponse) {
  let dietData: DietData = {
    days: [] as DietFromApiDay[],
    plicometrics: {} as DietData["plicometrics"],
  };
  try {
    const parsedData = JSON.parse(diet.dietsData);
    if (Array.isArray(parsedData)) {
      dietData.days = parsedData;
    } else {
      dietData = parsedData;
    }
  } catch (error) {
    console.error(error);
  }
  const mapDiet: Diet = {
    ...diet,
    dietsData: {
      plicometrics: dietData.plicometrics || undefined,
      days: dietData.days || [],
    },
    // days: diet.days.map((day) => {
    //   const dietParameters = day.dietParameters
    //     .filter((dt) => {
    //       // remove more then 1 day with same dayType
    //       const dtWithSameDayType = day.dietParameters.find(
    //         (dtF: any) => dt.key === dtF.key && dt.idDietDay === dt.idDietDay
    //       );
    //       if (dtWithSameDayType?.id === dt.id) {
    //         return true;
    //       }
    //       return false;
    //     })
    //     .map((param) => {
    //       if (param.key === "integrations") {
    //         return {
    //           ...param,
    //           value: JSON.parse(param.value as string),
    //         };
    //       }
    //       return param;
    //     });
    //   return {
    //     ...day,
    //     dietParameters,
    //   };
    // }),
  };
  return mapDiet;
}

export function transformDietRequest(diet: Diet): DietRequestPUT {
  const dietRequest: DietRequestPUT = {
    ...diet,
    dietsData: JSON.stringify(diet.dietsData),
  };
  return dietRequest;
}
