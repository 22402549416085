import { useEffect, useState } from "react";
import { WorkoutTemplatePreview } from "../../libs/workout/types";
import { CreateTemplateModal } from "./CreateTemplateModal";
import { Header } from "./Header";
import { Pagination } from "../Pagination";

type WorkoutTemplateTableProps = {
  templates: WorkoutTemplatePreview[];
};

const WorkoutsTableColumns = ["Nome", "Descrizione", ""];

const WorkoutTemplateRow: React.FC<{
  template: WorkoutTemplatePreview;
  index: number;
}> = ({ template, index }) => {
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";
  return (
    <tr key={template.id} className={`${rowBgColor} dark:border-gray-700`}>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {template.name}
      </th>
      <td className="px-6 py-4">{template.note}</td>
      <td className="px-6 py-4">
        <a
          href={"/workouts/" + template.id}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          Visualizza
        </a>
      </td>
    </tr>
  );
};
export const WorkoutTemplateTable: React.FC<WorkoutTemplateTableProps> = ({
  templates,
}) => {
  const [openModal, setOpenModal] = useState<string | undefined>();
  const [filteredResults, setFilteredResults] = useState(templates);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;

  useEffect(() => {
    setSearchInput("");
    setFilteredResults(templates);
  }, [templates]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredResults]);

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      const filteredData = templates.filter((template) => {
        const item = {
          name: template.name,
          description: template.note,
        };
        return Object.values(item)
          .join(" ")
          .replaceAll("-", " ")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(templates);
    }
  };

  return (
    <>
      <div className="relative my-8 overflow-auto shadow-md sm:rounded-lg no-scrollbar">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Workout Templates
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
              Lista dei templates di allenamento utilizzabili per assegnare
              allenamenti agli utenti
            </p>
            <Header
              onSearch={searchItems}
              searchInput={searchInput}
              openCreateTemplateModal={() => setOpenModal("create-template")}
            />
          </caption>

          <thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {WorkoutsTableColumns.map((column) => (
                <th key={column} scope="col" className="px-6 py-3">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="max-h-96 overflow-y-auto no-scrollbar">
            {filteredResults
              ?.slice((currentPage - 1) * perPage, currentPage * perPage)
              .map((template, i) => (
                <WorkoutTemplateRow key={i} template={template} index={i} />
              ))}
          </tbody>
        </table>
      </div>
      {filteredResults?.length > perPage && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(filteredResults?.length / perPage)}
        />
      )}
      <CreateTemplateModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};
