import { useState, ReactNode, useEffect } from "react";
import { EXERCISE_TAGS, EXERCISE_TYPES } from "../../libs/exercises/types";
import { getCategoryLabel, getTypeLabel } from "../../libs/exercises/utils";
import { Exercise } from "../../libs/exercises/types";
import { UseFormSetValue, useForm } from "react-hook-form";
import { PlusButton } from "../Button";

type ExerciseFormProps = {
  register: any;
  errors: any;
  onSubmit: () => void;
  formBtn: ReactNode;
  exerciseToUpdate?: Exercise;
  setValue: UseFormSetValue<any>;
};

export const ExerciseForm: React.FC<ExerciseFormProps> = ({
  register,
  errors,
  onSubmit,
  formBtn,
  exerciseToUpdate,
  setValue,
}) => {
  const {
    register: registerTag,
    formState: { errors: errorsTag },
    handleSubmit,
    setValue: setValueTag,
  } = useForm<{ tag: string }>();
  const [tags, setTags] = useState<string[]>(exerciseToUpdate?.tags || []);
  const { name } = register("tags");

  useEffect(() => {
    setValue("tags", tags);
  }, [tags]);

  const onTagSubmit = (data: { tag: string }) => {
    setTags([...tags, data.tag.toUpperCase()]);
    setValueTag("tag", "");
  };

  return (
    <form className="space-y-6 p-4" onSubmit={onSubmit}>
      <div>
        <label
          htmlFor="text"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Nome Esercizio
        </label>
        <input
          {...register("name", { required: "Inserisci un valore valido" })}
          aria-invalid={errors.name ? "true" : "false"}
          type="name"
          id="name"
          defaultValue={exerciseToUpdate?.name}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Nome Esercizio"
          required
        />
      </div>
      {errors.name && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.name?.message?.toString()}
        </p>
      )}
      {/* <select
        {...register("type", { required: "Inserisci una categoria valida" })}
        id="type"
        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
      >
        <option defaultValue={""} value={exerciseToUpdate?.type} disabled>
          Seleziona una Tipologia
        </option>
        {Object.keys(EXERCISE_TYPES).map((type) => (
          <option key={type} value={type}>
            {getTypeLabel(type as keyof typeof EXERCISE_TYPES)}
          </option>
        ))}
      </select>
      {errors.type && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.type?.message?.toString()}
        </p>
      )} */}
      <div>
        <label
          htmlFor="text"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Link Video
        </label>
        <input
          {...register("video", { required: "Inserisci un link valido" })}
          aria-invalid={errors.name ? "true" : "false"}
          type="video"
          id="video"
          defaultValue={exerciseToUpdate?.videoUrls[0]}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Inserisci il link del video di esempio"
          required
        />
      </div>
      {errors.video && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.video?.message?.toString()}
        </p>
      )}
      <label
        htmlFor="text"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Tags
      </label>
      <div className="flex bg-gray-100 p-1 items-center w-full space-x-2 sm:space-x- rounded border border-gray-500 dark:bg-gray-700 dark:border-gray-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 opacity-50 dark:text-gray-100 ml-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
          />
        </svg>

        <input
          {...registerTag("tag", { required: "Inserisci tags validi" })}
          id="tag"
          className="bg-gray-100 outline-none text-sm sm:text-base w-full dark:bg-gray-700 dark:text-gray-200 border-transparent focus:border-transparent focus:ring-0"
          type="text"
          placeholder="Aggiungi un tag..."
        />
        <PlusButton variant={"icon"} onClick={handleSubmit(onTagSubmit)} />
      </div>
      {errors.tags && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.category?.message?.toString()}
        </p>
      )}
      <Tags
        tags={tags}
        removeTag={(tagToRm: string) =>
          setTags(tags.filter((tag: string) => tagToRm !== tag))
        }
      />
      {formBtn}
    </form>
  );
};

type TagsProps = {
  tags?: string[];
  removeTag: (tag: string) => void;
};
const Tags: React.FC<TagsProps> = ({ tags, removeTag }) => {
  return (
    <div className="my-3 flex flex-wrap -m-1">
      {tags?.map((tag) => (
        <span className="m-1 flex flex-wrap justify-between items-center text-xs sm:text-sm bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded px-4 py-2 font-bold leading-loose cursor-pointer dark:text-gray-300">
          {tag}
          <button type="button" onClick={() => removeTag(tag)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 h-3 sm:h-4 sm:w-4 ml-4 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      ))}
    </div>
  );
};
