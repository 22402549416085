import { useEffect, useState } from "react";
import { Exercise } from "../../libs/exercises/types";
import {
  getCategoryLabel,
  getTypeLabel,
  searchExercise,
} from "../../libs/exercises/utils";
import { DeleteButton, EditButton } from "../Button";
import { CreateExerciseModal } from "./CreateExerciseModal";
import { DeleteExerciseModal } from "./DeleteExerciseModal";
import { EditExerciseModal } from "./EditExerciseModal";
import { Header } from "./Header";
import { Pagination } from "../Pagination";

type ExercisesTableProps = {
  exercises?: Exercise[];
};

const ExercisesTableColumns = ["Nome", "Tags", "Video", "Azione"];

const ExerciseRow: React.FC<{
  exercise: Exercise;
  index: number;
  onEdit: () => void;
  onDelete: () => void;
}> = ({ exercise, index, onEdit, onDelete }) => {
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";
  return (
    <tr key={exercise.id} className={`${rowBgColor} dark:border-gray-700`}>
      <th
        scope="row"
        className="px-6 py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {exercise.name}
      </th>
      {/* <td className="px-6 py-4 text-center">{getTypeLabel(exercise?.type)}</td> */}
      <td className="px-6 py-4 text-center">
        {exercise.tags?.map((tag) => (
          <span className="px-2 m-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {tag}
          </span>
        ))}
      </td>
      <td className="px-6 py-4 text-center">
        <a href={exercise.videoUrls[0]}>LINK</a>
      </td>
      <td className="px-6 py-4 text-center">
        <EditButton onClick={onEdit} variant="icon" />
        <DeleteButton onClick={onDelete} variant="icon" />
      </td>
    </tr>
  );
};
export const ExercisesTable: React.FC<ExercisesTableProps> = ({
  exercises,
}) => {
  const [openModal, setOpenModal] = useState<string | undefined>();
  const [exerciseToUpdate, setExerciseToUpdate] = useState<
    Exercise | undefined
  >();
  const [filteredResults, setFilteredResults] = useState(exercises);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    setSearchInput("");
    setFilteredResults(exercises);
  }, [exercises]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredResults]);

  // pagination over filteredResults
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const searchItems = (searchValue: string) => {
    searchExercise(
      exercises || [],
      searchValue,
      setSearchInput,
      setFilteredResults
    );
  };

  return (
    <>
      <Header
        onSearch={searchItems}
        searchInput={searchInput}
        openCreateUserModal={() => setOpenModal("create-exercise")}
      />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {ExercisesTableColumns.map((column) => (
                <th key={column} scope="col" className="px-6 py-3 text-center">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredResults
              ?.slice((currentPage - 1) * perPage, currentPage * perPage)
              .map((exercise, i) => (
                <ExerciseRow
                  key={i}
                  exercise={exercise}
                  index={i}
                  onEdit={() => {
                    setExerciseToUpdate(exercise);
                    setOpenModal("edit-exercise");
                  }}
                  onDelete={() => {
                    setExerciseToUpdate(exercise);
                    setOpenModal("delete-exercise");
                  }}
                />
              ))}
          </tbody>
        </table>
      </div>

      {openModal === "create-exercise" && (
        <CreateExerciseModal
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
      {openModal === "edit-exercise" && (
        <EditExerciseModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          exerciseToUpdate={exerciseToUpdate}
        />
      )}
      {openModal === "delete-exercise" && (
        <DeleteExerciseModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          exerciseToDelete={exerciseToUpdate}
        />
      )}
      {filteredResults && filteredResults.length > perPage && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(filteredResults?.length / perPage)}
        />
      )}
      <div className="mb-20" />
    </>
  );
};
