import { api } from "../api";
import { transformDietRequest, transformDietResponse } from "./map";
import { Diet, DietResponse } from "./types";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDiet: builder.query<Diet, string>({
      query: (dietId) => ({
        url: `/diet/${dietId}`,
        method: "GET",
      }),
      transformResponse: transformDietResponse,
      providesTags: ["Diet"],
    }),
    setDiet: builder.mutation<DietResponse, Diet>({
      query: (diet) => ({
        url: `/diet`,
        method: "POST",
        body: transformDietRequest(diet),
      }),
      invalidatesTags: ["Diet"],
    }),
    updateDiet: builder.mutation<DietResponse, Diet>({
      query: (diet) => ({
        url: `/diet/`,
        method: "PUT",
        body: transformDietRequest(diet),
      }),
      invalidatesTags: ["Diet"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetDietQuery, useUpdateDietMutation, useSetDietMutation } =
  extendedApi;
