import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from "flowbite-react";
import { User } from "../../libs/user/types";
import { UserInfoSection } from "./UserInfoSection";
import { WorkoutTables } from "../WorkoutTables";
import { WeightTable } from "../WeightTable";
import { BodyTable } from "../BodyTable";
import { DietTable } from "../DietTable";
import { useGetWorkoutQuery } from "../../libs/workout/api";
import { EditButton } from "../Button";
import { ROUTES } from "../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  selectGetUserResult,
  selectLeanBodyMass,
  selectWeight,
} from "../../libs/user/selectors";
import { HistoryTable } from "../HistoryTable";
import {
  selectLastLeanBodyMass,
  selectLastWeight,
} from "../../libs/measures/selectors";
import { useEffect } from "react";
import { initUser } from "../../libs/user/slice";

type UserDetailsProps = {
  user: User;
};

export const UserDetails: React.FC<UserDetailsProps> = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => selectGetUserResult(state, userId));
  const lastLeanBodyMass = useAppSelector((state) =>
    selectLastLeanBodyMass(state, userId)
  );
  const lastWeight = useAppSelector((state) => selectLastWeight(state, userId));
  const fixedLeanBodyMass = useAppSelector((state) =>
    selectLeanBodyMass(state, userId)
  );
  const fixedWeight = useAppSelector((state) => selectWeight(state, userId));

  const lbm = parseFloat(fixedLeanBodyMass || "") || lastLeanBodyMass;
  const weight = parseFloat(fixedWeight || "") || lastWeight;

  const workoutId = (user?.workouts && user.workouts[0]?.id) || "";
  const dietId = (user?.diets && user.diets[0]?.id) || null;
  const {
    data: workout,
    isLoading: workoutIsLoading,
    error: workoutError,
  } = useGetWorkoutQuery(workoutId);

  useEffect(() => {
    if (user) {
      dispatch(initUser({ user }));
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <UserInfoSection />

      <Tabs.Group aria-label="Default tabs">
        <Tabs.Item title="Workout">
          {workoutId && (
            <div>
              <EditButton
                onClick={() => {
                  navigate(`${ROUTES.WORKOUTS}/${workoutId}?edit=true`);
                }}
              />
            </div>
          )}
          <WorkoutTables
            workout={workout}
            isLoading={workoutIsLoading}
            error={workoutError}
            showWorkoutSetMode={!workoutId}
          />
        </Tabs.Item>
        <Tabs.Item title="Dieta">
          <DietTable
            dietId={dietId}
            userId={user.id}
            showDietSetMode={!dietId}
            weight={weight && !isNaN(weight) ? weight : undefined}
            leanBodyMass={lbm && !isNaN(lbm) ? lbm : undefined}
          />
        </Tabs.Item>
        <Tabs.Item title="Storico Peso">
          <WeightTable
            dietId={dietId}
            userId={user?.id || undefined}
            isFemale={user?.gender === "F"}
          />
        </Tabs.Item>
        <Tabs.Item title="Storico Immagini">
          <BodyTable userId={user.id} />
        </Tabs.Item>
        <Tabs.Item title="Storico Misurazioni">
          <HistoryTable userId={user.id} />
        </Tabs.Item>
        {/* <Tabs.Item title="Storico Alimentazione">
          <NutritionTable userId={user.id} />
        </Tabs.Item> */}
      </Tabs.Group>
    </>
  );
};
