import { useEffect, useMemo, useState } from "react";
import { User } from "../../libs/user/types";
import { CreateUserModal } from "./CreateUserModal";
import { Header } from "./Header";
import { Pagination } from "../Pagination";
import { NoteButton } from "../Button";
import { useNavigate } from "react-router-dom";
import { getIsNoteNew } from "../../libs/user/utils";

type UsersTableProps = {
  users: User[];
};

const UsersTableColumns = [
  "Nome",
  "Email",
  "Data di nascita",
  "Sesso",
  "Note",
  "Azione",
];

const UserRow: React.FC<{ user: User; index: number }> = ({ user, index }) => {
  const isUserNoteNew = user.messages?.some((message) => {
    if (!message?.text?.user) {
      return false;
    }
    return getIsNoteNew(message.text.user[0]);
  });

  const navigate = useNavigate();
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";
  return (
    <tr key={user.id} className={`${rowBgColor} dark:border-gray-700`}>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {user.name} {user.surname}
      </th>
      <td className="px-6 py-4">{user.email}</td>
      <td className="px-6 py-4">{user.birthday}</td>
      <td className="px-6 py-4">{user.gender}</td>
      <td className="px-6 py-4">
        <NoteButton
          onClick={() => navigate(`/user/${user.id}?showNote=true`)}
          isActive={!!isUserNoteNew}
        />
      </td>
      <td className="px-6 py-4">
        <a
          href={"/user/" + user.id}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          Vai alla scheda
        </a>
      </td>
    </tr>
  );
};
export const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const [openModal, setOpenModal] = useState<string | undefined>();
  const [filteredResults, setFilteredResults] = useState(users);
  const [showNewNoteUsers, setShowNewNoteUsers] = useState(false);
  const newNoteUsers = useMemo(
    () =>
      users.filter((user) => {
        return user.messages?.some((message) => {
          if (!message?.text?.user) {
            return false;
          }
          return getIsNoteNew(message.text.user[0]);
        });
      }),
    [users]
  );

  const [userList, setUserList] = useState(users);

  const [searchInput, setSearchInput] = useState("");
  // pagination over filteredResults
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  useEffect(() => {
    if (showNewNoteUsers) {
      setUserList(newNoteUsers);
    } else {
      setUserList(users);
    }
    searchItems(searchInput);
  }, [showNewNoteUsers]);

  useEffect(() => {
    setSearchInput("");
    setFilteredResults(userList);
  }, [users]);

  useEffect(() => {
    setSearchInput("");
    setFilteredResults(userList);
  }, [JSON.stringify(userList)]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredResults]);

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      const filteredData = userList.filter((user) => {
        const item = {
          name: user.name,
          surname: user.surname,
        };
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(userList);
    }
  };

  return (
    <>
      {filteredResults && filteredResults.length > perPage && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(filteredResults?.length / perPage)}
        />
      )}
      <Header
        onSearch={searchItems}
        searchInput={searchInput}
        openCreateUserModal={() => setOpenModal("create-user")}
        setShowNewNoteUsers={setShowNewNoteUsers}
        showNewNoteUsers={showNewNoteUsers}
      />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {UsersTableColumns.map((column) => (
                <th key={column} scope="col" className="px-6 py-3">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredResults
              ?.slice((currentPage - 1) * perPage, currentPage * perPage)
              .map((user, i) => (
                <UserRow key={i} user={user} index={i} />
              ))}
          </tbody>
        </table>
      </div>
      <CreateUserModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};
