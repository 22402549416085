import { Modal, Tooltip } from "flowbite-react";
import { useState } from "react";
import { ExerciseSetFromServer, Workout } from "../../libs/workout/types";
import { EditButton } from "../Button";
import { Skeleton } from "../Skeleton";

type WorkoutTableProps = {
  workout?: Workout;
  isLoading?: boolean;
  error?: any;
};
const WeeksSubColumnsLabels = ["Set", "Notes"];

export const WorkoutTemplateTables: React.FC<WorkoutTableProps> = ({
  workout,
  isLoading,
  error,
}) => {
  const [openModal, setOpenModal] = useState<
    ExerciseSetFromServer[] | undefined
  >();

  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !workout || !workout.data || !workout.data[0]) {
    return <div>Error...</div>;
  }
  const weeksColumns = workout.data.map((week) => week.weekNumber);
  const workoutDays = workout.data[0].days.map((day) => day.dayNumber);
  const exercisesRows = (dayIndex: number) => {
    if (!workout.data) return [];
    return workout.data[0].days[dayIndex].exercises.map((exercise, i) => {
      return {
        name: exercise.exercise.name,
        weeks:
          (workout.data &&
            workout.data.map((week) => {
              const currEx = week.days[dayIndex].exercises[i];
              return {
                sets: currEx?.exercise?.sets,
                notes: currEx?.exercise?.notes,
              };
            })) ||
          [],
      };
    });
  };
  return (
    <div className="flex flex-col">
      {workoutDays.map((day, index) => (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 text-center">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="text-md">
                <th scope="col" className="px-6 py-3">
                  {day}
                </th>
                {weeksColumns.map((column) => (
                  <th
                    key={column}
                    scope="col"
                    colSpan={2}
                    className="px-6 py-3 text-center"
                  >
                    {column}
                  </th>
                ))}
                <th scope="col" className="px-6 py-3">
                  Azioni
                </th>
              </tr>
              <tr>
                <th scope="col" className="px-6 py-3">
                  Esercizio
                </th>
                {weeksColumns.map(() => (
                  <>
                    {WeeksSubColumnsLabels.map((column) => (
                      <th key={column} scope="col" className="px-6 py-3">
                        {column}
                      </th>
                    ))}
                  </>
                ))}
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"></thead>
            <tbody>
              {exercisesRows(index).map((exercise, i) => (
                <ExerciseRow
                  key={i}
                  exercise={exercise}
                  index={i}
                  setOpenModal={setOpenModal}
                />
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <Modal popup show={!!openModal} onClose={() => setOpenModal(undefined)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 text-center">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="text-md">
                <th scope="col" className="px-6 py-3">
                  Set
                </th>
                <th scope="col" className="px-6 py-3">
                  Reps
                </th>
                <th scope="col" className="px-6 py-3">
                  KG
                </th>
                <th scope="col" className="px-6 py-3">
                  Rest
                </th>
              </tr>
            </thead>
            <tbody>
              {openModal?.map((set, i) => (
                <tr key={i}>
                  <td className="px-6 py-4">{i + 1}</td>
                  <td className="px-6 py-4">
                    {showReps(set.reps, set.repsRange)}
                  </td>
                  <td className="px-6 py-4">{showWeight(set.weight)}</td>
                  <td className="px-6 py-4">{set.rest}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const showReps = (
  reps: ExerciseSetFromServer["reps"],
  repsRange: ExerciseSetFromServer["repsRange"]
) => {
  if (typeof reps === "number") {
    if (
      repsRange &&
      typeof repsRange === "number" &&
      repsRange > 0 &&
      reps < repsRange
    ) {
      return `(${reps} - ${repsRange})`;
    }
    return reps;
  }
  if (reps && reps.length > 0 && reps[0] && typeof reps[0] === "number") {
    return reps.join(" - ");
  }
  if (reps && reps.length > 0 && reps[0]) {
    return reps
      .map((rep) => {
        if (!rep) return "";
        // @ts-ignore
        return rep?.value;
      })
      .join(" - ");
  }
  return "-";
};

export const showWeight = (weight: ExerciseSetFromServer["weight"]) => {
  if (typeof weight === "number") {
    return weight;
  }
  if (
    weight &&
    weight.length > 0 &&
    weight[0] &&
    typeof weight[0] === "number"
  ) {
    return weight.join(" - ");
  }
  if (weight && weight.length > 0 && weight[0]) {
    // @ts-ignore
    return weight.map((rep) => rep.value).join(" - ");
  }
  return "-";
};
type ExerciseRowProps = {
  exercise: {
    name: string;
    weeks: {
      sets: ExerciseSetFromServer[] | undefined;
      notes: string | undefined;
    }[];
  };
  index: number;
  setOpenModal: (value: ExerciseSetFromServer[] | undefined) => void;
};
const ExerciseRow: React.FC<ExerciseRowProps> = ({
  exercise,
  index,
  setOpenModal,
}) => {
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";
  return (
    <tr key={index} className={`${rowBgColor} dark:border-gray-700`}>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {exercise.name}
      </th>
      {exercise.weeks.map((week, i) => (
        <>
          <td key={i + ""} className="px-6 py-4">
            <button
              onClick={() => setOpenModal(week.sets)}
              type="button"
              className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.6rem] px-2 py-1.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              MOSTRA
            </button>
          </td>
          <td className="px-6 py-4 line-clamp-2 text-left">
            <Tooltip className="p-2" content={week.notes}>
              {week.notes}
            </Tooltip>
          </td>
        </>
      ))}

      <td className="px-6 py-4">
        <EditButton onClick={() => null} />
      </td>
    </tr>
  );
};
