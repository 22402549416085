import { MEASURES_LABELS } from "../../../libs/measures/constants";
import {
  BodyMeasureKeys,
  PlicometryMeasureKeys,
} from "../../../libs/measures/types";

export const HISTORY_FORM_FIELD_NAMES = [
  "weight",
  ...(Object.keys(BodyMeasureKeys) as Array<keyof typeof BodyMeasureKeys>),
  ...(Object.keys(PlicometryMeasureKeys) as Array<
    keyof typeof PlicometryMeasureKeys
  >),
] as const;

export type HistoryFormType = Record<
  (typeof HISTORY_FORM_FIELD_NAMES)[number],
  string
>;

export const HISTORY_FORM_FIELDS = HISTORY_FORM_FIELD_NAMES.map((field) => ({
  name: field,
  label:
    MEASURES_LABELS[field] +
    (field === "weight" ? " (KG)" : "") +
    // @ts-ignore
    (BodyMeasureKeys[field] ? " (CM)" : "") +
    // @ts-ignore
    (PlicometryMeasureKeys[field] ? " (MM) (Plicometria)" : ""),
  rightAddon: field === "weight" ? "KG" : "CM",
  rules: {
    required: false,
    validate: {
      lenght: (value: string) => {
        if (!value) return true;

        const lenght = parseInt(value);
        return lenght >= 1 && lenght <= 300 && value.match(/^\d*\.?\d{1,2}$/)
          ? true
          : "Inserisci un valore compreso tra 1,00 e 300,00. Usa il . come separatore decimale";
      },
    },
  },
  type: "text",
}));
