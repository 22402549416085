import { useForm } from "react-hook-form";
import {
  useDeleteUserMutation,
  useUpdateUserFormMutation,
  useUpdateUserProfileMutation,
} from "../../../libs/user/api";
import { selectGetUserResult } from "../../../libs/user/selectors";
import {
  PERSONAL_PROFILE_FORM_FIELDS,
  PersonalProfileFormType,
} from "./fields";
import { useState } from "react";
import { EditButton, SubmitButton } from "../../Button";
import { DeleteModal } from "../../Modal/DeleteModal";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useAppSelector } from "../../../store";
import { InfoItem } from "./UserProfileFormItem";

export const UserProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const user = useAppSelector((state) => selectGetUserResult(state, userId));

  const [deleteUser, { isLoading: isLoadingDelete, error: errorDelete }] =
    useDeleteUserMutation();

  const [
    updateUserProfile,
    { error: userProfileUpdateError, isLoading: isUserProfileUpdatePending },
  ] = useUpdateUserProfileMutation();

  const [
    updateUserForm,
    { error: userFormUpdateError, isLoading: isUserFormUpdatePending },
  ] = useUpdateUserFormMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PersonalProfileFormType>();

  const onSave = (data: PersonalProfileFormType) => {
    if (user) {
      updateUserProfile({
        profileInfo: data,
        currentUser: user,
      })
        .unwrap()
        .then(() => {
          updateUserForm({
            userFormData: data,
            currentUser: user,
          })
            .unwrap()
            .then(() => {
              setIsEditMode(false);
            });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)} className="">
      <div className="flex flex-wrap">
        {PERSONAL_PROFILE_FORM_FIELDS.map((field, i) => {
          return (
            <div className="flex" key={i + ""}>
              <InfoItem
                fieldObj={field}
                register={register}
                errors={errors}
                setValue={setValue}
                isEditMode={isEditMode}
              />
            </div>
          );
        })}
      </div>
      <div className="flex items-center space-x-4">
        {isEditMode ? (
          <>
            <button onClick={() => setIsEditMode(false)}>Annulla</button>

            <SubmitButton
              isPending={isUserProfileUpdatePending || isUserFormUpdatePending}
            >
              Salva
            </SubmitButton>
          </>
        ) : (
          <>
            <EditButton
              onClick={() => {
                setIsEditMode(true);
              }}
            />
            <DeleteModal
              title={`Elimina ${user?.name} ${user?.surname}`}
              onDelete={() => {
                user && deleteUser(user);
                navigate(ROUTES.DASHBOARD);
              }}
              isDeleteInProgress={isLoadingDelete}
            />
          </>
        )}
        {(errorDelete || userProfileUpdateError || userFormUpdateError) && (
          <p className="text-red-500">
            C'è stato un errore, riprova più tardi!
          </p>
        )}
      </div>
    </form>
  );
};
