import {
  BodyMeasuresImgsResponse,
  BodyMeasuresResponse,
  WeightMeasuresResponse,
} from "../measures/types";
import { NutritionResponse } from "../nutrition/types";

export type HistoryItem = {
  id: number | null;
  date: number;
  idUser: number;
  historyType: keyof typeof HISTORY_TYPES;
  historyParameters?: HistoryParameters[];
  historyImages?: HistoryImages[];
};

export const HISTORY_TYPES = {
  NUTRITION: "NUTRITION",
  WEIGHT: "WEIGHT",
  MEASUREMENT: "MEASUREMENT",
} as const;

export type HistoryParameters = {
  id: number | null;
  idHistory: number | null;
  idUser: number | null;
  key: string;
  value: string;
};

export type HistoryImages = {
  id: number | null;
  idHistory: number | null;
  idUser: number | null;
  image: string;
  fileName: string;
};

export type HistoryResponse = HistoryItem[];
export type HistoryRequest = HistoryItem;

export type HistoryData = {
  measureHistoryList: BodyMeasuresResponse;
  imgHistoryList: BodyMeasuresImgsResponse;
  weightHistoryList: WeightMeasuresResponse;
  nutritionHistoryList: NutritionResponse;
};
