import { ExercisesTable } from "../../components/ExercisesTable";
import { Skeleton } from "../../components/Skeleton";
import { useGetAllExercisesQuery } from "../../libs/exercises/api";

export default function Exercises() {
  const { data: exercises, isLoading, error } = useGetAllExercisesQuery();

  if (isLoading) {
    return <Skeleton />;
  }
  if (error) {
    return <div>Error...</div>;
  }
  return (
    <div>
      <ExercisesTable exercises={exercises} />
    </div>
  );
}
