import { useForm } from "react-hook-form";
import { SubmitButton } from "../../Button";
import { HISTORY_FORM_FIELDS, HistoryFormType } from "./fields";
import {
  useSetBodyMeasuresMutation,
  useSetWeightMeasuresMutation,
} from "../../../libs/history/api";
import { getToday } from "../../../libs/utils/date";
import { useParams } from "react-router-dom";

export const AddHistoryForm = () => {
  const { id: userId } = useParams();
  const userIdParsed = userId ? parseInt(userId) : -1;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HistoryFormType>();

  const [setBodyMeasures, { isLoading: setBodyIsLoading }] =
    useSetBodyMeasuresMutation();
  const [setWeightMeasures, { isLoading: setWeightIsLoading }] =
    useSetWeightMeasuresMutation();

  const onSubmit = (data: HistoryFormType) => {
    const { weight, ...bodyMeasures } = data;
    if (weight) {
      setWeightMeasures({
        weightMeasures: { [getToday()]: { weight: weight } },
        userId: userIdParsed,
      });
    }

    setBodyMeasures({
      bodyMeasures: { [getToday()]: { ...bodyMeasures } },
      userId: userIdParsed,
    });
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-h-[80vh] overflow-y-auto flex flex-col items-center gap-4"
      >
        <div className="flex flex-row flex-wrap justify-center items-center gap-8">
          {HISTORY_FORM_FIELDS.map(({ label, name, rules, type }) => (
            <div
              key={label + name}
              className="flex-col items-center justify-between mb-4 pr-4"
            >
              <div className="text-xs font-medium text-gray-400 dark:text-gray-500">
                {label}
              </div>
              <input
                className="text-sm font-medium text-gray-800 pl-2 w-fit border-2 border-gray-200"
                type={type}
                {...register(name, { ...rules })}
              />
              {errors[name] && (
                <div className="text-red-500 text-xs max-w-[200px]">
                  {errors[name]?.message}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mb-4">
          <SubmitButton isPending={setBodyIsLoading || setWeightIsLoading}>
            Submit
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};
