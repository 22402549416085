import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../App";
import { SubmitButton } from "../../components/Button";
import { useForm } from "react-hook-form";
import { ROUTES } from "../../constants/routes";

type LoginForm = {
  phoneNumber: string;
  pin: string;
};

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signin } = useAuth();

  let from = location.state?.from?.pathname || "/";

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>();

  function onSubmit(data: LoginForm) {
    let phoneNumber = data.phoneNumber;
    if (!phoneNumber.startsWith("+39")) {
      phoneNumber = "+39" + phoneNumber;
    }
    signin(
      {
        phoneNumber,
        pin: data.pin,
      },
      () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.

        navigate(ROUTES.DASHBOARD, { replace: true });
        document.location.reload();
      }
    );
  }
  return (
    <div className="relative overflow-x-auto max-w-[500px] m-auto shadow-md sm:rounded-lg">
      <div className="relative bg-white dark:bg-gray-800">
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          <h1 className="font-medium">
            Esegui la login per accedere alla Dashboard
          </h1>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 p-4">
        <div>
          <label
            htmlFor="phoneNumber"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Numero di telefono
          </label>
          <div className="flex items-center justify-between relative">
            <span className="absolute left-[2px] text-gray-800 bg-slate-200 p-2 rounded-lg">
              +39
            </span>
            <input
              {...register("phoneNumber", {
                required: "Inserisci un numero valido",
              })}
              aria-invalid={errors.phoneNumber ? "true" : "false"}
              type="phoneNumber"
              id="phoneNumber"
              className="pl-14 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder=""
              required
            />
          </div>
        </div>
        {errors.phoneNumber && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.phoneNumber?.message?.toString()}
          </p>
        )}
        <div>
          <label
            htmlFor="pin"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            {...register("pin", { required: "Inserisci un numero valido" })}
            aria-invalid={errors.pin ? "true" : "false"}
            type="password"
            id="pin"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder=""
            required
          />
        </div>
        {errors.pin && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.pin?.message?.toString()}
          </p>
        )}
        <SubmitButton isPending={false}>Login</SubmitButton>
      </form>
    </div>
  );
}
