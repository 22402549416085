import * as React from "react";

import "./index.css";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Flowbite, useThemeMode } from "flowbite-react";

import { Layout } from "./Layout";
import DashboardPage from "./pages/Dashboard";
import UserProfilePage from "./pages/UserProfile";
import WorkoutPage from "./pages/Workout";
import LoginPage from "./pages/Login";
import { ROUTES } from "./constants/routes";
import WorkoutDetails from "./pages/WorkoutDetails";
import Exercises from "./pages/Exercises";
import { useLoginMutation } from "./libs/api";
// import { initFlowbite } from 'flowbite';
/**
 * This represents some generic auth provider API, like Firebase.
 */
// const fakeAuthProvider = {
//   isAuthenticated: false,
//   signin(callback: VoidFunction) {
//     fakeAuthProvider.isAuthenticated = true;
//     setTimeout(callback, 100); // fake async
//   },
//   signout(callback: VoidFunction) {
//     fakeAuthProvider.isAuthenticated = false;
//     setTimeout(callback, 100);
//   },
// };

const flowbiteTheme = {
  theme: {
    tab: {
      tablist: {
        tabitem: {
          styles: {
            default: {
              active: {},
            },
          },
        },
      },
    },
  },
};

export default function App() {
  // React.useEffect(() => {
  //   initFlowbite();
  // }, []);
  return (
    <Flowbite theme={flowbiteTheme}>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            <Route
              path={ROUTES.DASHBOARD}
              element={
                <RequireAuth>
                  <DashboardPage />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.USER}
              element={
                <RequireAuth>
                  <UserProfilePage />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.WORKOUTS}
              element={
                <RequireAuth>
                  <WorkoutPage />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.WORKOUT_DETAILS}
              element={
                <RequireAuth>
                  <WorkoutDetails />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.EXERCISES}
              element={
                <RequireAuth>
                  <Exercises />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </Flowbite>
  );
}

interface AuthContextType {
  user: any;
  signin: (
    user: {
      phoneNumber: string;
      pin: string;
    },
    callback?: VoidFunction
  ) => void;
  signout: (callback?: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<Record<string, string | null> | null>({
    jwt: window.localStorage.getItem("userJwt"),
  });

  const [login, { data, isLoading, error }] = useLoginMutation();

  React.useEffect(() => {
    if (data) {
      setUser(data);
      window.localStorage.setItem("userJwt", JSON.stringify(data.jwt));
    }
  }, [data]);

  const signin = (
    newUser: {
      phoneNumber: string;
      pin: string;
    },
    callback?: VoidFunction
  ) => {
    return login(newUser).then((res) => {
      callback && callback();
    });
  };
  const signout = (callback?: VoidFunction) => {
    window.localStorage.removeItem("userJwt");
    setUser(null);
    callback && callback();
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.user?.jwt) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
