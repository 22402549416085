import { Modal } from "flowbite-react";

import { NoteForm } from "./NoteForm";
import { NoteList } from "./NoteList";

type NoteModalProps = {
  isModalOpen: boolean;
  setOpenModal: (state: boolean) => void;
};
export const NoteModal: React.FC<NoteModalProps> = ({
  isModalOpen,
  setOpenModal,
}) => {
  return (
    <Modal popup show={!!isModalOpen} onClose={() => setOpenModal(false)}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4 ">
          <div className="text-2xl font-bold px-4 mt-4">Note</div>
          {isModalOpen && <NoteList onClose={() => setOpenModal(false)} />}
        </div>
      </Modal.Body>
    </Modal>
  );
};
