export const ACTIVITY_LEVELS = {
  SEDENTARY: "SEDENTARY",
  MODERATELY_ACTIVE: "MODERATELY_ACTIVE",
  VERY_ACTIVE: "VERY_ACTIVE",
} as const;
export const WEEKLY_WORKOUT_DAYS = ["1", "2", "3", "4", "5", "6", "7"] as const;

export const WORKOUT_DURATION = [
  "30",
  "45",
  "60",
  "75",
  "90",
  "105",
  "120",
] as const;

export const WORKOUT_TIME_SLOT = {
  MORNING: "MORNING",
  AFTERNOON: "AFTERNOON",
  EVENING: "EVENING",
} as const;

export const STEPS_PER_DAY = {
  LOW: "1",
  MEDIUM: "2",
  HIGH: "3",
} as const;

export const WORKOUT_SINCE = {
  LOW: "1",
  MEDIUM: "2",
  HIGH: "3",
} as const;

export type UserDataExt = {
  leanBodyMass?: string;
  weight?: string;
  showInitialForm?: boolean;
  status?: "DISABLED" | "ACTIVE";

  // TO NOT BE USED IN USER APP
  profileNotes?: string;
  paymentNotes?: string;
};

export type UserFormData = {
  fiscalCode?: string;
  address?: string;
  activityLevel?: keyof typeof ACTIVITY_LEVELS;
  weeklyWorkoutDays?: (typeof WEEKLY_WORKOUT_DAYS)[number];
  workoutDuration?: (typeof WORKOUT_DURATION)[number];
  workoutTimeSlot?: keyof typeof WORKOUT_TIME_SLOT;
  stepsPerDay: keyof typeof STEPS_PER_DAY;
  workoutSince: keyof typeof WORKOUT_SINCE;
  goal?: string;
};

export type Note = {
  msg?: string;
  createdAt?: number;
  readAt?: number | null;
};
export type UserNote = {
  user?: Note[];
  pt?: Note[];
};

export type Message = {
  id?: number;
  idUser: number;
  text: UserNote;
};

export type User = {
  name?: string;
  surname?: string;
  email?: string;
  userData?: UserDataExt;
  messages?: Message[];
  form?: {
    id?: number;
    userId?: number;
    data: UserFormData;
  };
  id?: number | null;
  birthday?: string;
  gender?: "M" | "F";
  phoneNumber?: string;
  pt?: {
    name: string | null;
    data: string | null;
    id: number;
  };
  height?: string;
  leanBodyMass?: number;
  workouts?: { id: string }[];
  diets?: { id: string }[];
  token?: string;
  jwt?: string;
  pin?: string;
};

export type UserResponse = Omit<User, "userData" | "messages" | "form"> & {
  userData?: string;
  messages?: {
    id?: number;
    idUser: number;
    text: string;
  }[];
  form?: {
    id?: number;
    userId?: number;
    data: string;
  };
};
export type UserRequest = User;

export type UserProfile = {
  id: number | null;
  jwt?: string;
  token?: string;
  phoneNumber?: string;
  name?: string;
  surname?: string;
  height?: number;
  weight?: number;
  biceps?: number;
  chest?: number;
  waist?: number;
};

export type UserCreate = {
  tel: string;
  token: string;
};

export type UserCreateRequest = {
  phoneNumber: string;
  name: string;
  surname: string;
};
export type UserCreateResponse = UserCreate;
