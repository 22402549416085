import { api } from "../api";
import { Exercise, ExerciseRequest, ExercisesResponse } from "./types";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllExercises: builder.query<Exercise[], void>({
      query: () => ({
        url: "/exercise",
        method: "GET",
      }),
      transformResponse: transformExercises,
      providesTags: ["Exercises"],
    }),
    createExercise: builder.mutation<ExercisesResponse, Omit<Exercise, "id">>({
      query: (exerciseReq) => ({
        url: "/exercise/",
        method: "POST",
        body: transformExerciseRequest(exerciseReq),
      }),
      invalidatesTags: ["Exercises"],
    }),
    updateExercise: builder.mutation<ExercisesResponse, Exercise>({
      query: (exerciseReq) => ({
        url: "/exercise/",
        method: "PUT",
        body: transformExerciseRequest(exerciseReq),
      }),
      invalidatesTags: ["Exercises"],
    }),
    deleteExercise: builder.mutation<ExercisesResponse, string>({
      query: (exerciseId) => ({
        url: "/exercise/" + exerciseId,
        method: "DELETE",
      }),
      invalidatesTags: ["Exercises"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllExercisesQuery,
  useCreateExerciseMutation,
  useUpdateExerciseMutation,
  useDeleteExerciseMutation,
} = extendedApi;

function transformExercises(response: ExercisesResponse): Exercise[] {
  return response.map((exercise) => {
    let tags: string[] = [];
    let videoUrls: string[] = [];
    try {
      tags = JSON.parse(exercise.exercisesData)?.tags || [];
      videoUrls = JSON.parse(exercise.exercisesData)?.videoUrls || [];
    } catch (e) {
      console.error(e);
    }
    return {
      ...exercise,
      notes: exercise.note,
      tags,
      videoUrls,
      name: exercise.desc || "",
      id: exercise.id + "",
    };
  });
}

function transformExerciseRequest(
  exercise: Exercise | Omit<Exercise, "id">
): ExerciseRequest {
  const { name, tags, videoUrls, notes, ...rest } = exercise;
  const exercisesData = JSON.stringify({
    tags: exercise.tags || [],
    videoUrls: exercise.videoUrls || [],
  });

  return {
    desc: exercise.name,
    exercisesData,
    link: "",
    note: notes,
    sets: [],
    // @ts-ignore
    id: exercise.id ? parseInt(exercise.id) : null,
    ...rest,
    type: "DEFAULT",
  };
}
