import {
  UseFieldArrayReturn,
  UseFormRegister,
  FieldErrors,
  useFieldArray,
  useFormContext,
  UseFormGetValues,
} from "react-hook-form";
import { FormValues } from ".";
import { CloneButton, DeleteButton, PlusButton } from "../../Button";
import { Exercise } from "../../../libs/workout/types";
import { useState } from "react";

export const SetTableSuperSet: React.FC<{
  setsFields: UseFieldArrayReturn<FormValues, "sets", "id">;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  isEditMode: boolean;
  control: any;
  subExercises: Exercise["subExercises"];
  getValues: UseFormGetValues<FormValues>;
}> = ({
  setsFields,
  isEditMode,
  register,
  errors,
  control,
  subExercises,
  getValues,
}) => {
  const { fields: sets, append, remove } = setsFields;

  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="text-md  text-center">
          <th scope="col" className="px-6 py-3">
            Set
          </th>
          <th scope="col" className="px-6 py-3">
            Reps
          </th>
          <th scope="col" className="px-6 py-3">
            Rest
          </th>
        </tr>
      </thead>
      <tbody>
        {sets?.map((set, i) => (
          <>
            <tr key={set.id} className="border-b-2">
              <td className="px-6 py-4">
                {isEditMode ? (
                  <>
                    <DeleteButton variant="icon" onClick={() => remove(i)} />
                    <CloneButton
                      variant="icon"
                      onClick={() => append(getValues(`sets.${i}`))}
                    />
                  </>
                ) : (
                  i + 1
                )}
              </td>
              <td className="px-6 py-4">
                {
                  <tr className="p-5 text-lg font-semibold text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    <td className="px-6 py-3">
                      <NestedReps
                        setIndex={i}
                        register={register}
                        errors={errors}
                        isEditMode={isEditMode}
                        control={control}
                        subExercises={subExercises}
                      />
                    </td>
                  </tr>
                }
              </td>
              <td className="px-6 pr-8 py-4 relative min-w-[120px]">
                <input
                  type="number"
                  step={15}
                  max={1000}
                  min={0}
                  disabled={!isEditMode}
                  {...register(`sets.${i}.rest`, {
                    valueAsNumber: true,
                    min: 0,
                    max: 999,
                  })}
                  className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <span className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                  sec
                </span>
              </td>
            </tr>
          </>
        ))}
        {isEditMode && (
          <tr className="p-5 text-lg font-semibold text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <td className="px-6 py-3">
              <PlusButton
                onClick={() =>
                  append({
                    reps:
                      subExercises?.map((subExercise) => ({
                        ...subExercise,
                        value: 0,
                      })) || [],
                    rest: 0,
                  })
                }
                variant="icon"
              />
              <p>{errors.sets?.root?.message}</p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const NestedReps: React.FC<{
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  isEditMode: boolean;
  control: any;
  setIndex: number;
  subExercises: Exercise["subExercises"];
}> = ({ setIndex, register, control, isEditMode, errors, subExercises }) => {
  const { fields: reps } = useFieldArray({
    control,
    name: `sets.${setIndex}.reps`,
    rules: {
      maxLength: subExercises?.length,
    },
  });

  // const errorRepsRange =
  //   // @ts-ignore
  //   (errors.sets && errors.sets[i] && errors.sets[i].repsRange) || null;

  //   useEffect(() => {
  //     if (reps.length === 0 && subExercises) {
  //       subExercises.forEach((subExercise) => {
  //         append({
  //           ...subExercise,
  //           value: 0,
  //         });
  //       });
  //     }
  //   }, [subExercises?.length]);

  return (
    <>
      <div className="flex items-start justify-center space-x-1 my-2">
        {reps?.map((rep, j) => (
          <div className="flex flex-col items-start justify-center space-y-1">
            <div className="text-[0.5rem] leading-[0.6rem] text-ellipses">
              <p className="">{subExercises && subExercises[j]?.name}</p>
            </div>
            <input
              key={j}
              type="text"
              disabled={!isEditMode}
              {...register(`sets.${setIndex}.reps.${j}.value`, {
                valueAsNumber: true,
                min: 0,
                max: 999,
              })}
              className="min-w-[40px] text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <RepRange
              setIndex={setIndex}
              repIndex={j}
              register={register}
              isEditMode={isEditMode}
            />
          </div>
        ))}
      </div>
      <p>{errors.sets?.root?.message}</p>
    </>
  );
};

const RepRange: React.FC<{
  register: UseFormRegister<FormValues>;
  isEditMode: boolean;
  setIndex: number;
  repIndex: number;
}> = ({ setIndex, register, isEditMode, repIndex }) => {
  const {
    getValues,
    watch,
    formState: { isSubmitted, errors },
  } = useFormContext();

  // @ts-ignore
  const errorRepsRange = errors.sets?.[setIndex]?.repsRange?.[repIndex]?.value;
  const set = getValues("sets")[setIndex];
  const watchRepRange = watch(`sets.${setIndex}.repsRange.${repIndex}.value`);

  const [showRange, setShowRange] = useState(
    set.repsRange &&
      set.repsRange[repIndex] &&
      !isNaN(set.repsRange[repIndex].value) &&
      set.repsRange[repIndex].value > 0
  );

  return (
    <>
      {showRange && (
        <div className="relative">
          <input
            type="text"
            disabled={!isEditMode}
            value={isNaN(watchRepRange) ? 0 : watchRepRange}
            {...register(`sets.${setIndex}.repsRange.${repIndex}.value`, {
              valueAsNumber: true,
              value: isNaN(watchRepRange) ? 0 : watchRepRange,
              max: {
                value: 999,
                message: "Deve essere < di 999",
              },
              validate: (value) =>
                value >= set.reps[repIndex].value ||
                value === 0 ||
                "Deve essere > delle reps",
            })}
            className="min-w-[40px] text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <div className="text-[0.5rem] leading-[0.6rem] text-ellipses text-center">
            <p className="">RANGE</p>
          </div>
          {errorRepsRange && isSubmitted && (
            <span className="text-red-500 text-xs inline-block absolute left-0">
              {errorRepsRange.message}
            </span>
          )}
        </div>
      )}
      {!showRange && isEditMode && (
        <button
          onClick={() => setShowRange(true)}
          className="text-xs text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.6rem] px-2 py-1.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
        >
          + Range
        </button>
      )}
    </>
  );
};
