import { useState } from "react";
import { Workout } from "../../libs/workout/types";
import { getIsTemplate } from "../../libs/workout/utils";
import { PlusButton } from "../Button";
import { ExerciseModal } from "./ExerciseModal";
import { ExerciseRow } from "./ExerciseRow";
import { SetModal } from "./SetModal";
import { getColumns, getExercisesRowsObj } from "./utils";
import { Skeleton } from "../Skeleton";
import React from "react";
import { EXERCISE_TYPES } from "../../libs/exercises/types";
import { MuscleSummaryTable } from "./MuscleSummaryTable";
import { FeedbackTable } from "./FeedbackTable";

type WorkoutTableProps = {
  workout?: Workout;
  isLoading?: boolean;
  error?: any;
  isEditMode?: boolean;
  showWorkoutSetMode?: boolean;
};

export const WorkoutTables: React.FC<WorkoutTableProps> = ({
  workout,
  isLoading,
  error,
  isEditMode,
  showWorkoutSetMode,
}) => {
  const [openSetModal, setOpenSetModal] = useState<
    | {
        dayIndex: number;
        exerciseIndex: number;
        weekIndex: number;
      }
    | undefined
  >();
  const [openExerciseModal, setOpenExerciseModal] = useState<
    | {
        dayIndex: number;
        exToCloneIndex?: number;
        exType?: keyof typeof EXERCISE_TYPES;
      }
    | undefined
  >();

  if (showWorkoutSetMode) {
    return (
      <div className="flex flex-col text-xs text-gray-700 uppercase dark:text-gray-400">
        <a href="/workouts">
          <h2 className="font-bold center">Aggiungi un workout all'utente</h2>
          <span>Vai alla pagina dei workouts</span>
        </a>
      </div>
    );
  }
  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !workout || !workout.data) {
    return <div>Error...</div>;
  }
  const isTemplate = getIsTemplate(workout);

  const weeksColumns = workout.data.map((_, i) => "Week " + (i + 1).toString());
  const workoutDays = workout.data[0]?.days?.map((day, i) => ({
    name: day.dayNumber,
    dayIndex: i,
  }));

  return (
    <div className="flex flex-col">
      {workoutDays &&
        workoutDays.map((day, index) => (
          <div
            key={index + "-day"}
            className="relative overflow-x-auto shadow-md sm:rounded-lg my-8"
          >
            <table className="table-auto w-full text-sm text-gray-500 dark:text-gray-400 text-center">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className="text-md">
                  <th
                    scope="col"
                    colSpan={isEditMode ? 2 : 1}
                    className="w-[400px] px-6 py-3"
                  >
                    Day {index + 1}
                  </th>
                  {weeksColumns.map((column) => (
                    <th
                      key={column + "-day-column-" + index}
                      scope="col"
                      colSpan={getColumns(isTemplate).length}
                      className="w-[300px] px-6 py-3 text-center"
                    >
                      {column}
                    </th>
                  ))}
                </tr>
                <tr>
                  {isEditMode && (
                    <th scope="col" className="px-6 py-3">
                      Modifica
                    </th>
                  )}
                  <th scope="col" className="px-6 py-3">
                    Esercizio
                  </th>
                  {weeksColumns.map((_, i) =>
                    getColumns(isTemplate).map((column) => (
                      <th
                        key={column + "-subcolumn-" + index}
                        scope="col"
                        className="px-6 py-3"
                      >
                        {column}
                      </th>
                    ))
                  )}
                </tr>
              </thead>
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"></thead>
              <tbody>
                {getExercisesRowsObj(workout, index)?.map((exercise, i) => (
                  <ExerciseRow
                    key={i + "-exercise-row-" + index}
                    exercise={exercise}
                    dayIndex={index}
                    index={i}
                    setOpenSetModal={(weekIndex) =>
                      setOpenSetModal({
                        dayIndex: index,
                        exerciseIndex: i,
                        weekIndex: weekIndex,
                      })
                    }
                    setOpenExerciseModal={(exIndex) => {
                      const exType =
                        workout.data[0].days[index]?.exercises[exIndex]
                          ?.exercise?.type;
                      setOpenExerciseModal({
                        dayIndex: index,
                        exToCloneIndex: exIndex,
                        exType: exType,
                      });
                    }}
                    isTemplate={isTemplate}
                    isEditMode={isEditMode}
                    isLastRow={
                      i === getExercisesRowsObj(workout, index).length - 1
                    }
                    isLastDay={index === workoutDays.length - 1}
                  />
                ))}
                {isEditMode && (
                  <tr className="p-5 text-lg font-semibold text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    <td className="px-6 py-3">
                      <PlusButton
                        onClick={() => {
                          setOpenExerciseModal({ dayIndex: index });
                        }}
                        variant="icon"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ))}
      <FeedbackTable workout={workout} weeksColumns={weeksColumns} />
      <MuscleSummaryTable workout={workout} weeksColumns={weeksColumns} />
      <SetModal
        openModal={openSetModal}
        setOpenModal={setOpenSetModal}
        isEditMode={!!isEditMode}
        workout={workout}
      />

      <ExerciseModal
        dayIndex={openExerciseModal?.dayIndex}
        exToCloneIndex={openExerciseModal?.exToCloneIndex}
        exType={openExerciseModal?.exType}
        setOpenModal={setOpenExerciseModal}
      />
    </div>
  );
};
