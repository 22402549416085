import { getKcal } from "../utils/diet";
import { mergeDeep } from "../utils/obj";
import { initialDays } from "./hooks";
import {
  Diet,
  DietFromApiDay,
  DietTableIntegrationsItem,
  DietTableKeys,
  DietTableMacros,
  DietTableMacrosItem,
  DietTableMacrosKeys,
  DietTables,
} from "./types";

const DEFAULT_TABLES: DietTables = {
  ON: {
    CARB: "0",
    PROTEIN: "0",
    FAT: "0",
  },
  OFF: {
    CARB: "0",
    PROTEIN: "0",
    FAT: "0",
  },
  REFEED: {
    CARB: "0",
    PROTEIN: "0",
    FAT: "0",
  },
};

export const getMacrosDifference = (diet: Diet): DietTableMacrosItem[] => {
  const macrosDifferenceList = [] as DietTableMacrosItem[];
  const macrosDaysMatrix = reduceMacrosDays(diet);
  macrosDaysMatrix.forEach((week, weekIndex) => {
    const macrosDifference = {
      CARB: "0",
      PROTEIN: "0",
      FAT: "0",
    };
    if (weekIndex === 0) {
      return macrosDifferenceList.push(macrosDifference);
    }
    const dayOn = week.find((day) => day.dayType === "ON");
    const carb = dayOn?.dietParameters.find(
      (param) => param.key === DietTableMacros.CARB
    );
    const protein = dayOn?.dietParameters.find(
      (param) => param.key === "PROTEIN"
    );
    const fat = dayOn?.dietParameters.find((param) => param.key === "FAT");
    // const dayOff = week.find((day) => day.dayType === "OFF");
    // const dayRefeed = week.find((day) => day.dayType === "REFEED");

    const weekCarb = parseInt(carb?.value ? carb.value + "" : "0");
    const weekProtein = parseInt(protein?.value ? protein.value + "" : "0");
    const weekFat = parseInt(fat?.value ? fat.value + "" : "0");

    const pastWeekDayOn = macrosDaysMatrix[weekIndex - 1].find(
      (day) => day.dayType === "ON"
    );

    const pastWeekCarbObj = pastWeekDayOn?.dietParameters.find(
      (param) => param.key === DietTableMacros.CARB
    );
    const pastWeekProteinObj = pastWeekDayOn?.dietParameters.find(
      (param) => param.key === "PROTEIN"
    );
    const pastWeekFatObj = pastWeekDayOn?.dietParameters.find(
      (param) => param.key === "FAT"
    );
    const pastWeekCarb = parseInt(
      pastWeekCarbObj?.value ? pastWeekCarbObj.value + "" : "0"
    );
    const pastWeekProtein = parseInt(
      pastWeekProteinObj?.value ? pastWeekProteinObj.value + "" : "0"
    );
    const pastWeekFat = parseInt(
      pastWeekFatObj?.value ? pastWeekFatObj.value + "" : "0"
    );

    macrosDifference.CARB = (weekCarb - pastWeekCarb).toString();

    macrosDifference.PROTEIN = (weekProtein - pastWeekProtein).toString();

    macrosDifference.FAT = (weekFat - pastWeekFat).toString();

    macrosDifferenceList.push(macrosDifference);
  });
  return macrosDifferenceList;
};

export const getStartingMacros = (diet: Diet): DietTables => {
  const macrosDaysMatrix = reduceMacrosDays(diet);
  const firstWeek = macrosDaysMatrix[0] || [];
  const firstWeekOn = firstWeek.find((day) => day.dayType === "ON");
  const firstWeekOff = firstWeek.find((day) => day.dayType === "OFF");
  const firstWeekRefeed = firstWeek.find((day) => day.dayType === "REFEED");

  const firstWeekOnCarb =
    firstWeekOn?.dietParameters.find(
      (param) => param.key === DietTableMacros.CARB
    )?.value || "0";
  const firstWeekOnProtein =
    firstWeekOn?.dietParameters.find((param) => param.key === "PROTEIN")
      ?.value || "0";
  const firstWeekOnFat =
    firstWeekOn?.dietParameters.find((param) => param.key === "FAT")?.value ||
    "0";

  const firstWeekOffCarb =
    firstWeekOff?.dietParameters.find(
      (param) => param.key === DietTableMacros.CARB
    )?.value || "0";
  const firstWeekOffProtein =
    firstWeekOff?.dietParameters.find((param) => param.key === "PROTEIN")
      ?.value || "0";
  const firstWeekOffFat =
    firstWeekOff?.dietParameters.find((param) => param.key === "FAT")?.value ||
    "0";

  const firstWeekRefeedCarb =
    firstWeekRefeed?.dietParameters.find(
      (param) => param.key === DietTableMacros.CARB
    )?.value || "0";
  const firstWeekRefeedProtein =
    firstWeekRefeed?.dietParameters.find((param) => param.key === "PROTEIN")
      ?.value || "0";
  const firstWeekRefeedFat =
    firstWeekRefeed?.dietParameters.find((param) => param.key === "FAT")
      ?.value || "0";

  const macrosDaysTable = {
    ON: {
      CARB: firstWeekOnCarb,
      PROTEIN: firstWeekOnProtein,
      FAT: firstWeekOnFat,
    },
    OFF: {
      CARB: firstWeekOffCarb,
      PROTEIN: firstWeekOffProtein,
      FAT: firstWeekOffFat,
    },
    REFEED: {
      CARB: firstWeekRefeedCarb,
      PROTEIN: firstWeekRefeedProtein,
      FAT: firstWeekRefeedFat,
    },
  };
  return macrosDaysTable as DietTables;
};

export const calculateMacros = (
  diet: Diet,
  startingMacros: DietTables,
  macrosDifference: DietTableMacrosItem[]
): Diet => {
  const newDiet = JSON.parse(JSON.stringify(diet)) as Diet;
  // const macrosDaysMatrix = reduceMacrosDays(diet)
  diet.dietsData.days.forEach((day, dayIndex) => {
    if (newDiet.dietsData.days[dayIndex].dietParameters.length < 3) {
      newDiet.dietsData.days[dayIndex].dietParameters = fillDietParameters(
        diet.userId,
        day.id
      );
    }
    if (day.weekNumber === 1) {
      const dayType = day.dayType as DietTableKeys;
      const startingMacrosDay = { ...startingMacros[dayType] };

      newDiet.dietsData.days[dayIndex].dietParameters.forEach((param, i) => {
        if (param.key === DietTableMacros.CARB) {
          newDiet.dietsData.days[dayIndex].dietParameters[i].value =
            startingMacrosDay?.CARB || "0";
        }
        if (param.key === DietTableMacros.PROTEIN) {
          newDiet.dietsData.days[dayIndex].dietParameters[i].value =
            startingMacrosDay?.PROTEIN || "0";
        }
        if (param.key === DietTableMacros.FAT) {
          newDiet.dietsData.days[dayIndex].dietParameters[i].value =
            startingMacrosDay?.FAT || "0";
        }
      });
    } else {
      const dayType = day.dayType as DietTableKeys;
      const pastWeekDay = newDiet.dietsData.days
        .filter((dayV) => dayV.weekNumber === day.weekNumber - 1)
        .find(
          (pday) => pday.dayType === newDiet.dietsData.days[dayIndex].dayType
        );
      const macrosDifferenceTable = { ...macrosDifference[day.weekNumber - 1] };
      newDiet.dietsData.days[dayIndex].dietParameters.forEach((param, i) => {
        const pastWeekValue = pastWeekDay?.dietParameters[i].value + "";
        if (param.key === DietTableMacros.CARB) {
          newDiet.dietsData.days[dayIndex].dietParameters[i].value =
            parseInt(pastWeekValue) +
              parseInt(macrosDifferenceTable?.CARB || "0") || "0";
        }
        if (param.key === DietTableMacros.PROTEIN) {
          newDiet.dietsData.days[dayIndex].dietParameters[i].value =
            parseInt(pastWeekValue || "0") +
              parseInt(macrosDifferenceTable?.PROTEIN || "0") || "0";
        }
        if (param.key === DietTableMacros.FAT) {
          newDiet.dietsData.days[dayIndex].dietParameters[i].value =
            parseInt(pastWeekValue || "0") +
              parseInt(macrosDifferenceTable?.FAT || "0") || "0";
        }
      });
    }
  });
  return newDiet;
};

export const reduceMacrosDays = (diet: Diet): DietFromApiDay[][] => {
  let days = JSON.parse(JSON.stringify(diet.dietsData.days));
  if (days.length < 1) {
    days = initialDays(diet.userId);
  }
  const macrosDaysMatrix = removeDuplicates(days).reduce((acc, day) => {
    const weekIndex = day.weekNumber - 1;
    if (!acc[weekIndex]) {
      acc[weekIndex] = [];
    }
    if (acc[weekIndex].length >= 3) {
      return acc;
    }
    acc[weekIndex].push(day);
    return acc;
  }, [] as DietFromApiDay[][]);
  return macrosDaysMatrix;
};

export const removeDuplicates = (arr: DietFromApiDay[]) => {
  return arr.filter(
    (item, index) =>
      arr.findIndex(
        (day) =>
          day.dayType === item.dayType && day.weekNumber === item.weekNumber
      ) === index
  );
};

const fillDietParameters = (
  userId: number,
  dayId: number | null
): DietFromApiDay["dietParameters"] => {
  return Object.keys(DietTableMacros).map((key) => ({
    id: null,
    idUser: userId,
    idDietDay: dayId,
    key: key as keyof typeof DietTableMacros,
    value: "0",
  }));
};

export const getWeekAverage = (
  week: DietFromApiDay[],
  { on, off, refeed }: { on: number; off: number; refeed: number }
) => {
  const weekMacros = week.map((day) => day.dietParameters);
  const weekMacrosAvg = weekMacros.reduce(
    (acc, curr, i) => {
      curr.forEach((param) => {
        const value =
          typeof param.value === "string" ? parseInt(param.value) : param.value;
        const v = typeof value === "number" ? value : 0;

        const dayTypeAmount = () => {
          if (week[i].dayType === "ON") {
            return on;
          }
          if (week[i].dayType === "OFF") {
            return off;
          }
          if (week[i].dayType === "REFEED") {
            return refeed;
          }
          return 0;
        };
        const amount = dayTypeAmount();

        if (param.key === "CARB") {
          acc.CARB += v * amount;
        }
        if (param.key === "PROTEIN") {
          acc.PROTEIN += v * amount;
        }
        if (param.key === "FAT") {
          acc.FAT += v * amount;
        }
      });
      return acc;
    },
    { CARB: 0, PROTEIN: 0, FAT: 0 }
  );
  return {
    CARB: Math.ceil(weekMacrosAvg.CARB / 7),
    PROTEIN: Math.ceil(weekMacrosAvg.PROTEIN / 7),
    FAT: Math.ceil(weekMacrosAvg.FAT / 7),
  };
};

export const formWeekToTable = (
  week: DietFromApiDay[],
  rowKey: DietTableKeys
): DietTableMacrosItem & { TOTAL: number } => {
  const day =
    week.find((day) => day.dayType === rowKey) || ({} as DietFromApiDay);
  const carbs =
    day.dietParameters.find((param) => param.key === "CARB")?.value + "";
  const protein =
    day.dietParameters.find((param) => param.key === "PROTEIN")?.value + "";
  const fat =
    day.dietParameters.find((param) => param.key === "FAT")?.value + "";
  return {
    CARB: carbs,
    PROTEIN: protein,
    FAT: fat,
    TOTAL:
      getKcal(parseInt(carbs), "CARB") +
      getKcal(parseInt(protein), "PROTEIN") +
      getKcal(parseInt(fat), "FAT"),
  };
};
