import { PlicometryMeasureKeys } from "../measures/types";

export const calculateLeanBodyMass = (
  weight: number,
  plicometry: Record<keyof typeof PlicometryMeasureKeys, number>,
  age: number,
  isFemale: boolean
) => {
  const chest = plicometry.P_CHEST;
  const axillary = plicometry.P_AXILLARY;
  const subscapular = plicometry.P_SUBSCAPULAR;
  const abdominal = plicometry.P_ABDOMINAL;
  const suprailiac = plicometry.P_SUPRAILIAC;
  const thigh = plicometry.P_THIGH;
  const calf = plicometry.P_CALF;

  const sum =
    chest + axillary + subscapular + abdominal + suprailiac + thigh + calf;
  const density = isFemale
    ? 1.097 - 0.00046971 * sum + 0.00000056 * sum * sum - 0.00012828 * age
    : 1.112 - 0.00043499 * sum + 0.00000055 * sum * sum - 0.00028826 * age;

  const fatMass = (4.95 / density - 4.5) * 100;
  return weight - fatMass;
};

export const calculateLeanAndFatBodyMass = (
  weight: number,
  plicometry: Record<keyof typeof PlicometryMeasureKeys, number>,
  age: number,
  isFemale: boolean
) => {
  if (!plicometry || !weight || !age) {
    return null;
  }
  const chest = plicometry.P_CHEST;
  const axillary = plicometry.P_AXILLARY;
  const subscapular = plicometry.P_SUBSCAPULAR;
  const abdominal = plicometry.P_ABDOMINAL;
  const suprailiac = plicometry.P_SUPRAILIAC;
  const thigh = plicometry.P_THIGH;
  const calf = plicometry.P_CALF;

  const sum =
    chest + axillary + subscapular + abdominal + suprailiac + thigh + calf;
  const density = isFemale
    ? 1.097 - 0.00046971 * sum + 0.00000056 * sum * sum - 0.00012828 * age
    : 1.112 - 0.00043499 * sum + 0.00000055 * sum * sum - 0.00028826 * age;

  const fatMass = (4.95 / density - 4.5) * 100;
  return {
    leanBodyMass: weight - fatMass,
    fatMass: fatMass,
  };
};
