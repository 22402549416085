import { updateDifference } from "../../libs/diet/slice";
import {
  DietTableMacros,
  DietTableMacrosItem,
  DietTableMacrosKeys,
} from "../../libs/diet/types";
import { getKcal } from "../../libs/utils/diet";
import { useAppDispatch } from "../../store";
import { getMacrosCellKeys, Input, TableInput } from "./shared";

type DifferenceRowProps = {
  dietDifferenceList: DietTableMacrosItem[];
};
export const DifferenceRow: React.FC<DifferenceRowProps> = ({
  dietDifferenceList,
}) => {
  const dispatch = useAppDispatch();
  const rowBgColor = "bg-gray-50 dark:bg-gray-700";
  return (
    <>
      <tr className={`${rowBgColor} dark:border-gray-700`}>
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          DIFFERENZA SETT.
        </th>
        {dietDifferenceList.map((dietDifference, i) => (
          <td className="px-6 py-4">
            {/* <tr key={i + "keys"}>
              {getMacrosCellKeys(dietDifference).map((cell, index) => (
                <th key={index.toString()} className="px-6">
                  {i > 0 && cell}
                </th>
              ))}
            </tr>

            <tr key={i + "values"}>
              {Object.keys(dietDifference).map(
                (key) =>
                  i > 0 && (
                    <TableInput
                      label="gr"
                      value={dietDifference[key as DietTableMacrosKeys] || "0"}
                      onChange={(e) => {
                        dispatch(
                          updateDifference({
                            weekIndex: i,
                            macro: key as DietTableMacrosKeys,
                            value: e.target.value || "0",
                          })
                        );
                      }}
                    />
                  )
              )}
            </tr> */}
            {Object.keys(DietTableMacros).map((macroKey) => {
              const table = dietDifference;
              const value =
                parseInt(table[macroKey as DietTableMacrosKeys] || "") || 0;
              const kcal = getKcal(value, macroKey as DietTableMacrosKeys);
              if (i === 0) return;
              return (
                <div key={i + macroKey + "macro-key"} className="flex-column">
                  <div className="px-6 uppercase font-bold text-xs">
                    {macroKey}
                  </div>
                  <Input
                    value={value}
                    label="gr"
                    // @ts-ignore
                    onChange={(e) => {
                      dispatch(
                        updateDifference({
                          weekIndex: i,
                          macro: macroKey as DietTableMacrosKeys,
                          value: e.target.value || "0",
                        })
                      );
                    }}
                  />
                </div>
              );
            })}
          </td>
        ))}
      </tr>
    </>
  );
};
