import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useUpdateWorkoutMutation } from "./api";
import { init, selectWorkoutCurrent, selectWorkoutOriginal } from "./slice";
import { Workout } from "./types";

export const useEditWorkout = (wrk?: Workout) => {
  const dispatch = useAppDispatch();
  const originalWrk = useAppSelector(selectWorkoutOriginal);
  const currentWrk = useAppSelector(selectWorkoutCurrent);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updateWorkout, { data, error, isLoading }] = useUpdateWorkoutMutation();

  useEffect(() => {
    if (!wrk) {
      return;
    }
    dispatch(init({ wrk: wrk }));
  }, [wrk])


  const saveWorkout = () => {
    if (!currentWrk) {
      return;
    }
    updateWorkout(currentWrk).then(() => {
      setIsEditMode(false);
    })

  }

  return {
    isEditMode: isEditMode,
    toggleEditMode: () => setIsEditMode(!isEditMode),
    saveWorkout: saveWorkout,
    isUpdatePending: isLoading,
    updateError: error,
  }
}
