import { Button, Modal } from 'flowbite-react';
import { FC, useState } from 'react';
import { DeleteButton } from '../Button';

type DeleteModalProps = {
  title?: string;
  onDelete: () => void;
  isDeleteInProgress?: boolean;
};
export const DeleteModal: FC<DeleteModalProps> = ({ title, onDelete, isDeleteInProgress }) => {
  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal };

  return (
    <>
      <DeleteButton onClick={() => props.setOpenModal('pop-up')} />
      <Modal
        show={props.openModal === 'pop-up'}
        size="sm"
        popup
        onClose={() => props.setOpenModal(undefined)}
        theme={{
          content: {
            inner:
              'max-w-sm w-full bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow-lg overflow-hidden',
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center p-4">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Sei sicuro di voler procedere? L'azione non è reversibile.
            </h3>
            <div className="flex justify-center gap-4">
              <DeleteButton
                isPending={isDeleteInProgress}
                onClick={() => {
                  onDelete();
                  props.setOpenModal(undefined);
                }}
              />
              <Button color="gray" onClick={() => props.setOpenModal(undefined)}>
                Annulla
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
