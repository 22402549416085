import { Button, Dropdown } from "flowbite-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AssignToUserModal } from "../../components/AssignToUserModal";
import {
  DeleteButton,
  EditButton,
  PlusButton,
  SubmitButton,
} from "../../components/Button";
import { WeeksCounter } from "../../components/WeeksCounter";
import { WorkoutTables } from "../../components/WorkoutTables";
import { ROUTES } from "../../constants/routes";
import { useGetUsersQuery } from "../../libs/users/api";
import {
  useDeleteWorkoutMutation,
  useGetWorkoutQuery,
} from "../../libs/workout/api";
import { useEditWorkout } from "../../libs/workout/hooks";
import {
  addWeek,
  removeWeek,
  addDay,
  removeDay,
  selectWorkoutCurrent,
  editNote,
  editName,
} from "../../libs/workout/slice";
import { Workout, WORKOUT_TYPES } from "../../libs/workout/types";
import { useAppDispatch, useAppSelector } from "../../store";
import { Skeleton } from "../../components/Skeleton";
import React from "react";
import { CreateTemplateModal } from "../../components/WorkoutTemplatesTable/CreateTemplateModal";
import { cloneWorkoutWithoutIds } from "../../libs/workout/utils";
import { useQuery } from "../../libs/utils/hooks";

export default function WorkoutDetails() {
  const {
    data: users,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useGetUsersQuery();

  const query = useQuery();
  const isEditModeFromQS = query.get("edit");

  useEffect(() => {
    if (isEditModeFromQS) {
      toggleEditMode();
    }
  }, []);

  const getUserName = (id: string) => {
    if (isLoadingUsers || errorUsers || !users) return "";
    const user = users?.find((user) => user.id + "" === id);
    return user?.name + " " + user?.surname;
  };
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const currentWrk = useAppSelector(selectWorkoutCurrent);

  const [openModal, setOpenModal] = useState<string | number | undefined>();
  const [openModalTemplate, setOpenModalTemplate] = useState<
    { workout: Workout } | undefined
  >();

  const { data: workout, isLoading, error } = useGetWorkoutQuery(id || "");
  const [deleteWorkout, { isLoading: isDeletePending, error: deleteError }] =
    useDeleteWorkoutMutation();
  const {
    isEditMode,
    toggleEditMode,
    isUpdatePending,
    updateError,
    saveWorkout,
  } = useEditWorkout(workout);

  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !workout) {
    return <div>Workout Error...</div>;
  }

  const onSave = (e: any) => {
    e.preventDefault();
    saveWorkout();
  };
  const onDelete = () => {
    deleteWorkout(workout).then(() => {
      return navigate(ROUTES.WORKOUTS);
    });
  };

  const isTemplate = workout.workoutType === WORKOUT_TYPES.TEMPLATE;

  return (
    <div>
      <>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 max-w-2xl lg:py-16">
            <p className="mb-4 text-xl font-extrabold leading-none text-gray-900 md:text-2xl dark:text-white">
              {isTemplate ? (
                isEditMode ? (
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search"
                    required={false}
                    value={currentWrk?.name}
                    onChange={(e) => {
                      dispatch(editName({ name: e.target.value }));
                    }}
                  />
                ) : (
                  <p className="mb-4 text-sm font-regular leading-none text-gray-900 md:text-sm dark:text-white">
                    {currentWrk?.name}
                  </p>
                )
              ) : (
                getUserName(currentWrk?.userId + "")
              )}
            </p>
            {workout.note &&
              (isEditMode ? (
                <input
                  type="text"
                  id="simple-search"
                  className="block w-full p-2 pl-10 mb-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Search"
                  required={false}
                  value={currentWrk?.note}
                  onChange={(e) => {
                    dispatch(editNote({ note: e.target.value }));
                  }}
                />
              ) : (
                <p className="mb-4 text-sm font-regular leading-none text-gray-900 md:text-sm dark:text-white">
                  {currentWrk?.note}
                </p>
              ))}
            <div className="flex items-center space-x-4">
              {isEditMode && currentWrk ? (
                <>
                  <button onClick={toggleEditMode}>Annulla</button>

                  <form onSubmit={onSave}>
                    <SubmitButton isPending={isUpdatePending}>
                      Salva
                    </SubmitButton>
                  </form>
                  <WeeksCounter
                    current={currentWrk.data?.length || 0}
                    increment={() => dispatch(addWeek())}
                    decrement={() => dispatch(removeWeek())}
                  />
                  <DaysCounter
                    current={
                      (currentWrk.data && currentWrk.data[0]?.days?.length) || 0
                    }
                    increment={() => dispatch(addDay())}
                    decrement={() => dispatch(removeDay())}
                  />
                </>
              ) : (
                <>
                  {/* <AssignToUserDropdown workout={currentWrk} /> */}

                  <EditButton
                    onClick={() => {
                      toggleEditMode();
                    }}
                  />
                  {isTemplate && (
                    <PlusButton
                      onClick={() => setOpenModal(workout.id)}
                      variant={"default"}
                    >
                      Assegna
                    </PlusButton>
                  )}
                  <PlusButton
                    onClick={() =>
                      setOpenModalTemplate({
                        workout:
                          cloneWorkoutWithoutIds(
                            currentWrk || ({} as Workout)
                          ) || ({} as Workout),
                      })
                    }
                    variant={"default"}
                  >
                    Clona workout
                  </PlusButton>
                  <DeleteButton
                    isPending={isDeletePending}
                    onClick={onDelete}
                  />
                  {deleteError && <div>Errore nella cancellazione</div>}
                </>
              )}
              {(deleteError || updateError) && (
                <div>Ops! C'è stato un errore, riprova più tardi...</div>
              )}
            </div>
          </div>
        </section>
        <WorkoutTables
          workout={isEditMode && currentWrk ? currentWrk : workout}
          isLoading={isLoading}
          error={error}
          isEditMode={isEditMode}
        />
      </>
      {currentWrk?.id && (
        <AssignToUserModal
          workoutId={currentWrk.id}
          isModalOpen={!!openModal}
          setOpenModal={setOpenModal}
        />
      )}
      {currentWrk?.id && (
        <CreateTemplateModal
          openModal={openModalTemplate}
          setOpenModal={() => setOpenModalTemplate(undefined)}
        />
      )}
    </div>
  );
}

const DaysCounter: React.FC<{
  current: number;
  increment: () => void;
  decrement: () => void;
}> = ({ current, increment, decrement }) => {
  return (
    <div className="ml-4 flex flex-row items-center gap-2 w-full rounded-lg relative bg-transparent">
      <span className="text-md font-semibold leading-none text-gray-900 md:text-md dark:text-white">
        Days:{" "}
      </span>

      <button
        disabled={current <= 1}
        onClick={decrement}
        type="button"
        className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 2"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h16"
          />
        </svg>
      </button>
      <span className="text-md font-semibold leading-none text-blue-700 dark:text-blue-500">
        {current}
      </span>
      <button
        onClick={increment}
        disabled={current >= 7}
        type="button"
        className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 1v16M1 9h16"
          />
        </svg>
      </button>
    </div>
  );
};
const AssignToUserDropdown: React.FC<{ workout: Workout | null }> = ({
  workout,
}) => {
  const { data: users, isLoading } = useGetUsersQuery();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  return (
    <div className="relative">
      <button
        id="dropdownSearchButton"
        data-dropdown-toggle="dropdownSearch"
        data-dropdown-placement="bottom"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={toggleDropdown}
      >
        Assegna a{" "}
        <svg
          className="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      <div
        id="dropdownSearch"
        className={`${
          isDropdownVisible ? "" : "hidden"
        } z-10 absolute bg-white rounded-lg shadow w-60 dark:bg-gray-700`}
      >
        <div className="p-3">
          <label htmlFor="input-group-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="input-group-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search user"
            />
          </div>
        </div>
        <ul
          className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownSearchButton"
        >
          {users?.map((user) => (
            <li>
              <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <input
                  id="checkbox-item-11"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  htmlFor="checkbox-item-11"
                  className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                >
                  {" "}
                  {user.name} {user.surname}
                </label>
              </div>
            </li>
          ))}
        </ul>
        <SubmitButton isPending={false}>Salva</SubmitButton>
      </div>
    </div>
  );
};
