import {
  UseFieldArrayReturn,
  UseFormRegister,
  FieldErrors,
  useFieldArray,
} from "react-hook-form";
import { DeleteButton, PlusButton } from "../../Button";
import { FormValues } from "./index";

export const SetTableStripping: React.FC<{
  setsFields: UseFieldArrayReturn<FormValues, "sets", "id">;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  isEditMode: boolean;
  control: any;
}> = ({ setsFields, isEditMode, register, errors, control }) => {
  const { fields: sets, append, remove } = setsFields;

  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="text-md  text-center">
          <th scope="col" className="px-6 py-3">
            Set
          </th>
          <th scope="col" className="px-6 py-3 text-center">
            Reps
          </th>
          <th scope="col" className="px-6 py-3">
            Rest
          </th>
        </tr>
      </thead>
      <tbody>
        {sets?.map((set, i) => (
          <>
            <tr key={set.id} className="border-b-2">
              <td className="px-6 py-4">
                {isEditMode ? (
                  <DeleteButton variant="icon" onClick={() => remove(i)} />
                ) : (
                  i + 1
                )}
              </td>
              <td className="px-6 py-4">
                {
                  <tr className="p-5 text-lg font-semibold text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    <td className="px-6 py-3">
                      <NestedReps
                        setIndex={i}
                        register={register}
                        errors={errors}
                        isEditMode={isEditMode}
                        control={control}
                      />
                    </td>
                  </tr>
                }
              </td>
              <td className="px-6 pr-8 py-4 relative min-w-[60px]">
                <input
                  type="number"
                  step={15}
                  max={1000}
                  min={0}
                  disabled={!isEditMode}
                  {...register(`sets.${i}.rest`, {
                    valueAsNumber: true,
                    min: 0,
                    max: 999,
                  })}
                  className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <span className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                  sec
                </span>
              </td>
            </tr>
          </>
        ))}
        {isEditMode && (
          <tr className="p-5 text-lg font-semibold text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <td className="px-6 py-3">
              <PlusButton
                onClick={() =>
                  append({
                    reps: 0,
                    rest: 0,
                  })
                }
                variant="icon"
              />
              <p>{errors.sets?.root?.message}</p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const NestedReps: React.FC<{
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  isEditMode: boolean;
  control: any;
  setIndex: number;
}> = ({ setIndex, register, control, isEditMode, errors }) => {
  const {
    fields: reps,
    append: appendReps,
    remove: removeReps,
  } = useFieldArray({
    control,
    name: `sets.${setIndex}.reps`,
  });
  return (
    <>
      <div className="flex align-middle justify-center space-x-1 my-2">
        {reps?.map((rep, j) => (
          <input
            key={j}
            type="text"
            disabled={!isEditMode}
            {...register(`sets.${setIndex}.reps.${j}`, {
              valueAsNumber: true,
              min: 0,
              max: 999,
            })}
            className="max-w-[40px] text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 inline-block w-auto py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        ))}
      </div>
      {isEditMode && (
        <>
          <PlusButton onClick={() => appendReps(1)} variant="icon" />
          <DeleteButton
            onClick={() => removeReps(reps.length - 1)}
            variant="icon"
          />
          <p>{errors.sets?.root?.message}</p>
        </>
      )}
    </>
  );
};
