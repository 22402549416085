import { getKcal } from "../utils/diet";
import { NutritionItem } from "./types";

export const getTotalKcal = (nutrition: NutritionItem) => {
  const { CARB = "0", PROTEIN = "0", FAT = "0" } = nutrition || {};
  const carbKcal = getKcal(parseInt(CARB), "CARB");
  const proteinKcal = getKcal(parseInt(PROTEIN), "PROTEIN");
  const fatKcal = getKcal(parseInt(FAT), "FAT");

  const totalKcal = carbKcal + proteinKcal + fatKcal;
  return totalKcal > 0 ? totalKcal : null;
};
