import { FEEDBACK_QUESTION_IDS } from "./types";

export const FEEDBACK_QUESTION_LABELS = {
  [FEEDBACK_QUESTION_IDS.MUSCLE_TARGETED]:
    "QUANTO HAI SENTITO I MUSCOLI BERSAGLIO?",
  [FEEDBACK_QUESTION_IDS.MUSCLE_SORENESS]:
    "QUANTO INDOLENZIMENTO MUSCOLARE HAI AVUTO?",
  [FEEDBACK_QUESTION_IDS.PHYSICAL_TIREDNESS]:
    "COME TI SENTI A LIVELLO DI STANCHEZZA FISICA?",
  [FEEDBACK_QUESTION_IDS.EXERCISE_WEIGHT]: "COME SONO STATI I CARICHI?",
  [FEEDBACK_QUESTION_IDS.DIET_QUANTITY]:
    "A LIVELLO ALIMENTARE COME TI SEI SENTITO?",
  [FEEDBACK_QUESTION_IDS.DIET_QUALITY]: "SEI RIUSCITO A SEGUIRE LA DIETA?",
  [FEEDBACK_QUESTION_IDS.SLEEP_QUALITY]: "COME HAI DORMITO?",
} as const;

export const FEEDBACK_QUESTION_OPTIONS = {
  [FEEDBACK_QUESTION_IDS.MUSCLE_TARGETED]: [
    "Molto bruciore",
    "Un po' di bruciore",
    "Bruciore lieve",
  ],
  [FEEDBACK_QUESTION_IDS.MUSCLE_SORENESS]: [
    "Molto indolenzito, difficoltà ad allenarmi",
    "Abbastanza indolenzito, anche nei giorni successivi",
    "Indolenzito ma non troppo",
  ],
  [FEEDBACK_QUESTION_IDS.PHYSICAL_TIREDNESS]: [
    "Devastato dagli allenamenti",
    "Stanco ma non troppo",
    "Poco stanco, pronto a dare il massimo",
  ],
  [FEEDBACK_QUESTION_IDS.EXERCISE_WEIGHT]: [
    "Male, in calo evidente come reps o kg",
    "Normale, stessi kg e reps",
    "Ottimo, sono riuscito ad aumentare i carichi",
  ],
  [FEEDBACK_QUESTION_IDS.DIET_QUANTITY]: [
    "Troppo cibo",
    "Bene, quantità giusta",
    "Sento spesso fame",
  ],
  [FEEDBACK_QUESTION_IDS.DIET_QUALITY]: [
    "Male, ho seguito poco la dieta",
    "Abbastanza, a parte il weekend",
    "Ottimo, tutti i giorni senza sgarri",
  ],
  [FEEDBACK_QUESTION_IDS.SLEEP_QUALITY]: ["< 6 ore", "7-8 ore", "> 8 ore"],
} as const;
