export const BodyMeasureKeys = {
  WAIST: "WAIST",
  HIPS: "HIPS",
  CONTRACTED_ARM: "CONTRACTED_ARM",
  ARM: "ARM",
  FOREARM: "FOREARM",
  THIGH_ROOT: "THIGH_ROOT",
  THIGH_MID: "THIGH_MID",
  SUPRAPATELLAR: "SUPRAPATELLAR",
  CALF: "CALF",
} as const;

export const PlicometryMeasureKeys = {
  P_BICEPS: "P_BICEPS",
  P_TRICEPS: "P_TRICEPS",
  P_CHEST: "P_CHEST",
  P_AXILLARY: "P_AXILLARY",
  P_SUBSCAPULAR: "P_SUBSCAPULAR",
  P_ABDOMINAL: "P_ABDOMINAL",
  P_SUPRAILIAC: "P_SUPRAILIAC",
  P_THIGH: "P_THIGH",
  P_CALF: "P_CALF",
} as const;

export type BodyMeasure = Partial<
  Record<
    keyof typeof BodyMeasureKeys | keyof typeof PlicometryMeasureKeys,
    string | null | undefined
  >
> & {
  historyId?: number | null;
};
export type BodyMeasureImgs = {
  imgs?: string[];
  historyId?: number | null;
};
export type WeightMeasure = {
  weight?: string;
  hasPeriod?: boolean;
  historyId?: number | null;
};

type Measure<T> = Record<string, T>;

export type BodyMeasuresRequest = Measure<BodyMeasure & BodyMeasureImgs>;
export type BodyMeasuresResponse = Measure<BodyMeasure>;
export type BodyMeasuresImgsResponse = Measure<BodyMeasureImgs>;
export type WeightMeasuresRequest = Measure<WeightMeasure>;
export type WeightMeasuresResponse = Measure<WeightMeasure>;
