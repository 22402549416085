import { useForm } from 'react-hook-form';
import { useCreateExerciseMutation, useDeleteExerciseMutation } from '../../libs/exercises/api';
import { Exercise } from '../../libs/exercises/types';
import { SuccessAlert } from '../Alerts';
import { DeleteButton } from '../Button';
import { Modal } from '../Modal';

type DeleteExerciseFormProps = {
  openModal: string | undefined;
  setOpenModal: (value: string | undefined) => void;
  exerciseToDelete?: Exercise;
};

export const DeleteExerciseModal: React.FC<DeleteExerciseFormProps> = ({
  openModal,
  setOpenModal,
  exerciseToDelete,
}) => {
  const [deleteExercise, { data, isLoading, error }] = useDeleteExerciseMutation();

  const onSubmit = () => {
    deleteExercise(exerciseToDelete?.id as string)
      .then(() => {
        setOpenModal(undefined);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      title={'Elimina ' + exerciseToDelete?.name}
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <p role="alert" className="block my-4 text-sm font-medium">
        Sei sicuro di voler eliminare questo esercizio?
      </p>
      <div className="w-full">
        <DeleteButton isPending={isLoading} onClick={() => onSubmit()} />
      </div>
      {error && (
        <p role="alert" className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500">
          OPS! La modifica non è andata a buon fine. Riprova
        </p>
      )}
    </Modal>
  );
};
