import { updateExercise } from "../../../libs/workout/slice";
import { DeleteButton, PlusButton, SubmitButton } from "../../Button";
import { useAppDispatch } from "../../../store";
import {
  FieldErrors,
  FormProvider,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { Exercise, ExerciseSetFromServer } from "../../../libs/workout/types";
import { EXERCISE_TYPES } from "../../../libs/exercises/types";
import React from "react";
import { SetTableStripping } from "./SetTableStripping";
import { SetTable } from "./SetTableDefault";
import { SetTableSuperSet } from "./SetTableSuperSet";

type SetFormProps = {
  weekIndex: number;
  dayIndex: number;
  exerciseIndex: number;
  exercise: Exercise;
  isEditMode: boolean;
  closeModal: () => void;
};
export type FormValues = {
  sets: ExerciseSetFromServer[];
  notes: string;
};

export const SetForm: React.FC<SetFormProps> = ({
  weekIndex,
  isEditMode,
  dayIndex,
  exercise,
  closeModal,
  exerciseIndex,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      sets: exercise?.sets,
      notes: exercise?.notes,
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = methods;

  const setsFields = useFieldArray({
    name: "sets",
    control,
    rules: {
      required: "Inserisci almeno un set",
    },
  });

  const dispatch = useAppDispatch();

  const onSubmit = (data: FormValues) => {
    const updatedExercise = {
      ...exercise,
      ...data,
    } as Exercise;
    dispatch(
      updateExercise({
        weekIndex,
        dayIndex,
        exerciseIndex,
        exercise: updatedExercise,
      })
    );
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form
        className="overflow-y-auto max-h-[500px] space-y-6 p-4 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {exercise.type === EXERCISE_TYPES.DEFAULT && (
          <SetTable
            register={register}
            errors={errors}
            isEditMode={isEditMode}
            setsFields={setsFields}
          />
        )}
        {exercise.type === EXERCISE_TYPES.STRIPPING && (
          <SetTableStripping
            register={register}
            errors={errors}
            isEditMode={isEditMode}
            setsFields={setsFields}
            control={control}
          />
        )}
        {exercise.type === EXERCISE_TYPES.SUPERSET && (
          <SetTableSuperSet
            register={register}
            errors={errors}
            isEditMode={isEditMode}
            setsFields={setsFields}
            control={control}
            subExercises={exercise.subExercises}
            getValues={getValues}
          />
        )}
        <NotesForm
          register={register}
          errors={errors}
          isEditMode={isEditMode}
        />

        {isEditMode && (
          <div className="w-full">
            <SubmitButton isPending={false}>Salva</SubmitButton>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

const NotesForm: React.FC<{
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  isEditMode: boolean;
}> = ({ register, errors, isEditMode }) => {
  return (
    <>
      <div>
        <label
          htmlFor="text"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Note
        </label>
        <textarea
          {...register("notes", {})}
          disabled={!isEditMode}
          aria-invalid={errors.notes ? "true" : "false"}
          id="notes"
          rows={4}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      {errors.notes && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.notes?.message?.toString()}
        </p>
      )}
    </>
  );
};
