import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useUpdateDietMutation, useSetDietMutation } from "./api";
import { init, selectDietCurrent, selectDietOriginal } from "./slice";
import {
  Diet,
  DietTableMacrosTypes,
  DietTableMacros,
  DietFromApiDay,
} from "./types";
import { removeDuplicates } from "./utils";
import { useNavigate } from "react-router-dom";

export const initialDays = (
  userId: number,
  dietId?: number
): DietFromApiDay[] => {
  const days = Object.keys(DietTableMacrosTypes).map((dayTypeKey) => {
    const dayParams = Object.keys(DietTableMacros).map((dayMacroKey) => ({
      key: dayMacroKey as keyof typeof DietTableMacros | "integrations",
      value: "0" as any,
      id: null,
      idDietDay: null,
      idUser: userId,
    }));
    if (dayTypeKey === DietTableMacrosTypes.ON) {
      dayParams.push({
        key: "integrations",
        value: [],
        id: null,
        idDietDay: null,
        idUser: userId,
      });
    }
    return {
      id: null,
      idDiet: dietId || null,
      idUser: userId,
      goal: 0,
      dayType: dayTypeKey as keyof typeof DietTableMacrosTypes,
      weekNumber: 1,
      dietParameters: dayParams,
    };
  });

  return days;
};
const getDefaultDiet = (userId: number, dietId?: string): Diet => ({
  id: null,
  weeksAmount: 1,
  dietsData: {
    days: initialDays(userId, dietId ? parseInt(dietId) : undefined),
  },
  notes: "",
  goals: "0",
  userId: userId,
  daysOnAmount: 7,
  daysOffAmount: 0,
  daysRefeedAmount: 0,
});

type UseEditDietOpt = {
  setDefault?: boolean;
  userId?: number;
};

export const useEditDiet = (diet?: Diet, opt?: UseEditDietOpt) => {
  const dispatch = useAppDispatch();
  const originalWrk = useAppSelector(selectDietOriginal);
  const currentWrk = useAppSelector(selectDietCurrent);
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updateDiet, { error: errorUpdate, isLoading: isLoadingUpdate }] =
    useUpdateDietMutation();
  const [setDiet, { error: errorSet, isLoading: isLoadingSet }] =
    useSetDietMutation();

  useEffect(() => {
    if (!diet && opt?.setDefault) {
      dispatch(init({ diet: getDefaultDiet(opt.userId || -1) }));
      return;
    }
    if (!diet) {
      return;
    }
    if (diet.dietsData.days.length < 3) {
      dispatch(
        init({
          diet: {
            ...getDefaultDiet(opt?.userId || -1, diet.id + ""),
            id: diet.id,
          },
        })
      );
      return;
    }
    dispatch(
      init({
        diet: {
          ...diet,
          dietsData: {
            days: removeDuplicates(diet.dietsData.days),
          },
        },
      })
    );
  }, [diet, opt?.setDefault]);

  const saveDiet = () => {
    if (!currentWrk) {
      return;
    }
    if (opt?.setDefault) {
      setDiet(currentWrk).then(() => {
        setIsEditMode(false);
        navigate(0);
      });
      return;
    }
    updateDiet(currentWrk).then(() => {
      setIsEditMode(false);
    });
  };

  return {
    isEditMode: isEditMode,
    toggleEditMode: () => setIsEditMode(!isEditMode),
    saveDiet: saveDiet,
    isUpdatePending: isLoadingSet || isLoadingUpdate,
    updateError: errorSet || errorUpdate,
  };
};
