import { useEffect, useState } from "react";

export const Skeleton = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  if (!show) {
    return null;
  }
  return (
    <div>
      <div className="animate-load flex space-x-8">
        <div className="flex-1 space-y-4 py-1 px-4">
          <div className="h-20 bg-gray-200 rounded full-w"></div>
          <div className="space-y-4 justify-center">
            <div className="h-10 bg-gray-100 rounded"></div>
            <div className="h-10 bg-gray-100 rounded w-5/6"></div>
            <div className="h-10 bg-gray-100 rounded w-5/6"></div>
            <div className="h-10 bg-gray-100 rounded w-5/6"></div>
            <div className="h-10 bg-gray-100 rounded w-5/6"></div>
            <div className="h-10 bg-gray-100 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
