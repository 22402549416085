import { Pagination as FlowbitePagination } from "flowbite-react";
import { useState } from "react";

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (p: number) => void;
};

const active =
  "flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white";
const nonActive =
  "flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white";
export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const getPagesToShow = () => {
    if (totalPages <= 5) return Array.from(Array(totalPages).keys());
    if (currentPage <= 3) return [0, 1, 2, 3, 4, totalPages - 1];
    if (currentPage >= totalPages - 2)
      return [
        0,
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
      ];
    return [
      0,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      totalPages - 1,
    ];
  };

  const pagesToShow = getPagesToShow();

  return (
    <nav className="flex justify-center w-full my-4">
      <ul className="inline-flex -space-x-px text-sm">
        <li>
          <button
            type="button"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Previous
          </button>
        </li>
        {pagesToShow.map((key, i) => (
          <li key={key + ""}>
            <button
              type="button"
              onClick={() => setCurrentPage(key + 1)}
              className={currentPage === key + 1 ? active : nonActive}
            >
              {key + 1}
            </button>
          </li>
        ))}
        <li>
          <button
            type="button"
            disabled={currentPage >= totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};
