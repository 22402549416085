import { createSelector } from "@reduxjs/toolkit";
import { endpoints as historyEndpoints } from "../history/api";
import {
  selectBirthDate,
  selectGender,
  selectLeanBodyMass,
  selectWeight,
} from "../user/selectors";
import { getAge } from "../utils/date";
import { calculateLeanBodyMass } from "./utils";
import { PlicometryMeasureKeys } from "./types";

export const selectGetHistoryResult = createSelector(
  [(state) => state, (_, userId) => userId],
  (state, userId) =>
    historyEndpoints.getHistory.select(parseInt(userId))(state)?.data ?? null
);

export const selectLastWeight = createSelector(
  [selectGetHistoryResult],
  (history) => {
    if (!history || !history.weightHistoryList) {
      return null;
    }

    const lastHistoryEntryWeightDate = Object.keys(
      history.weightHistoryList
    ).reverse()[0];
    const lastHistoryEntryWithWeight =
      history.weightHistoryList[lastHistoryEntryWeightDate];

    if (!lastHistoryEntryWithWeight) {
      return null;
    }

    return parseFloat(lastHistoryEntryWithWeight.weight || "");
  }
);

export const selectLastLeanBodyMass = createSelector(
  [selectGetHistoryResult, selectBirthDate, selectGender],
  (history, birthDate, gender) => {
    if (!history || !birthDate) {
      return null;
    }

    const lastHistoryEntryWithPlicometry = Object.keys(
      history.measureHistoryList
    )
      ?.reverse()
      .find((entry) => {
        return Object.keys(history.measureHistoryList[entry]).some((key) =>
          key.startsWith("P_")
        );
      });

    if (!lastHistoryEntryWithPlicometry) {
      return null;
    }

    const historyWeight =
      history.weightHistoryList[lastHistoryEntryWithPlicometry]?.weight;
    const historyWeightParsed = parseFloat(historyWeight || "");

    if (!historyWeight) {
      return null;
    }

    const birthDateDate = new Date(birthDate);

    const age = getAge(birthDateDate);

    const plicometry = Object.keys(
      history.measureHistoryList[lastHistoryEntryWithPlicometry]
    ).reduce((acc, key) => {
      // @ts-ignore
      if (key.startsWith("P_")) {
        acc[key as keyof typeof PlicometryMeasureKeys] = parseFloat(
          // @ts-ignore
          history.measureHistoryList[lastHistoryEntryWithPlicometry][key]
        );
      }
      return acc;
    }, {} as Record<keyof typeof PlicometryMeasureKeys, number>);

    const leanBodyMassCalc = calculateLeanBodyMass(
      historyWeightParsed,
      plicometry,
      age,
      gender === "F"
    );
    return leanBodyMassCalc;
  }
);
