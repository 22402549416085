export const DietTableMacros = {
  CARB: "CARB",
  PROTEIN: "PROTEIN",
  FAT: "FAT",
} as const;

const DietTableIntegrations = {
  creatine: "creatine",
  vitaminB: "vitaminB",
  vitaminC: "vitaminC",
  vitaminD: "vitaminD",
  amminoacids: "amminoacids",
} as const;

export const DietTableMacrosTypes = {
  ON: "ON",
  OFF: "OFF",
  REFEED: "REFEED",
} as const;

export const Integrations = "integrations" as const;

export const PlicometricsKeys = {
  BICEPS: "BICEPS",
  TRICEPS: "TRICEPS",
  CHEST: "CHEST",
  AXILLARY: "AXILLARY",
  SUBSCAPULAR: "SUBSCAPULAR",
  ABDOMINAL: "ABDOMINAL",
  SUPRAILIAC: "SUPRAILIAC",
  THIGH: "THIGH",
  CALF: "CALF",
};

export type DietData = {
  plicometrics?: Record<keyof typeof PlicometricsKeys, number | null>;
  days: DietFromApiDay[];
};
export type Diet = Omit<DietFromApi, "dietsData"> & {
  dietsData: DietData;
};

export type DietWeek = {
  id: string;
  name: string;
  tables: DietTables;
  goal: number;
};

export type DietTables = {
  [key in DietTableKeys]?: DietTableMacrosItem;
};

export type DietTableKeys = keyof typeof DietTableMacrosTypes;
export type DietTableMacrosKeys = keyof typeof DietTableMacros;
export type DietTableIntegrationsKeys = keyof typeof DietTableIntegrations;

export type DietTableMacrosItem = {
  [key in DietTableMacrosKeys]?: string;
};

export type DietTableIntegrationsItem = {
  key: string;
  value: string;
};

// API RESPONSES

type DietFromApi = {
  id: number | null;
  userId: number;
  description?: string;
  notes?: string | null;
  goals?: string | null;
  weeksAmount: number;
  daysOnAmount: number;
  daysOffAmount: number;
  daysRefeedAmount: number;
  dietsData: string;
};

export type DietFromApiDay = {
  id: number | null;
  idDiet: number | null;
  idUser: number;
  goal: number;
  dayType: keyof typeof DietTableMacrosTypes;
  weekNumber: number;
  dietParameters: {
    id: number | null;
    idUser: number | null;
    idDietDay: number | null;
    key: keyof typeof DietTableMacros | "integrations";
    value:
      | string
      | number
      | null
      | {
          key: string;
          value: string;
        }[];
  }[];
};

export type DietResponse = DietFromApi;

export type DietRequestPOST = Omit<DietFromApi, "id">;
export type DietRequestPUT = DietFromApi;
