import {
  HistoryResponse,
  HistoryItem,
  HISTORY_TYPES,
  HistoryRequest,
} from "../history/types";
import { convertDateToTimestamp, convertTimestampToDate } from "../utils/date";
import {
  BodyMeasure,
  BodyMeasureImgs,
  BodyMeasureKeys,
  BodyMeasuresImgsResponse,
  BodyMeasuresRequest,
  BodyMeasuresResponse,
  PlicometryMeasureKeys,
  WeightMeasure,
  WeightMeasuresRequest,
  WeightMeasuresResponse,
} from "./types";

export function transformHistoryMeasureBody(
  response: HistoryResponse
): BodyMeasuresResponse {
  return (
    response
      .filter(
        (item: HistoryItem) => item.historyType === HISTORY_TYPES.MEASUREMENT
      )
      .sort((a, b) => b.date - a.date)
      .reduce((acc: BodyMeasuresResponse, item: HistoryItem, i) => {
        const bodyMeasureItem = Object.keys({
          ...BodyMeasureKeys,
          ...PlicometryMeasureKeys,
        }).reduce((acc, key) => {
          const parameter = item.historyParameters?.find(
            (param) => param.key === key
          );
          if (parameter) {
            acc[
              key as
                | keyof typeof BodyMeasureKeys
                | keyof typeof PlicometryMeasureKeys
            ] = parameter.value;
          }
          return acc;
        }, {} as BodyMeasure);
        if (Object.keys(bodyMeasureItem).length === 0) {
          return acc;
        }
        bodyMeasureItem.historyId = item.id;
        acc[convertTimestampToDate(item.date)] = bodyMeasureItem;
        return acc;
      }, {} as BodyMeasuresResponse) || {}
  );
}

export function transformHistoryMeasureBodyImgs(
  response: HistoryResponse
): BodyMeasuresResponse {
  return (
    response
      .filter(
        (item: HistoryItem) => item.historyType === HISTORY_TYPES.MEASUREMENT
      )
      .sort((a, b) => b.date - a.date)
      .reduce((acc: BodyMeasuresResponse, item: HistoryItem, i) => {
        const bodyMeasureItem = {} as BodyMeasureImgs;
        bodyMeasureItem.imgs = item.historyImages?.map((img) => img?.fileName);
        bodyMeasureItem.historyId = item.id;
        acc[convertTimestampToDate(item.date)] = bodyMeasureItem;
        return acc;
      }, {} as BodyMeasuresImgsResponse) || {}
  );
}

export function transformHistoryMeasureWeight(
  response: HistoryResponse
): WeightMeasuresResponse {
  return (
    response
      .filter((item: HistoryItem) => item.historyType === HISTORY_TYPES.WEIGHT)
      .sort((a, b) => b.date - a.date)
      .reduce((acc: WeightMeasuresResponse, item: HistoryItem, i) => {
        const bodyMeasureItem =
          item.historyParameters && item.historyParameters[0]?.value;
        let weightMeasureItemParser = null as WeightMeasure | null;
        try {
          weightMeasureItemParser =
            bodyMeasureItem && JSON.parse(bodyMeasureItem);
          if (
            weightMeasureItemParser &&
            weightMeasureItemParser.weight &&
            typeof weightMeasureItemParser.weight === "string"
          ) {
            // @ts-ignore
            weightMeasureItemParser.weight = parseFloat(
              weightMeasureItemParser.weight
            );
          }
        } catch (e) {
          console.warn("TRANSFORM HISTORY ERROR: ", e);
        }
        if (!weightMeasureItemParser) {
          return acc;
        }
        weightMeasureItemParser.historyId = item.id;
        acc[convertTimestampToDate(item.date)] = weightMeasureItemParser;
        return acc;
      }, {} as WeightMeasuresResponse) || {}
  );
}

export function transformHistoryMeasureBodyRequest(
  bodyMeasures: BodyMeasuresRequest,
  idUser: number
): HistoryRequest {
  const dateToConvert = Object.keys(bodyMeasures)[0];
  const hasHistoryParameters =
    Object.keys(BodyMeasureKeys).some(
      (key) => bodyMeasures[dateToConvert][key as keyof typeof BodyMeasureKeys]
    ) ||
    Object.keys(PlicometryMeasureKeys).every(
      (key) =>
        bodyMeasures[dateToConvert][key as keyof typeof PlicometryMeasureKeys]
    );
  const measureKeys = Object.keys(BodyMeasureKeys).concat(
    Object.keys(PlicometryMeasureKeys)
  );
  return {
    date: convertDateToTimestamp(dateToConvert),
    historyType: HISTORY_TYPES.MEASUREMENT,
    historyParameters: hasHistoryParameters
      ? measureKeys.map((key) => ({
          id: null,
          idHistory: null,
          idUser: idUser,
          key,
          value: bodyMeasures[dateToConvert][
            key as keyof typeof BodyMeasureKeys
          ]
            ? bodyMeasures[dateToConvert][key as keyof typeof BodyMeasureKeys] +
              ""
            : "",
        }))
      : [],
    historyImages: bodyMeasures[dateToConvert].imgs?.map((img, i) => ({
      id: null,
      idHistory: null,
      idUser: idUser,
      image: img.split(",")[1],
      fileName: convertDateToTimestamp(dateToConvert) + "_" + i + "_" + idUser,
    })),
    idUser,
    id: null,
  };
}

export function transformHistoryMeasureWeightRequest(
  weightMeasures: WeightMeasuresRequest,
  idUser: number
): HistoryRequest {
  const dateToConvert = Object.keys(weightMeasures)[0];
  return {
    id: null,
    idUser,
    date: convertDateToTimestamp(dateToConvert),
    historyType: HISTORY_TYPES.WEIGHT,
    historyParameters: [
      {
        id: null,
        idHistory: null,
        idUser: idUser,
        key: "weight",
        value: JSON.stringify(weightMeasures[dateToConvert]),
      },
    ],
  };
}
