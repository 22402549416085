import { NoteButton, WhatsAppButton } from "../Button";

import { useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { UserProfileForm } from "./UserProfileForm/UserProfileForm";
import { selectGetUserResult } from "../../libs/user/selectors";

import { getIsNoteNew } from "../../libs/user/utils";
import { useQuery } from "../../libs/utils/hooks";
import { NoteModal } from "./NoteModal";
import { useState } from "react";
import { selectPTHasNewMessages } from "../../libs/user/slice";

export const UserInfoSection: React.FC = () => {
  const { id: userId } = useParams();

  const user = useAppSelector((state) => selectGetUserResult(state, userId));

  const query = useQuery();
  const isShowNoteFromQS = query.get("showNote");
  const [openNoteModal, setNoteOpenModal] = useState<boolean>(
    !!isShowNoteFromQS
  );

  const isUserNoteNew = useAppSelector(selectPTHasNewMessages);

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 lg:py-16">
        <div className="mb-4 flex flex-row items-center gap-x-4">
          <p className="inline-flex text-xl font-extrabold leading-none text-gray-900 md:text-2xl dark:text-white">
            {user?.name} {user?.surname}
          </p>
          <div className="flex flex-col items-center space-x-">
            <span className="flex text-[0.7rem] font-bold text-gray-500 dark:text-gray-400">
              Token di invito:{" "}
            </span>
            {user?.token && user?.phoneNumber && (
              <WhatsAppButton
                tel={user.phoneNumber}
                message={"Iscriviti ed inserisci questo codice: " + user.token}
                bgColor={"transparent"}
              />
            )}
          </div>
          <div className="flex flex-col items-center space-x-">
            <NoteButton
              onClick={() => setNoteOpenModal(true)}
              isActive={isUserNoteNew}
            />
          </div>
        </div>
        <UserProfileForm />
      </div>
      <NoteModal
        isModalOpen={!!openNoteModal}
        setOpenModal={setNoteOpenModal}
      />
    </section>
  );
};
