import { Modal } from "flowbite-react";
import { addSet, removeSet } from "../../libs/workout/slice";
import { ExerciseSetFromServer, Workout } from "../../libs/workout/types";
import { useAppDispatch } from "../../store";
import { DeleteButton, PlusButton } from "../Button";
import { SetForm } from "./SetForm";

type SetModalProps = {
  openModal:
    | { dayIndex: number; exerciseIndex: number; weekIndex: number }
    | undefined;
  setOpenModal: any;
  isEditMode: boolean;
  workout: Workout;
};
export const SetModal: React.FC<SetModalProps> = ({
  openModal,
  setOpenModal,
  isEditMode,
  workout,
}) => {
  const dayIndex = openModal?.dayIndex;
  const weekIndex = openModal?.weekIndex;
  const exerciseIndex = openModal?.exerciseIndex;
  if (
    !openModal ||
    dayIndex === undefined ||
    weekIndex === undefined ||
    exerciseIndex === undefined
  ) {
    return null;
  }
  const day = workout.data && workout.data[weekIndex]?.days[dayIndex];
  const exercise = day?.exercises[exerciseIndex];

  if (!openModal || !day || !exercise) {
    return null;
  }

  return (
    <Modal popup show={!!openModal} onClose={() => setOpenModal(undefined)}>
      <Modal.Header>
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Day {dayIndex + 1} - Week {weekIndex + 1} -{" "}
          {exercise?.exercise?.name ?? ""}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <SetForm
          dayIndex={dayIndex}
          weekIndex={weekIndex}
          exerciseIndex={exerciseIndex}
          isEditMode={isEditMode}
          exercise={exercise.exercise}
          closeModal={() => setOpenModal(undefined)}
        />
      </Modal.Body>
    </Modal>
  );
};
