import { MEASURES_LABELS } from "../../libs/measures/constants";
import {
  BodyMeasuresResponse,
  PlicometryMeasureKeys,
  WeightMeasure,
  WeightMeasuresResponse,
} from "../../libs/measures/types";
import {
  calculateLeanAndFatBodyMass,
  calculateLeanBodyMass,
} from "../../libs/measures/utils";
import { getAge } from "../../libs/utils/date";

const RowWrapper: React.FC<{
  children: React.ReactNode;
  index: number;
}> = ({ children, index }) => {
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";

  return <tr className={`${rowBgColor} dark:border-gray-700`}>{children}</tr>;
};

type RowProps = {
  dates: Set<string>;
  index: number;
};

export const MeasureRow: React.FC<
  RowProps & {
    historyList: BodyMeasuresResponse | WeightMeasuresResponse;
    name: keyof typeof MEASURES_LABELS;
  }
> = ({ dates, index, name, historyList }) => {
  if (!historyList) return null;
  return (
    <>
      <RowWrapper index={index}>
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {MEASURES_LABELS[name]}
        </th>

        {Array.from(dates).map((date, i) => {
          // @ts-ignore
          const item = historyList[date] && historyList[date][name];

          if (!item) {
            return (
              <td key={i} className="px-6 py-4 relative">
                <div className="flex justify-center items-center">
                  <div className="text-xs font-bold">-</div>
                </div>
              </td>
            );
          }
          return (
            <td key={i} className="px-6 py-4 relative">
              {item}{" "}
              {name !== "weight" ? (name.startsWith("P_") ? "MM" : "CM") : "KG"}
            </td>
          );
        })}
      </RowWrapper>
    </>
  );
};

export const MeasureCalculatedLeanBodyMassRow: React.FC<
  RowProps & {
    measureHistoryList: BodyMeasuresResponse;
    weightHistoryList: WeightMeasuresResponse;
  }
> = ({ dates, index, measureHistoryList, weightHistoryList }) => {
  if (!measureHistoryList || !weightHistoryList) return null;

  return (
    <>
      <RowWrapper index={index}>
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          MASSA MAGRA / MASSA GRASSA
          <br />
          (calcolata)
        </th>

        {Array.from(dates).map((date, i) => {
          const measureDateList = measureHistoryList[date] || {};
          const weightDateList =
            (weightHistoryList[date] as WeightMeasure) || ({} as WeightMeasure);
          const plicometry = Object.keys(measureDateList).reduce((acc, key) => {
            // @ts-ignore
            if (key.startsWith("P_") && measureDateList[key]) {
              acc[key as keyof typeof PlicometryMeasureKeys] =
                // @ts-ignore
                parseFloat(measureDateList[key]);
            }
            return acc;
          }, {} as Record<keyof typeof PlicometryMeasureKeys, number>);
          const weight = parseFloat(weightDateList.weight || "0");

          const leanAndFat = plicometry
            ? calculateLeanAndFatBodyMass(weight, plicometry, 31, false)
            : null;

          if (!leanAndFat || !leanAndFat.leanBodyMass || !leanAndFat.fatMass) {
            return (
              <td key={i + date} className="px-6 py-4 relative">
                <div className="flex justify-center items-center">
                  <div className="text-xs font-bold">-</div>
                </div>
              </td>
            );
          }
          return (
            <>
              <td key={i} className="px-6 py-4 relative">
                {leanAndFat.leanBodyMass.toFixed(2)} KG /{" "}
                {leanAndFat.fatMass.toFixed(2)} KG
              </td>
            </>
          );
        })}
      </RowWrapper>
    </>
  );
};
