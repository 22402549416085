export type NutritionItem = {
  CARB?: string;
  FAT?: string;
  PROTEIN?: string;
  historyId?: number;
};
export const NutritionKeys = ["CARB", "FAT", "PROTEIN"] as const;

type Nutrition<T> = Record<string, T>;

export type NutritionRequest = Nutrition<NutritionItem>;
export type NutritionResponse = Nutrition<NutritionItem>;
