import { api } from "../api";
import {
  AllWorkoutResponse,
  GenericWorkout,
  Workout,
  WorkoutPreview,
  WorkoutTemplatePreview,
  WorkoutsResponse,
  WorkoutsWeek,
} from "./types";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkout: builder.query<Workout, string>({
      query: (workoutId) => ({
        url: "/workout/" + workoutId,
        method: "GET",
      }),
      transformResponse: transformWorkoutResponse,
      providesTags: ["Workout"],
    }),
    updateWorkout: builder.mutation<WorkoutsResponse, Workout>({
      query: (workout) => ({
        url: "/workout/",
        method: "PUT",
        body: transformWorkoutRequest(workout),
      }),
      invalidatesTags: ["Workout"],
    }),
    deleteWorkout: builder.mutation<WorkoutsResponse, Workout>({
      query: (workout) => ({
        url: "/workout/" + workout.id,
        method: "DELETE",
      }),
      invalidatesTags: ["Workouts"],
    }),
    createWorkout: builder.mutation<WorkoutsResponse, Partial<Workout>>({
      query: (workout) => ({
        url: "/workout/",
        method: "POST",
        body: transformWorkoutRequest(workout),
      }),
      invalidatesTags: ["Workouts"],
    }),
    getAllWorkout: builder.query<WorkoutPreviewResponse, void>({
      query: () => ({
        url: "/workout",
        method: "GET",
      }),
      transformResponse: (response: AllWorkoutResponse) =>
        transformAllWorkoutResponse(response),
      providesTags: ["Workouts"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWorkoutQuery,
  useGetAllWorkoutQuery,
  useCreateWorkoutMutation,
  useDeleteWorkoutMutation,
  useUpdateWorkoutMutation,
} = extendedApi;

function transformAllWorkoutResponse(response: AllWorkoutResponse) {
  return {
    workouts: response.filter((workout) => workout.workoutType === "DEFAULT"),
    templates: response.filter((workout) => workout.workoutType === "TEMPLATE"),
  } as WorkoutPreviewResponse;
}

type WorkoutPreviewResponse = {
  workouts: WorkoutPreview[];
  templates: WorkoutTemplatePreview[];
};

function transformWorkoutResponse(workout: WorkoutsResponse): Workout {
  let data = [] as WorkoutsWeek[];
  try {
    data = JSON.parse(workout.data) as WorkoutsWeek[];
    //if data is not an array, force it to be an array
    if (!Array.isArray(data)) {
      data = [];
    }
  } catch (error) {
    console.error(error);
  }
  return {
    ...workout,
    data: data || [],
  };
}

function transformWorkoutRequest(workout: Workout | Partial<Workout>) {
  let data = "";
  try {
    data = JSON.stringify(workout.data);
  } catch (error) {
    console.error(error);
  }
  return {
    ...workout,
    data: data,
    id: workout.id || null,
    weeks: [],
    // weeks:
    //   workout.weeks?.map((week) => ({
    //     ...week,
    //     id: week.id || null,
    //     days:
    //       week.days?.map((day) => ({
    //         ...day,
    //         exercises:
    //           day.exercises?.map((exercise) => ({
    //             ...exercise,
    //             id: exercise.id || null,
    //           })) || [],
    //         id: day.id || null,
    //       })) || [],
    //   })) || [],
  };
}
