import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Exercise, ExerciseSetFromServer, Workout } from "./types";
import { stat } from "fs";
import { WritableDraft } from "immer/dist/internal";
import { getClonedRepsFromExercise } from "./utils";

// Define the initial state using that type

type State = {
  original: Workout | null;
  current: Workout | null;
};
const initialState: State = {
  original: null,
  current: null,
};

export const workoutSlice = createSlice({
  name: "workout",
  initialState,
  reducers: {
    init(state, action: PayloadAction<{ wrk: Workout }>) {
      state.original = action.payload.wrk;
      state.current = action.payload.wrk;
    },
    reset(state) {
      state = initialState;
    },
    addWeek(state) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][addWeek] Current workout is null");
        return;
      }
      const lastWeek = state.current.data[state.current.data.length - 1];
      if (!lastWeek) {
        state.current.data[0] = {
          id: null,
          weekNumber: 1,
          idWorkout: state.current.id,
          days: [{ id: null, exercises: [], dayNumber: 1, idWeek: null }],
        };
        return;
      }
      lastWeek.weekNumber = lastWeek.weekNumber + 1;
      lastWeek.feedbackForm = null;
      state.current.data.push(lastWeek);
    },
    removeWeek(state) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][removeWeek] Current workout is null");
        return;
      }
      state.current.data.pop();
    },
    addDay(state) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][addDay] Current workout is null");
        return;
      }
      state.current.data.forEach((data, i) => {
        const lastDay = { ...data?.days[data?.days.length - 1] };
        if (state.current && state.current.data) {
          lastDay.id = null;
          state.current.data[i].days.push(lastDay);
        }
      });
    },
    removeDay(state) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][removeDay] Current workout is null");
        return;
      }
      state.current.data.forEach((week, i) => {
        if (
          state.current &&
          state.current.data &&
          state.current.data[i].days.length > 1
        ) {
          state.current.data[i].days.pop();
        }
      });
    },
    addExercise(
      state,
      action: PayloadAction<{
        dayIndex: number;
        exercise: Exercise;
        exToCloneIndex?: number;
        removeExToClone?: boolean;
      }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][addExercise] Current workout is null");
        return;
      }

      const { dayIndex, exercise, exToCloneIndex, removeExToClone } =
        action.payload;
      state.current.data.forEach((week, i) => {
        if (state.current && dayIndex !== -1 && state.current.data) {
          const exToClone =
            exToCloneIndex !== undefined
              ? state.current.data[i].days[dayIndex].exercises[exToCloneIndex]
                  .exercise
              : null;

          if (exToCloneIndex !== undefined && exToClone) {
            const moveValue = removeExToClone ? 0 : 1;
            const removeValue = removeExToClone ? 1 : 0;
            state.current.data[i].days[dayIndex].exercises.splice(
              exToCloneIndex + moveValue,
              removeValue,
              {
                exercise: {
                  ...exercise,
                  sets: exToClone.sets.map((set) => ({
                    reps: getClonedRepsFromExercise(exercise, set, "reps"),
                    repsRange: getClonedRepsFromExercise(
                      exercise,
                      set,
                      "repsRange"
                    ),
                    rest: set.rest,
                    weight: undefined,
                    completed: false,
                  })),
                },
              }
            );
          } else {
            state.current.data[i].days[dayIndex].exercises.push({
              exercise: {
                ...exercise,
                sets: [],
              },
            });
          }
        }
      });
    },
    removeExercise(
      state,
      action: PayloadAction<{ dayIndex: number; exerciseIndex: number }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][addExercise] Current workout is null");
        return;
      }

      const { dayIndex, exerciseIndex } = action.payload;
      state.current.data.forEach((week, i) => {
        if (state.current && state.current.data && dayIndex !== -1) {
          if (exerciseIndex !== -1) {
            state.current.data[i].days[dayIndex].exercises.splice(
              exerciseIndex,
              1
            );
          }
        }
      });
    },
    updateExercise(
      state,
      action: PayloadAction<{
        weekIndex: number;
        dayIndex: number;
        exerciseIndex: number;
        exercise: Exercise;
      }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][updateExercise] Current workout is null");
        return;
      }

      const { weekIndex, dayIndex, exerciseIndex, exercise } = action.payload;
      const week = state.current.data[weekIndex];
      if (state.current && dayIndex !== -1) {
        if (exerciseIndex !== -1) {
          state.current.data[weekIndex].days[dayIndex].exercises[
            exerciseIndex
          ] = {
            exercise: exercise,
          };
        }
      }
    },
    moveExercise(
      state,
      action: PayloadAction<{
        dayIndex: number;
        exerciseIndex: number;
        toExerciseIndex: number | null;
        toDayIndex: number;
      }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][moveExercise] Current workout is null");
        return;
      }

      const { dayIndex, exerciseIndex, toExerciseIndex, toDayIndex } =
        action.payload;
      state.current.data.forEach((week, i) => {
        if (
          state.current &&
          state.current.data &&
          dayIndex !== -1 &&
          exerciseIndex !== -1
        ) {
          // splice remove the element from the array and return it
          const exToMove = state.current.data[i].days[
            dayIndex
          ].exercises.splice(exerciseIndex, 1);

          if (toExerciseIndex !== null) {
            state.current.data[i].days[toDayIndex].exercises.splice(
              toExerciseIndex,
              0,
              exToMove[0]
            );
          } else {
            state.current.data[i].days[toDayIndex].exercises.push(exToMove[0]);
          }
        }
      });
    },

    addSet(
      state,
      action: PayloadAction<{
        weekIndex: number;
        dayIndex: number;
        exerciseIndex: number;
      }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][addSet] Current workout is null");
        return;
      }

      const { weekIndex, dayIndex, exerciseIndex } = action.payload;
      if (dayIndex !== -1) {
        if (exerciseIndex !== -1) {
          state.current.data[weekIndex].days[dayIndex].exercises[
            exerciseIndex
          ].exercise.sets.push({
            reps: 0,
            rest: 0,
          });
        }
      }
    },
    cloneLastSet(
      state,
      action: PayloadAction<{
        weekIndex: number;
        dayIndex: number;
        exerciseIndex: number;
      }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][cloneLastSet] Current workout is null");
        return;
      }

      const { weekIndex, dayIndex, exerciseIndex } = action.payload;
      if (dayIndex !== -1) {
        if (exerciseIndex !== -1 && weekIndex > 0) {
          const lastExercise = {
            ...state.current.data[weekIndex - 1].days[dayIndex].exercises[
              exerciseIndex
            ].exercise,
          };
          state.current.data[weekIndex].days[dayIndex].exercises[
            exerciseIndex
          ] = {
            exercise: lastExercise,
          };
        }
      }
    },
    removeSet(
      state,
      action: PayloadAction<{
        weekIndex: number;
        dayIndex: number;
        exerciseIndex: number;
        setIndex: number;
      }>
    ) {
      if (!state.current || !state.current.data) {
        console.error("[WORKOUT][removeSet] Current workout is null");
        return;
      }

      const { weekIndex, dayIndex, exerciseIndex, setIndex } = action.payload;
      const week = state.current.data[weekIndex];
      if (dayIndex !== -1) {
        if (exerciseIndex !== -1) {
          state.current.data[weekIndex].days[dayIndex].exercises[
            exerciseIndex
          ].exercise.sets.splice(setIndex, 1);
        }
      }
    },
    editNote(
      state,
      action: PayloadAction<{
        note: string;
      }>
    ) {
      if (!state.current) {
        console.error("[WORKOUT][editNote] Current workout is null");
        return;
      }

      const { note } = action.payload;
      state.current.note = note;
    },
    editName(
      state,
      action: PayloadAction<{
        name: string;
      }>
    ) {
      if (!state.current) {
        console.error("[WORKOUT][editName] Current workout is null");
        return;
      }

      const { name } = action.payload;
      state.current.name = name;
    },
  },
});

const { actions } = workoutSlice;

export const {
  init,
  reset,
  addWeek,
  removeWeek,
  addDay,
  removeDay,
  addExercise,
  moveExercise,
  removeExercise,
  updateExercise,
  addSet,
  removeSet,
  cloneLastSet,
  editNote,
  editName,
} = actions;

export const selectWorkoutOriginal = (state: RootState) =>
  state.workout.original;
export const selectWorkoutCurrent = (state: RootState) => state.workout.current;
