import { Link, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./App";
import { NavBar } from "./components/NavBar";

export const Layout = () => {
  let auth = useAuth();

  // redirect to login if not logged in
  let navigate = useNavigate();
  if (false) {
    // if (!auth.user) {
    navigate("/login");
  }
  return (
    <div className="bg-white dark:bg-gray-900 dark:text-white h-full overflow-auto">
      <NavBar
        isLoggedIn={auth.user?.jwt ? true : false}
        logout={() => {
          auth.signout();
        }}
      />
      <div className="container mx-auto px-4">
        <Outlet />
      </div>
    </div>
  );
};
