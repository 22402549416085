import { useParams } from "react-router-dom";
import { UserDetails } from "../../components/UserDetails";
import { useGetUserQuery } from "../../libs/user/api";
import { Skeleton } from "../../components/Skeleton";

export default function UserProfile() {
  const { id } = useParams();

  const { data: user, isLoading, error } = useGetUserQuery(id || "");
  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !user) {
    return <div>Error...</div>;
  }
  return (
    <div>
      <UserDetails user={user} />
    </div>
  );
}
