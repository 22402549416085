import { DietFromApiDay, DietTableMacros } from "../../libs/diet/types";
import { getKcal } from "../../libs/utils/diet";

type PerWeightTableProps = {
  weeksAverage: {
    CARB: number;
    PROTEIN: number;
    FAT: number;
  }[];
  weight: number;
};
export const PerWeightTable: React.FC<PerWeightTableProps> = ({
  weeksAverage,
  weight,
}) => {
  const rowBgColorDark = "bg-gray-50 dark:bg-gray-700";
  const rowBgColorLight = "bg-white dark:bg-gray-900";
  const rowBgColor = (index: number) =>
    index % 2 !== 0 ? rowBgColorDark : rowBgColorLight;

  // const perWeightMacros = (week: DietFromApiDay[]) => {
  //   const weekMacros = week.map((day) => day.dietParameters);
  //   const weekMacrosAvg = weekMacros.reduce(
  //     (acc, curr) => {
  //       curr.forEach((param) => {
  //         const value =
  //           typeof param.value === "string"
  //             ? parseInt(param.value)
  //             : param.value;
  //         const v = typeof value === "number" ? value : 0;

  //         if (param.key === "CARB") {
  //           acc.CARB += v;
  //         }
  //         if (param.key === "PROTEIN") {
  //           acc.PROTEIN += v;
  //         }
  //         if (param.key === "FAT") {
  //           acc.FAT += v;
  //         }
  //       });
  //       return acc;
  //     },
  //     { CARB: 0, PROTEIN: 0, FAT: 0 }
  //   );
  //   return {
  //     CARB: Math.ceil(weekMacrosAvg.CARB / weight),
  //     PROTEIN: Math.ceil(weekMacrosAvg.PROTEIN / weight),
  //     FAT: Math.ceil(weekMacrosAvg.FAT / weight),
  //   };
  // };
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
      <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="text-md">
            <th scope="col" className="px-4 py-3 text-xs">
              Media Macro / Peso
            </th>
            {weeksAverage.map((week, i) => (
              <th
                key={"Week-" + (i + 1)}
                scope="col"
                className="px-6 py-3 text-center "
              >
                Week {i + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(DietTableMacros).map((row, index) => (
            <>
              <tr
                key={row + index}
                className={`${rowBgColor(index)} dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {row}
                </th>
                {weeksAverage.map((week, i) => {
                  const weekAvg = week;
                  const value =
                    weekAvg[row as keyof typeof DietTableMacros] / weight;
                  const kcal = getKcal(
                    value,
                    row as keyof typeof DietTableMacros
                  );
                  return (
                    <td className="px-6 py-4">
                      {value.toFixed(2)} gr / {kcal.toFixed(2)} kcal
                    </td>
                  );
                })}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};
