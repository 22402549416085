import {
  Controller,
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormRegister,
  UseFormRegisterReturn,
} from "react-hook-form";
import { Exercise } from "../../libs/exercises/types";
import { addExercise } from "../../libs/workout/slice";
import { EXERCISE_TYPES } from "../../libs/exercises/types";
import { useAppDispatch } from "../../store";
import { SubmitButton, DeleteButton, PlusButton } from "../Button";
import { unique } from "../../libs/utils/array";
import { Checkbox, Label } from "flowbite-react";

type ExerciseFormProps = {
  dayIndex: number;
  exercises: Exercise[];
  filteredExercises: Exercise[];
  exToCloneIndex?: number;
  exType?: keyof typeof EXERCISE_TYPES;
  onClose: () => void;
};

type FormValues = {
  exerciseId: string | null;
  exerciseIds: {
    id: string | null;
  }[];
  type: string;
  deleteClone: boolean;
};
export const ExerciseForm: React.FC<ExerciseFormProps> = ({
  dayIndex,
  exercises,
  onClose,
  filteredExercises,
  exToCloneIndex,
  exType,
}) => {
  const dispatch = useAppDispatch();
  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      exerciseId: null,
      exerciseIds: [{ id: null }],
      type: exType || EXERCISE_TYPES.DEFAULT,
      deleteClone: false,
    },
  });
  const watchType = watch("type");
  const {
    fields: exerciseSelects,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "exerciseIds",
    rules: {
      required: "Inserisci almeno due esercizi",
    },
  });
  const watchFieldArray = watch("exerciseIds");

  const onSubmit = (formData: FormValues) => {
    if (!formData.type || dayIndex === undefined) {
      console.error("Exercise not found");
      return;
    }
    type ExerciseToAdd = {
      id: string | null;
      name: string | null;
      videoUrls: string[];
      tags: string[];
      subExercises?: { id: string; name: string }[];
    };
    let exToAdd: ExerciseToAdd = {
      id: null as null | string,
      name: null as null | string,
      videoUrls: [] as string[],
      tags: [] as string[],
      subExercises: [] as { id: string; name: string }[],
    };

    if (formData.type === EXERCISE_TYPES.SUPERSET) {
      formData.exerciseIds.forEach((formExercise: { id: string | null }) => {
        const exercise = exercises.find(
          (exer) => exer.id + "" === formExercise.id + ""
        );
        if (!exercise) return;
        exToAdd.id = exToAdd.id
          ? `${exToAdd.id}-${exercise.id}`
          : `${exercise.id}`;
        exToAdd.name = !exToAdd.name
          ? `SUPERSET - ${exercise.name}`
          : `${exToAdd.name} + ${exercise.name}`;
        exToAdd.videoUrls = [...exToAdd.videoUrls, ...exercise.videoUrls];
        exToAdd.tags = [...exToAdd.tags, ...exercise.tags];
        exToAdd.subExercises?.push({
          id: exercise.id + "",
          name: exercise.name,
        });
      });
    } else if (formData.type === EXERCISE_TYPES.STRIPPING) {
      const exIdForm = formData.exerciseId;
      const exercise = exercises.find((ex) => ex.id === exIdForm);
      if (!exercise) {
        console.warn("Exercise id not found");
        return;
      }

      exToAdd = {
        ...exercise,
        name: "STRIPPING - " + exercise.name,
        subExercises: undefined,
      };
    } else {
      const exIdForm = formData.exerciseId;
      const exercise = exercises.find((ex) => ex.id === exIdForm);
      if (!exercise) {
        console.warn("Exercise id not found");
        return;
      }

      exToAdd = { ...exercise, subExercises: undefined };
    }
    if (!exToAdd.id || !exToAdd.name) return;
    dispatch(
      addExercise({
        dayIndex: dayIndex,
        exToCloneIndex: exToCloneIndex,
        removeExToClone: formData.deleteClone,
        exercise: {
          id: exToAdd.id,
          name: exToAdd.name,
          type: formData.type as unknown as keyof typeof EXERCISE_TYPES,
          videoUrls: exToAdd.videoUrls?.filter(unique),
          tags: exToAdd.tags?.filter(unique),
          subExercises: exToAdd.subExercises,
          sets: [],
          completed: false,
          notes: "",
        },
      })
    );
    onClose();
  };
  return (
    <form className="space-y-6 p-4" onSubmit={handleSubmit(onSubmit)}>
      {watchType !== EXERCISE_TYPES.SUPERSET && (
        <ExerciseSelect
          register={register("exerciseId", {
            required: "Inserisci una categoria valida",
          })}
          errors={errors}
          exercises={exercises}
          filteredExercises={filteredExercises}
          isLast={true}
        />
      )}
      {watchType === EXERCISE_TYPES.SUPERSET && (
        <div>
          {exerciseSelects.map((field, index) => {
            if (exerciseSelects.length > index + 1) {
              return (
                <div
                  key={field.id}
                  className="max-w-[80%] relative flex flex-col space-y-2 m-auto"
                >
                  <div
                    id={"exercise" + (index ? index : "")}
                    className={`"ml-6 block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
                  >
                    {
                      exercises?.find(
                        (ex) => ex?.id === watchFieldArray[index]?.id
                      )?.name
                    }
                  </div>
                </div>
              );
            }
            return (
              <div
                key={field.id}
                className="max-w-[80%] relative flex flex-col space-y-2 m-auto"
              >
                <ExerciseSelect
                  register={register(`exerciseIds.${index}.id`, {
                    required: "Inserisci una categoria valida",
                  })}
                  errors={errors}
                  exercises={exercises}
                  index={index}
                  remove={() => remove(index)}
                  isLast={index === exerciseSelects.length - 1}
                  filteredExercises={filteredExercises}
                />
              </div>
            );
          })}
          {exerciseSelects.length < 5 && (
            <div className="max-w-[50%] m-auto pt-4 flex justify-center">
              <PlusButton variant="default" onClick={() => append({ id: "" })}>
                Aggiungi altro esercizio
              </PlusButton>
            </div>
          )}
        </div>
      )}
      <ExerciseTypeRadios
        register={register}
        control={control}
        disable={!!exType}
      />

      <div className="w-full">
        <div className="flex justify-between">
          <SubmitButton isPending={false}>Aggiungi esercizio</SubmitButton>
          {typeof exToCloneIndex === "number" && (
            <div>
              <Checkbox id="deleteClone" {...register("deleteClone")} />
              <Label className="ml-4" htmlFor="delete">
                Elimina esercizio da clonare
              </Label>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const ExerciseSelect: React.FC<{
  register: UseFormRegisterReturn;
  exercises: Exercise[];
  errors: FieldErrors<FormValues>;
  index?: number;
  remove?: () => void;
  filteredExercises?: Exercise[];
  isLast?: boolean;
}> = ({
  register,
  errors,
  exercises,
  index,
  remove,
  filteredExercises,
  isLast,
}) => {
  const multiSelect = index !== undefined;
  const exerciseArray =
    isLast && filteredExercises ? filteredExercises : exercises;

  return (
    <>
      {multiSelect && index >= 1 && (
        <div className="absolute bottom-0 left-[-30px]">
          <DeleteButton variant="icon" onClick={() => remove && remove()} />
        </div>
      )}
      <select
        {...register}
        id={"exercise" + (index ? index : "")}
        className={`${
          multiSelect && "ml-6"
        } block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
      >
        <option defaultValue={""} value={""} disabled>
          Seleziona un Esercizio
        </option>
        {exerciseArray.slice(0, 20).map((exercise) => (
          <option
            key={exercise.id}
            value={exercise.id}
            className="appearance-none"
          >
            <span>{exercise.name}</span>
          </option>
        ))}
      </select>
      {errors.exerciseId && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.exerciseId?.message?.toString()}
        </p>
      )}
    </>
  );
};
export const ExerciseTypeRadios: React.FC<{
  register: UseFormRegister<FormValues>;
  control: any;
  disable?: boolean;
}> = ({ register, control, disable }) => {
  return (
    <>
      <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
        Seleziona una tipologia:
      </h3>
      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <Controller
          control={control}
          name={"type"}
          defaultValue={EXERCISE_TYPES.DEFAULT}
          render={({ field: { onChange, ...props } }) => (
            <>
              {Object.keys(EXERCISE_TYPES).map((type) => (
                <li
                  key={type}
                  className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                >
                  <div className="flex items-center pl-3">
                    <input
                      {...register("type")}
                      id="horizontal-list-radio-license"
                      type="radio"
                      value={type}
                      disabled={disable}
                      onChange={(e) => onChange(e.target.value)}
                      name="list-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor="horizontal-list-radio-license"
                      className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {type}
                    </label>
                  </div>
                </li>
              ))}
            </>
          )}
        />
      </ul>
    </>
  );
};
