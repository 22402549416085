import {
  Exercise,
  Workout,
  WorkoutsResponse,
  WORKOUT_TYPES,
  ExerciseSetFromServer,
  FeedbackQuestion,
} from "./types";

export const getExerciseFromWorkouts = (
  exerciseId: string,
  workouts?: Workout
): Exercise | null => {
  if (!workouts || !workouts.data) {
    return null;
  }
  for (const week of workouts?.data) {
    for (const day of week.days) {
      for (const exercise of day.exercises) {
        if (exercise.exercise.id + "" === exerciseId) {
          return exercise.exercise;
        }
      }
    }
  }

  return null;
};

export const getIsTemplate = (workout: Workout) =>
  workout.workoutType === WORKOUT_TYPES.TEMPLATE;

export const cloneWorkoutWithoutIds = (workout: Workout) => {
  const clone = JSON.parse(JSON.stringify(workout)) as Workout;

  // @ts-ignore
  clone.id = null;
  // @ts-ignore
  clone.userId = null;
  if (clone.data) {
    clone.data = clone.data.map((week) => ({
      ...week,
      id: null,
      workoutId: null,
      feedbackForm: null,
      days:
        week.days?.map((day) => ({
          ...day,
          id: null,
          idWeek: null,
          exercises:
            day.exercises?.map((exercise) => ({
              ...exercise,
              id: null,
              idDay: null,
              exercise: {
                ...exercise.exercise,
                completed: false,
                sets:
                  exercise?.exercise?.sets?.map((set) => ({
                    reps: set.reps,
                    rest: set.rest,
                    repsRange: set.repsRange,
                  })) || [],
              },
            })) || [],
        })) || [],
    }));
  }
  return clone;
};

export const getClonedRepsFromExercise = (
  exercise: Exercise,
  setToClone: ExerciseSetFromServer,
  repsKey: "reps" | "repsDone" | "repsRange"
): ExerciseSetFromServer["reps"] => {
  // @ts-ignore
  return exercise.type === "SUPERSET" &&
    Array.isArray(setToClone[repsKey]) &&
    // @ts-ignore
    setToClone[repsKey].length > 0 &&
    // @ts-ignore
    setToClone[repsKey][0].id
    ? // @ts-ignore
      setToClone[repsKey].map((rep, idx) => {
        if (exercise?.subExercises && exercise?.subExercises[idx]) {
          return {
            ...exercise.subExercises[idx],
            // @ts-ignore
            value: (rep?.value as number) || 0,
          };
        }
        return undefined;
      })
    : setToClone[repsKey];
};

export const calculateFeedbackOutcomePercentage = (
  questions: FeedbackQuestion[]
): number => {
  const answeredQuestions = questions.filter((q) => q.value);
  // every question is worth 100% if value is "2", 50% if value is "1" and 0% if value is "0"
  const totalPercentage = answeredQuestions.reduce(
    (acc, q) => acc + parseInt(q.value),
    0
  );
  return (totalPercentage / (answeredQuestions.length * 2)) * 100;
};
