import { EXERCISE_TAGS, EXERCISE_TYPES, Exercise } from "./types";
import { ExerciseSetFromServer } from "../workout/types";
export const getCategoryLabel = (category: string) => {
  switch (category) {
    case EXERCISE_TAGS.FULL_BODY:
      return "Full Body";
    case EXERCISE_TAGS.UPPER_BODY:
      return "Upper Body";
    case EXERCISE_TAGS.LOWER_BODY:
      return "Lower Body";
    default:
      return "Unknown";
  }
};
export const getTypeLabel = (type: keyof typeof EXERCISE_TYPES) => {
  switch (type) {
    case EXERCISE_TYPES.DEFAULT:
      return "DEFAULT";
    case EXERCISE_TYPES.SUPERSET:
      return "SUPERSET";
    case EXERCISE_TYPES.STRIPPING:
      return "STRIPPING";
    default:
      return "DEFAULT";
  }
};

export const searchExercise = (
  exercises: Exercise[],
  searchValue: string,
  setSearchInput: (value: string) => void,
  setFilteredResults: (value: Exercise[]) => void
) => {
  setSearchInput(searchValue);
  if (searchValue !== "") {
    const filteredData =
      exercises?.filter((exercise) => {
        const item = {
          name: exercise.name,
          type: getTypeLabel(exercise.type),
          tags: exercise.tags.join(" "),
        };
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }) || [];
    setFilteredResults(filteredData);
  } else {
    setFilteredResults(exercises);
  }
};

export const getTonnage = (exerciseSets: ExerciseSetFromServer[]) => {
  if (!exerciseSets) {
    return 0;
  }
  if (exerciseSets.length === 0) {
    return 0;
  }

  // SUPERSET RETURN ARRAY OF OBJECTS WITH TONNAGE FOR EACH EXERCISE
  if (
    Array.isArray(exerciseSets[0].reps) &&
    exerciseSets[0].reps.length > 0 &&
    typeof exerciseSets[0].reps[0] === "object"
  ) {
    const exercises = exerciseSets[0].reps.map((rep) => {
      if (typeof rep === "number") {
        return {
          id: "",
          name: "",
          value: 0,
        };
      }
      return {
        ...rep,
        value: 0,
      };
    });

    exerciseSets.forEach((set, index) => {
      const tonnageReps =
        set.repsDone && Array.isArray(set.repsDone) && set.repsDone.length > 0
          ? set.repsDone
          : set.repsRange &&
            Array.isArray(set.repsRange) &&
            set.repsRange.length > 0
          ? null
          : set.reps;
      if (!tonnageReps || !set.weight) {
        return;
      }

      if (set.weight === 0) {
        return;
      }

      if (!Array.isArray(tonnageReps) || tonnageReps.length === 0) {
        return;
      }

      tonnageReps.forEach((rep, repIndex) => {
        if (
          // @ts-ignore
          !rep.value ||
          !set.weight ||
          // @ts-ignore
          !set.weight[repIndex] ||
          // @ts-ignore
          !set.weight[repIndex].value
        ) {
          return;
        }
        // @ts-ignore
        exercises[repIndex].value += rep.value * set.weight[repIndex].value;
      });
    });

    return exercises;
  }

  return exerciseSets.reduce((acc, set) => {
    let setTonnage = 0;

    if (!set.reps || !set.weight) {
      return acc;
    }

    if (set.reps === 0 || set.weight === 0) {
      return acc;
    }

    if (typeof set.reps === "number" && typeof set.weight === "number") {
      const tonnageReps =
        typeof set.repsDone === "number"
          ? set.repsDone
          : typeof set.repsRange === "number"
          ? 0
          : set.reps;
      setTonnage = tonnageReps * set.weight;
    }

    if (
      Array.isArray(set.reps) &&
      set.reps.length > 0 &&
      typeof set.reps[0] === "number"
    ) {
      const tonnageReps =
        Array.isArray(set.repsDone) &&
        set.repsDone.length > 0 &&
        typeof set.repsDone[0] === "number"
          ? set.repsDone
          : set.reps;
      // @ts-ignore
      setTonnage = tonnageReps.reduce((acc, rep) => {
        const weight = set.weight as number;
        const castedRep = rep as number;
        const castedAcc = acc as number;
        if (typeof castedRep !== "number") {
          return acc;
        }
        if (typeof weight !== "number") {
          return acc;
        }

        return castedAcc + castedRep * weight;
      }, 0) as number;
    }

    return acc + setTonnage;
  }, 0);
};
