import { Skeleton } from "../../components/Skeleton";
import { UsersTable } from "../../components/UsersTable";
import { WorkoutsTable } from "../../components/WorkoutsTable";
import { WorkoutTemplateTable } from "../../components/WorkoutTemplatesTable";
import { useGetAllWorkoutQuery } from "../../libs/workout/api";

export default function Workout() {
  const { data, isLoading, error } = useGetAllWorkoutQuery();
  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <div>
      <WorkoutTemplateTable templates={data.templates} />
      {/* <WorkoutsTable workouts={data.workouts} /> */}
    </div>
  );
}
