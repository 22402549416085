import {
  addIntegrator,
  updateIntegrator,
  removeIntegrator,
} from "../../libs/diet/slice";
import { DietTableIntegrationsItem } from "../../libs/diet/types";
import { useAppDispatch } from "../../store";
import { DeleteButton, PlusButton } from "../Button";
import { TableInput } from "./shared";

type IntegratiorsRowProps = {
  classes: string;
  integrations: DietTableIntegrationsItem[];
  isEditMode: boolean;
};

export const IntegratorsRow: React.FC<IntegratiorsRowProps> = ({
  integrations,
  isEditMode,
  classes,
}) => {
  const dispatch = useAppDispatch();

  const updateIntegratorsKey = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    dispatch(
      updateIntegrator({
        integratorItem: {
          key: e.target.value,
          value: integrations[index].value,
        },
        index,
      })
    );
  };
  const updateIntegratorsValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    dispatch(
      updateIntegrator({
        integratorItem: {
          key: integrations[index].key,
          value: e.target.value,
        },
        index,
      })
    );
  };
  const deleteIntegrator = (index: number) => {
    dispatch(
      removeIntegrator({
        index,
      })
    );
  };
  return (
    <tr key={"Integrators"} className={`${classes} dark:border-gray-700`}>
      <th
        scope="row"
        className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        INTEGRATORI
      </th>
      <td className="px-6 py-2 uppercase">
        <tr>
          {integrations &&
            getIntegrationsKeys(
              integrations,
              isEditMode,
              updateIntegratorsKey
            ).map((cell, i) => (
              <th key={"integrKey" + i} className="px-6 uppercase">
                {cell}
              </th>
            ))}
        </tr>

        <tr>
          {integrations &&
            getIntegrationsValues(
              integrations,
              isEditMode,
              updateIntegratorsValue,
              deleteIntegrator
            ).map((cell, i) => (
              <td key={"integrValue" + i} className="px-6 py-2">
                {cell}
              </td>
            ))}
          {isEditMode && (
            <td className="px-6 py-2">
              <PlusButton
                onClick={() =>
                  dispatch(
                    addIntegrator({
                      integratorItem: {
                        key: "",
                        value: "",
                      },
                    })
                  )
                }
                variant="icon"
              />
            </td>
          )}
        </tr>
      </td>
    </tr>
  );
};

const getIntegrationsKeys = (
  integrations: DietTableIntegrationsItem[],
  isEditMode: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void
) => {
  return integrations.map((integ, i) =>
    isEditMode ? (
      <InteInput value={integ.key} onChange={(e) => onChange(e, i)} />
    ) : (
      <td className="px-6">{integ.key}</td>
    )
  );
};
const getIntegrationsValues = (
  integrations: DietTableIntegrationsItem[],
  isEditMode: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void,
  onDelete: (i: number) => void
) => {
  return integrations.map((integ, i) =>
    isEditMode ? (
      <>
        <InteInput value={integ.value} onChange={(e) => onChange(e, i)} />
        <DeleteButton onClick={() => onDelete(i)} variant="icon" />
      </>
    ) : (
      <td className="px-6 uppercase">{integ.value}</td>
    )
  );
};

export const InteInput: React.FC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}> = ({ value, onChange, label }) => {
  return (
    <td className="px-2 flex-row">
      <input
        value={value}
        onChange={(e) => onChange(e)}
        className="w-[120px] h-[40px] p-4 my-1 text-center text-xs bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 px-2 py-1 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      {label && <span>{label}</span>}
    </td>
  );
};
