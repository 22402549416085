import {
  Controller,
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormRegister,
  UseFormRegisterReturn,
} from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store";
import { SubmitButton, DeleteButton, PlusButton } from "../Button";
import { User } from "../../libs/user/types";
import { useUpdateUserMutation } from "../../libs/user/api";
import { useEditWorkout } from "../../libs/workout/hooks";
import {
  useCreateWorkoutMutation,
  useGetWorkoutQuery,
} from "../../libs/workout/api";
import { useGetAllExercisesQuery } from "../../libs/exercises/api";
import { cloneWorkoutWithoutIds } from "../../libs/workout/utils";
import { Workout, WORKOUT_TYPES } from "../../libs/workout/types";
import { Header } from "../UsersTable/Header";
import { useEffect, useState } from "react";

type AssignToUserFormProps = {
  workoutId: number;
  users: User[];
  onClose: () => void;
};

type FormValues = {
  userId: string;
};
export const AssignToUserForm: React.FC<AssignToUserFormProps> = ({
  workoutId,
  users,
  onClose,
}) => {
  const { data: workout } = useGetWorkoutQuery(workoutId + "");
  const [createWorkout, { isLoading, error }] = useCreateWorkoutMutation();

  const usersWithoutWrk = users.filter((user) => !user.workouts?.length);

  const [filteredResults, setFilteredResults] = useState(usersWithoutWrk);
  const [searchInput, setSearchInput] = useState("");

  const perPage = 10;
  useEffect(() => {
    setSearchInput("");
    setFilteredResults(usersWithoutWrk);
  }, [users]);

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      const filteredData = users.filter((user) => {
        const item = {
          name: user.name,
          surname: user.surname,
        };
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(users);
    }
  };

  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      userId: "",
    },
  });

  const onSubmit = (formData: FormValues) => {
    if (!formData.userId) {
      console.error("User not found");
      return;
    }
    const originalWrk = workout;
    if (!originalWrk) {
      console.error("Workout not found");
      return;
    }
    const newWrk = cloneWorkoutWithoutIds(originalWrk);
    newWrk.userId = parseInt(formData.userId);
    newWrk.workoutType = WORKOUT_TYPES.DEFAULT;
    createWorkout(newWrk).then((res: any) => {
      if (res.data) {
        onClose();
      }
    });
  };
  return (
    <form className="space-y-6 p-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <div className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Search"
              required={false}
              value={searchInput}
              autoFocus
              onChange={(e) => {
                searchItems(e.target.value);
                // don't loose focus on input
                setTimeout(() => {
                  e.target.focus();
                }, 0);
              }}
            />
          </div>
        </div>
      </div>
      <UserSelect
        register={register("userId", { required: "Seleziona un utente" })}
        errors={errors}
        users={filteredResults}
      />
      <div className="w-full">
        <SubmitButton isPending={isLoading}>Assegna workout</SubmitButton>
        {error && <p> OPS! C'è stato un errore. Riprova più tardi!</p>}
      </div>
    </form>
  );
};

const UserSelect: React.FC<{
  register: UseFormRegisterReturn;
  users: User[];
  errors: FieldErrors<FormValues>;
  remove?: () => void;
}> = ({ register, errors, users, remove }) => {
  return (
    <>
      <select
        {...register}
        id={"userId"}
        className={
          "block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
        }
      >
        <option defaultValue={""} value={""} disabled>
          Seleziona un Utente
        </option>
        {users.slice(0, 10).map((user) => (
          <option key={user.id} value={user.id || -1}>
            {user.name} {user.surname}
          </option>
        ))}
      </select>
      {errors.userId && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          {errors.userId?.message?.toString()}
        </p>
      )}
    </>
  );
};
