import { useState } from "react";
import { useGetDietQuery } from "../../libs/diet/api";
import {
  NutritionItem,
  NutritionKeys,
  NutritionResponse,
} from "../../libs/nutrition/types";
import { getTotalKcal } from "../../libs/nutrition/utils";
import {
  getDay,
  getListByWeek,
  getMonth,
  getToday,
  getWeek,
  getWeekYear,
  getWeekYearBefore,
  isSunday,
} from "../../libs/utils/date";
import { getKcal } from "../../libs/utils/diet";
import { Skeleton } from "../Skeleton";
import { useGetHistoryQuery } from "../../libs/history/api";
import {
  WeightMeasure,
  WeightMeasuresResponse,
} from "../../libs/measures/types";

const columns = ["Data", "Peso"];
NutritionKeys.forEach((key) => {
  columns.push(key);
});
columns.push("Calorie");

type WeightTableProps = {
  userId?: number;
  dietId: string | null;
  isFemale?: boolean;
};
type WeightsListItem = {
  date: string;
  weight?: number;
  hasPeriod?: boolean;
  historyId?: number | null;
  lastWeekAverage?: number | null;
  lastWeekNutritionAverage?: {
    [key: string]: number | null;
  };
  weekBeforeAverage?: number | null;
  nutrition?: NutritionItem | null;
  totalKcal: number | null;
};
export const WeightTable: React.FC<WeightTableProps> = ({
  dietId,
  userId,
  isFemale,
}) => {
  const [showAll, setShowAll] = useState(false);
  const {
    data: weights,
    isLoading,
    error,
  } = useGetHistoryQuery(userId || -1, {
    selectFromResult: (result) => ({
      isLoading: result.isLoading,
      error: result.error,
      data: (result.data?.weightHistoryList || {}) as WeightMeasuresResponse,
    }),
  });
  const { data: nutrition } = useGetHistoryQuery(userId || -1, {
    selectFromResult: (result) => ({
      data: (result.data?.nutritionHistoryList || {}) as NutritionResponse,
    }),
  });
  const { data: diet } = useGetDietQuery(dietId || "");
  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !weights) {
    return <div>Error...</div>;
  }

  const weeklyDifference = diet?.goals ?? 0;

  const weightsList: WeightsListItem[] =
    Object.keys(weights)?.map((key) => {
      const parsedWeight = parseFloat(weights[key]?.weight || "");
      return {
        date: key,
        weight: parsedWeight,
        hasPeriod: weights[key]?.hasPeriod,
        historyId: weights[key]?.historyId,
        nutrition: nutrition?.[key] ?? null,
        totalKcal: getTotalKcal(nutrition?.[key] ?? {}),
      };
    }) || [];

  const weightListByWeek = getListByWeek(weightsList) as WeightsListItem[][];

  const getWeekAverage = (weekList: WeightsListItem[]) => {
    const weekSum = weekList.reduce((acc, item) => {
      return acc + (item?.weight || 0);
    }, 0);
    // calc week length without null values
    const weekLength = weekList.reduce((acc, item) => {
      return acc + (item?.weight ? 1 : 0);
    }, 0);
    const weekAverage = weekSum / weekLength;
    return !isNaN(weekAverage) ? weekAverage : 0;
  };
  const getWeekAverageNutrition = (weekList: WeightsListItem[]) => {
    const weekSumObj = weekList.reduce((acc, item) => {
      const obj = {
        total: item.totalKcal,
      } as { [key: string]: number | null };
      NutritionKeys.forEach((key) => {
        let value = item?.nutrition?.[key] ?? null;
        if (!value) {
          value = "0";
        }
        obj[key] = parseInt(value) ?? null;
      });
      Object.keys(obj).forEach((key) => {
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key] += obj[key] ?? 0;
      });
      return acc;
    }, {} as any);
    const weekAverageObj = {} as { [key: string]: number | null };
    // calc week length without null values
    const weekLength = weekList.reduce((acc, item) => {
      return acc + (item?.nutrition ? 1 : 0);
    }, 0);
    Object.keys(weekSumObj).forEach((key) => {
      let avg = weekSumObj[key] / weekLength;
      weekAverageObj[key] = !isNaN(avg) ? avg : null;
    });
    return weekAverageObj;
  };
  const nutritionAvgByWeek = weightListByWeek.map((week) => {
    return {
      ...getWeekAverageNutrition(week),
    };
  });
  const weightAvgByWeek = weightListByWeek.map((week) => {
    return getWeekAverage(week);
  });

  const isLastDayOfTheWeekAvailable = (i: number) => {
    if (isSunday(weightsList[i].date)) {
      return true;
    }
    const curWeek = getWeekYear(new Date(weightsList[i].date));
    const nextWeek =
      getWeekYear(new Date(weightsList[i - 1]?.date || "")) || -1;
    if (nextWeek === -1) {
      return false;
    }
    if (curWeek !== nextWeek) {
      return true;
    }
    return false;
  };
  const listToRender =
    weightsList.map((item, idx) => {
      if (!isLastDayOfTheWeekAvailable(idx)) {
        return item;
      }
      const isFirstWeek =
        Object.keys(weightAvgByWeek)[0] ===
        getWeekYear(new Date(item.date)) + "";
      return {
        ...item,
        lastWeekAverage:
          weightAvgByWeek[getWeekYear(new Date(item.date))] || null,
        lastWeekNutritionAverage: {
          ...nutritionAvgByWeek[getWeekYear(new Date(item.date))],
        },

        weekBeforeAverage: isFirstWeek
          ? weightsList[weightsList.length - 1].weight
          : weightAvgByWeek[getWeekYearBefore(new Date(item.date))],
      };
    }) || [];

  const dateList = Object.keys(weights) || [];

  // @ts-ignore
  if (dateList.length === 0 || !weights[dateList[0]]?.weight) {
    return (
      <div className="text-xs text-gray-700 uppercase dark:text-gray-400 mt-8">
        Non ci sono misurazioni
      </div>
    );
  }

  // if (isFemale) {
  //   columns.push("Ciclo");
  // }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
      <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="text-md">
            {columns.map((column) => (
              <th key={column} scope="col" className="px-6 py-3 text-center ">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listToRender.map((item, index) => (
            <WeightRow
              key={item.date}
              date={item.date}
              item={item}
              index={index}
              isFemale={isFemale}
              goals={weeklyDifference}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

type ExerciseRowProps = {
  date: string;
  index: number;
  isFemale?: boolean;
  item: WeightsListItem;
  goals: number | string;
};
const WeightRow: React.FC<ExerciseRowProps> = ({
  date,
  index,
  item,
  isFemale,
  goals,
}) => {
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";

  return (
    <>
      {typeof item.lastWeekAverage === "number" && item.lastWeekAverage > 0 && (
        <SundayRecap item={item} goals={goals} />
      )}
      <tr key={date} className={`${rowBgColor} dark:border-gray-700`}>
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {getDay(date)}, {date.split("-")[2]} {getMonth(date)}{" "}
          {date.split("-")[0]}
        </th>

        <td className="px-6 py-4 relative">
          {item.weight?.toFixed(1)} KG{" "}
          {isFemale && item.hasPeriod && (
            <span className="w-[8px] h-[8px] rounded-full bg-red-800 absolute top-[23px] right-[13px]" />
          )}
        </td>
        {NutritionKeys.map((key) => {
          return (
            <td key={key + "-" + item.date} className="px-6 py-4 relative">
              {(item?.nutrition &&
                item?.nutrition[key] &&
                item.nutrition[key] + " GR") ||
                "-"}
            </td>
          );
        })}
        <td className="px-6 py-4 relative">
          {(item.totalKcal && item.totalKcal + " KCAL") || "-"}
        </td>
      </tr>
    </>
  );
};

const SundayRecap: React.FC<{
  item: WeightsListItem;
  goals: number | string;
}> = ({ item, goals }) => {
  const diff =
    item?.weekBeforeAverage && item?.lastWeekAverage
      ? item.lastWeekAverage - item.weekBeforeAverage
      : null;

  const isPositive = () => {
    if (!diff || !goals) {
      return null;
    }
    const goalToKg =
      typeof goals === "string" ? parseInt(goals) / 1000 : goals / 1000;
    if (goalToKg > 0 && diff > goalToKg) {
      return true;
    }
    if (goalToKg < 0 && diff < goalToKg) {
      return true;
    }

    return false;
  };
  return (
    <tr
      className={`bg-primary-50 dark:bg-primary-900 border-t-2 border-primary-200 dark:border-primary-800`}
    >
      <th
        scope="row"
        className="px-6 py-4 font-bold text-gray-800 whitespace-nowrap dark:text-white"
      >
        Media Settimanale
      </th>

      <td className="px-6 py-4 dark:text-white font-bold">
        <div className="flex-column justify-center items-center">
          <div className="text-xs font-bold">
            {item.lastWeekAverage?.toFixed(1)} KG
          </div>
          {typeof diff === "number" && (
            <div
              className={`text-xs font-bold ${
                isPositive() ? "text-green-500" : "text-red-500"
              }`}
            >
              {diff > 0 ? "+" : ""}
              {diff.toFixed(1)} KG
            </div>
          )}
        </div>
      </td>
      {NutritionKeys.map((key) => {
        const avg = item.lastWeekNutritionAverage?.[key] ?? null;
        return (
          <td
            key={key + "-" + item.date}
            className="px-6 py-4 relative font-bold"
          >
            {(avg && avg.toFixed(0) + " GR") || "-"}
          </td>
        );
      })}
      <td className="px-6 py-4 relative font-bold">
        {(item.lastWeekNutritionAverage &&
          item.lastWeekNutritionAverage["total"] &&
          item.lastWeekNutritionAverage["total"].toFixed(0) + " KCAL") ||
          "-"}
      </td>
    </tr>
  );
};
