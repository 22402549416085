import { Tooltip } from "flowbite-react";
import {
  cloneLastSet,
  moveExercise,
  removeExercise,
} from "../../libs/workout/slice";
import { ExerciseSetFromServer } from "../../libs/workout/types";
import { useAppDispatch } from "../../store";
import { CloneButton, DeleteButton } from "../Button";
import { showReps, showWeight } from "../WorkoutTemplateTables";
import { getTonnage } from "../../libs/exercises/utils";

type ExerciseRowProps = {
  dayIndex: number;
  exercise: {
    name: string;
    weeks: {
      sets: ExerciseSetFromServer[] | undefined;
      notes: string | undefined;
      isCompleted: boolean | undefined;
    }[];
  };
  index: number;
  setOpenSetModal: (weekIndex: number) => void;
  setOpenExerciseModal?: (exerciseIndex: number) => void;
  isTemplate?: boolean;
  isEditMode?: boolean;
  isLastRow: boolean;
  isLastDay: boolean;
};
export const ExerciseRow: React.FC<ExerciseRowProps> = ({
  dayIndex,
  exercise,
  index,
  setOpenSetModal,
  setOpenExerciseModal,
  isTemplate,
  isEditMode,
  isLastRow,
  isLastDay,
}) => {
  const dispatch = useAppDispatch();
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";

  // template string example: "day-0-exercise-0"
  const id = `day-${dayIndex}-exercise-${index}`;
  return (
    <tr className={`${rowBgColor} dark:border-gray-700`}>
      {isEditMode && (
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          <div className="flex flex-row items-center justify-between">
            <div className="inline-flex flex-col space-y-3">
              {(index > 0 || dayIndex > 0) && (
                <button
                  type="button"
                  onClick={() => {
                    // toExerciseIndex null if need to move to another day (lenght unknown)
                    const toExerciseIndex = index > 0 ? index - 1 : null;
                    const toDayIndex = index > 0 ? dayIndex : dayIndex - 1;
                    dispatch(
                      moveExercise({
                        dayIndex: dayIndex,
                        exerciseIndex: index,
                        toExerciseIndex: toExerciseIndex,
                        toDayIndex: toDayIndex,
                      })
                    );
                  }}
                >
                  <svg
                    className="w-5 h-5 text-primary-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 8"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
                    />
                  </svg>
                </button>
              )}
              {!(isLastRow && isLastDay) && (
                <button
                  type="button"
                  onClick={() => {
                    // toExerciseIndex null if need to move to another day (lenght unknown)
                    const toExerciseIndex = isLastRow ? 0 : index + 1;
                    const toDayIndex = isLastRow ? dayIndex + 1 : dayIndex;
                    dispatch(
                      moveExercise({
                        dayIndex: dayIndex,
                        exerciseIndex: index,
                        toExerciseIndex: toExerciseIndex,
                        toDayIndex: toDayIndex,
                      })
                    );
                  }}
                >
                  <svg
                    className="w-5 h-5 text-primary-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 8"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                    />
                  </svg>
                </button>
              )}
            </div>
            <DeleteButton
              variant="icon"
              onClick={() =>
                dispatch(
                  removeExercise({
                    dayIndex: dayIndex,
                    exerciseIndex: index,
                  })
                )
              }
            />
            <CloneButton
              variant="icon"
              onClick={() =>
                setOpenExerciseModal && setOpenExerciseModal(index)
              }
            />
          </div>
        </th>
      )}
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 dark:text-white"
      >
        {exercise.name}
      </th>
      {exercise.weeks.map((week, i) => (
        <td
          key={i + "-ex-cell-week" + dayIndex}
          className="px-6 py-4 align-top"
        >
          {week.sets && week.sets?.length > 0 && (
            <div className="flex flex-col ">
              {!isTemplate && (
                <div className="px-2 pb-1 min-h-[25px]">
                  {week.isCompleted ? <Checkmark /> : ""}
                </div>
              )}
              <button
                onClick={() => setOpenSetModal(i)}
                type="button"
                className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.6rem] px-2 py-1.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
              >
                <Tooltip
                  className="p-2 text-xs"
                  theme={{ target: "" }}
                  content={`${exercise.name} / DAY ${dayIndex + 1} / WEEK ${
                    i + 1
                  }`}
                >
                  <div className="flex flex-col ">
                    <div className="flex flex-row justify-between items-center">
                      <span>SET</span>
                      <span>REPS</span>
                      <span>REST</span>
                      {week.isCompleted ? <span>KG</span> : ""}
                    </div>
                    {week.sets.map((set, inx) => (
                      <div
                        key={inx + "" + set.rest + "" + inx + "" + dayIndex}
                        className="flex flex-row justify-between items-center"
                      >
                        <span>{inx + 1} º</span>
                        <span> {showReps(set.reps, set.repsRange)}</span>
                        <span> {set.rest}</span>
                        {week.isCompleted ? (
                          <span>
                            {" "}
                            {set.weight ? showWeight(set.weight) : "-"}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                </Tooltip>
              </button>
              {week.isCompleted && (
                <>
                  <div className="px-2 py-4 text-xs">
                    <span>Tonnellaggio: </span>
                    <Tonnage sets={week.sets} />
                  </div>
                </>
              )}
              <div className="px-6 py-4 line-clamp-2 text-xs">
                <Tooltip className="p-2 text-xs" content={week.notes}>
                  {week.notes}
                </Tooltip>
              </div>
            </div>
          )}
          {isEditMode && i > 0 && (!week.sets || !(week.sets?.length > 0)) && (
            <button
              onClick={() =>
                dispatch(
                  cloneLastSet({
                    weekIndex: i,
                    dayIndex: dayIndex,
                    exerciseIndex: index,
                  })
                )
              }
              type="button"
              className=" text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.6rem] px-2 py-1.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              CLONA SET PRECEDENTE
            </button>
          )}
          {isEditMode && (!week.sets || !(week.sets?.length > 0)) && (
            <button
              onClick={() => setOpenSetModal(i)}
              type="button"
              className="mt-4 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[0.6rem] px-2 py-1.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              APRI
            </button>
          )}
        </td>
      ))}
    </tr>
  );
};

const Checkmark = () => {
  return (
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-blue-600 lg:w-6 lg:h-6 dark:text-blue-300 m-auto"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

//@ts-ignore
const Tonnage: React.FC<{ sets: ExerciseSetFromServer[] }> = ({ sets }) => {
  const tonnage = getTonnage(sets);

  if (typeof tonnage === "number") {
    return <span> {tonnage} kg</span>;
  }
  if (tonnage.length > 0) {
    return tonnage.map((tonnage, index) => (
      <div
        key={index}
        className="flex flex-row justify-between text-left items-center py-1"
      >
        <span>{tonnage.name}</span>
        <span>{tonnage.value}kg</span>
      </div>
    ));
  }
};
