"use client";

import { set, useForm } from "react-hook-form";
import { useUpdateExerciseMutation } from "../../libs/exercises/api";
import { Exercise, EXERCISE_TYPES } from "../../libs/exercises/types";
import { SuccessAlert } from "../Alerts";
import { SubmitButton } from "../Button";
import { Modal } from "../Modal";
import { ExerciseForm } from "./ExerciseForm";

type EditExerciseFormProps = {
  openModal: string | undefined;
  setOpenModal: (value: string | undefined) => void;
  exerciseToUpdate?: Exercise;
};

export type FormValues = {
  name: string;
  tags: string[];
  video: string;
  type: keyof typeof EXERCISE_TYPES;
  notes?: string;
};

export const EditExerciseModal: React.FC<EditExerciseFormProps> = ({
  openModal,
  setOpenModal,
  exerciseToUpdate,
}) => {
  const [updateExercise, { data: exercise, isLoading, error }] =
    useUpdateExerciseMutation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>();
  const onSubmit = (data: FormValues) => {
    updateExercise({
      id: exerciseToUpdate?.id || "",
      name: data.name,
      tags: data.tags,
      videoUrls: [data.video],
      type: data.type,
      notes: data.notes,
    }).catch((err) => console.log(err));
  };

  const SubmitBtn = () => (
    <>
      {!exercise && (
        <div className="w-full">
          <SubmitButton isPending={isLoading}>Modifica esercizio</SubmitButton>
        </div>
      )}
    </>
  );
  return (
    <Modal
      title="Modifica questo esercizio"
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <ExerciseForm
        register={register}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        formBtn={SubmitBtn()}
        exerciseToUpdate={exerciseToUpdate}
        setValue={setValue}
      />

      {exercise && (
        <SuccessAlert>
          <div>
            <span className="font-medium">Modifica avventua con successo!</span>{" "}
            Ora puoi utilizzare questo nuovo esercizio nei tuoi workouts
          </div>
        </SuccessAlert>
      )}
      {error && (
        <p
          role="alert"
          className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
        >
          OPS! La modifica non è andata a buon fine. Riprova
        </p>
      )}
    </Modal>
  );
};
