"use client";

import { useForm } from "react-hook-form";
import {
  useCreateUserMutation,
  useUpdateUserProfileMutation,
} from "../../libs/user/api";
import { SubmitButton, WhatsAppButton } from "../Button";
import { Modal } from "../Modal";
import { type } from "os";

type CreateUserFormProps = {
  openModal: string | undefined;
  setOpenModal: (value: string | undefined) => void;
};

type CreateUserForm = {
  tel: string;
  name: string;
  surname: string;
};

export const CreateUserModal: React.FC<CreateUserFormProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [createUser, { data: token, isLoading, error }] =
    useCreateUserMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<CreateUserForm>();
  const onSubmit = (data: CreateUserForm) => {
    let phoneNumber = data.tel;
    if (!phoneNumber.startsWith("+39")) {
      phoneNumber = "+39" + phoneNumber;
    }
    createUser({
      phoneNumber,
      name: data.name,
      surname: data.surname,
    })
      .unwrap()
      .then(() => {
        updateUserProfile({
          profileInfo: { showInitialForm: true },
          currentUser: { phoneNumber, name: data.name, surname: data.surname },
        });
      });
  };

  return (
    <Modal
      title="Registra un nuovo utente"
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <form className="space-y-6 p-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Nome
          </label>
          <input
            {...register("name", { required: "Inserisci un nome valido" })}
            aria-invalid={errors.name ? "true" : "false"}
            type="name"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder=""
            required
          />
        </div>
        {errors.name && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.name?.message?.toString()}
          </p>
        )}
        <div>
          <label
            htmlFor="surname"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cognome
          </label>
          <input
            {...register("surname", {
              required: "Inserisci un cognome valido",
            })}
            aria-invalid={errors.surname ? "true" : "false"}
            type="surname"
            id="surname"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder=""
            required
          />
        </div>
        {errors.surname && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.surname?.message?.toString()}
          </p>
        )}
        <div>
          <label
            htmlFor="phone"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Numero di telefono
          </label>
          <input
            {...register("tel", { required: "Inserisci un numero valido" })}
            aria-invalid={errors.tel ? "true" : "false"}
            type="tel"
            id="phone"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="333xxxxxx"
            required
          />
        </div>
        {errors.tel && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            {errors.tel?.message?.toString()}
          </p>
        )}
        {error && (
          <p
            role="alert"
            className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
          >
            OPS! La creazione non è andata a buon fine. Riprova
          </p>
        )}
        {!token && (
          <div className="w-full">
            <SubmitButton isPending={isLoading}>
              Crea un nuovo utente
            </SubmitButton>
          </div>
        )}
        {token && (
          <div className="w-auto">
            <WhatsAppButton
              tel={watch().tel}
              message={"Iscriviti ed inserisci questo codice: " + token}
            />
          </div>
        )}
      </form>
    </Modal>
  );
};
