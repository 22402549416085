import { useGetAllExercisesQuery } from "../../libs/exercises/api";
import { Workout } from "../../libs/workout/types";

type MuscleSummaryTableProps = {
  workout: Workout;
  weeksColumns: string[];
};
export const MuscleSummaryTable: React.FC<MuscleSummaryTableProps> = ({
  workout,
  weeksColumns,
}) => {
  const { data: exercises, isLoading, error } = useGetAllExercisesQuery();
  if (isLoading) {
    return <></>;
  }
  if (error || !exercises || !workout || !workout.data || !workout.data[0]) {
    return <></>;
  }
  let bodyDistricts = [] as string[];
  exercises.forEach((ex) => {
    const m = ex.tags && ex.tags[0] && ex.tags[0].split("-")[0];
    if (m) {
      if (bodyDistricts.indexOf(m) === -1) {
        bodyDistricts.push(m);
      }
    }
  });

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-8">
      <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="text-md">
            <th scope="col" colSpan={1} className="px-6 py-3">
              Muscolo
            </th>
            <th scope="col" colSpan={1} className="px-6 py-3">
              Frequenza settiamanale
            </th>
            {weeksColumns.map((column) => (
              <th
                key={column}
                scope="col"
                colSpan={1}
                className="px-6 py-3 text-center"
              >
                SETS - {column}
              </th>
            ))}
          </tr>
        </thead>
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"></thead>
        <tbody>
          {bodyDistricts.map((district, i) => (
            <tr key={+"-" + district} className="even:bg-gray-100 odd:bg-white">
              <th scope="col" className="px-6 py-3">
                {district}
              </th>
              <th scope="col" className="px-6 py-3">
                {getWeekFrequencyForBodyDistrict(workout, district, 0)}
              </th>
              {weeksColumns.map((column, index) => (
                <th key={index} scope="col" className="px-6 py-3 text-center">
                  {getSetWeekAmountForBodyDistrict(workout, district, index)}
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getSetWeekAmountForBodyDistrict = (
  workout: Workout,
  district: string,
  weekIndex: number
) => {
  let amount = 0;

  workout.data[weekIndex].days?.forEach((day) => {
    day.exercises?.forEach((ex) => {
      if (ex.exercise.tags && ex.exercise.tags[0]) {
        ex.exercise.tags.forEach((tag) => {
          if (tag.split("-")[0] === district) {
            amount += ex.exercise.sets.length;
          }
        });
      }
    });
  });
  return amount;
};

const getWeekFrequencyForBodyDistrict = (
  workout: Workout,
  district: string,
  weekIndex: number
) => {
  let amount = 0;

  workout.data[weekIndex].days?.forEach((day) => {
    day.exercises?.forEach((ex) => {
      if (ex.exercise.tags && ex.exercise.tags[0]) {
        ex.exercise.tags.forEach((tag) => {
          if (tag.split("-")[0] === district) {
            amount += 1;
          }
        });
      }
    });
  });
  return amount;
};
