import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormField, PersonalProfileFormType } from "./fields";
import { useAppSelector } from "../../../store";
import { useParams } from "react-router";
import { useEffect } from "react";

type UserProfileFormItemProps = {
  fieldObj: FormField;
  register: UseFormRegister<PersonalProfileFormType>;
  errors: FieldErrors<PersonalProfileFormType>;
  setValue: UseFormSetValue<PersonalProfileFormType>;
  isEditMode: boolean;
};

export const InfoItem: React.FC<UserProfileFormItemProps> = ({
  fieldObj,
  register,
  isEditMode,
  setValue,
  errors,
}) => {
  const { id: userId } = useParams();
  const defaultValue = useAppSelector((state) =>
    fieldObj.selector(state, userId || "")
  );
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldObj.name, defaultValue + "");
    }
  }, [defaultValue]);

  const input = () => {
    if (fieldObj.type === "radio") {
      return (
        <SelectFormInput
          register={register}
          fieldObj={fieldObj}
          isDisabled={!isEditMode || fieldObj.disabled}
        />
      );
    }
    if (fieldObj.type === "textarea") {
      return (
        <textarea
          className={
            "text-sm font-medium text-gray-800 pl-0 border-x-0 border-t-0 border-b-2 border-gray-300 " +
            fieldObj.styles
          }
          placeholder={fieldObj.placeholder}
          disabled={!isEditMode || fieldObj.disabled}
          {...register(fieldObj.name, {
            ...fieldObj.rules,
            required: false,
          })}
        />
      );
    }
    return (
      <input
        className="text-sm font-medium text-gray-800 border-none pl-0 w-fit"
        placeholder={fieldObj.placeholder}
        type={fieldObj.type}
        disabled={!isEditMode || fieldObj.disabled}
        {...register(fieldObj.name, {
          ...fieldObj.rules,
          required: false,
        })}
      />
    );
  };
  return (
    <div className="flex-col items-center justify-between mb-4 pr-4 w-full">
      <label className="text-sm font-medium text-gray-400 dark:text-gray-500">
        {fieldObj.label}
      </label>
      <div className="flex-col">
        {input()}
        {errors[fieldObj.name] && (
          <div className="text-red-500 text-xs">
            {errors[fieldObj.name]?.message}
          </div>
        )}
      </div>
    </div>
  );
};

type SelectFormInputType = {
  register: UseFormRegister<PersonalProfileFormType>;
  fieldObj: FormField;
  isDisabled?: boolean;
};
export const SelectFormInput: React.FC<SelectFormInputType> = ({
  register,
  fieldObj,
  isDisabled,
}) => {
  return (
    <div className="flex items-center justify-between mb-4 w-3/4 min-w-[80px]">
      <select
        id={fieldObj.name}
        disabled={isDisabled}
        {...register(fieldObj.name, {
          ...fieldObj.rules,
          required: false,
        })}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        {fieldObj.fields?.map((field, i) => (
          <option key={field + "" + i} value={field.value}>
            {field.label}
          </option>
        ))}
      </select>
    </div>
  );
};
