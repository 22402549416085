export type Exercise = {
  id: string;
  name: string;
  tags: string[];
  type: keyof typeof EXERCISE_TYPES;
  notes?: string;
  videoUrls: string[];
};
export const EXERCISE_TYPES = {
  DEFAULT: "DEFAULT",
  SUPERSET: "SUPERSET",
  STRIPPING: "STRIPPING",
} as const;

export const EXERCISE_TAGS = {
  FULL_BODY: "FULL_BODY",
  UPPER_BODY: "UPPER_BODY",
  LOWER_BODY: "LOWER_BODY",
} as const;

export type ExercisesResponse = ExerciseFromApi[];

export type ExerciseFromApi = {
  id: string;
  desc: string;
  exercisesData: string;
  type: keyof typeof EXERCISE_TYPES;
  note?: string;
  link?: string;
  sets: string[];
};

export type ExerciseRequest = Omit<ExerciseFromApi, "id">;
