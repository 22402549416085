import { Workout } from "../../libs/workout/types";

export const getColumns = (isTemplate: boolean) => {
  const WeeksSubColumnsLabels = ["Set"];
  if (isTemplate) {
    return ["Set"];
  }
  return WeeksSubColumnsLabels;
};

export const getExercisesRowsObj = (workout: Workout, dayIndex: number) => {
  if (!workout.data) return [];

  return workout.data[0].days[dayIndex].exercises.map((exercise, index) => {
    return {
      name: exercise.exercise?.name,
      weeks:
        (workout.data &&
          workout.data.map((week) => {
            // const currEx =
            //   week.days[dayIndex] &&
            //   week.days[dayIndex].exercises?.find(
            //     (ex) => ex.exercise.id === exercise.exercise.id
            //   );
            const currEx = week.days[dayIndex].exercises[index];
            return {
              sets: currEx?.exercise?.sets,
              isCompleted: currEx?.exercise?.completed,
              notes: currEx?.exercise?.notes,
            };
          })) ||
        [],
    };
  });
};
