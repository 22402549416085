import { api } from "../api";
import { HISTORY_TYPES, HistoryItem, HistoryResponse } from "../history/types";
import { convertTimestampToDate } from "../utils/date";
import {
  NutritionItem,
  NutritionKeys,
  NutritionRequest,
  NutritionResponse,
} from "./types";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({}),
  overrideExisting: false,
});

export const {} = extendedApi;

export function transformHistoryNutrition(
  response: HistoryResponse
): NutritionResponse {
  return (
    response
      .filter(
        (item: HistoryItem) => item.historyType === HISTORY_TYPES.NUTRITION
      )
      .sort((a, b) => b.date - a.date)
      .reduce((acc: NutritionResponse, item: HistoryItem, i) => {
        const nutritionItem = NutritionKeys.reduce((acc, key) => {
          const parameter = item.historyParameters?.find(
            (param) => param.key === key
          );
          if (parameter) {
            acc[key] = parameter.value ?? undefined;
          }
          return acc;
        }, {} as NutritionItem);
        if (nutritionItem.CARB === undefined) {
          return acc;
        }

        nutritionItem.historyId = item.id ?? undefined;
        acc[convertTimestampToDate(item.date)] = nutritionItem;
        return acc;
      }, {} as NutritionResponse) || {}
  );
}
