import { Modal } from "flowbite-react";
import React from "react";
import { AddHistoryForm } from "./HistoryForm/HistoryForm";

type SetHistoryModalProps = {
  closeModal: () => void;
  showModal: boolean;
};
export const SetHistoryModal: React.FC<SetHistoryModalProps> = ({
  closeModal,
  showModal,
}) => {
  return (
    <Modal popup show={showModal} onClose={closeModal}>
      <Modal.Header></Modal.Header>
      <Modal.Body className="pb-4">
        <AddHistoryForm />
      </Modal.Body>
    </Modal>
  );
};
