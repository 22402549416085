import {
  selectActivityLevel,
  selectAddress,
  selectBirthDate,
  selectEmail,
  selectFiscalCode,
  selectGender,
  selectGoal,
  selectHeight,
  selectLeanBodyMass,
  selectName,
  selectPaymentNotes,
  selectPhoneNumber,
  selectProfileNotes,
  selectStepsPerDay,
  selectSurname,
  selectUserStatus,
  selectWeeklyWorkoutDays,
  selectWeight,
  selectWorkoutDuration,
  selectWorkoutSince,
  selectWorkoutTimeSlot,
} from "../../../libs/user/selectors";
import {
  ACTIVITY_LEVELS,
  STEPS_PER_DAY,
  WEEKLY_WORKOUT_DAYS,
  WORKOUT_DURATION,
  WORKOUT_SINCE,
  WORKOUT_TIME_SLOT,
} from "../../../libs/user/types";
import { RootState } from "../../../store";

export type PersonalProfileFormType = {
  leanBodyMass: string;
  weight: string;
  height: string;
  birthdate: string;
  fiscalCode: string;
  address: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  gender: "M" | "F";
  activityLevel: keyof typeof ACTIVITY_LEVELS;
  weeklyWorkoutDays: (typeof WEEKLY_WORKOUT_DAYS)[number];
  workoutDuration: (typeof WORKOUT_DURATION)[number];
  workoutTimeSlot: keyof typeof WORKOUT_TIME_SLOT;
  stepsPerDay: keyof typeof STEPS_PER_DAY;
  workoutSince: keyof typeof WORKOUT_SINCE;
  goal: string;

  profileNotes: string;
  paymentNotes: string;
  status: "ACTIVE" | "DISABLED";
};
type FormFieldName = keyof PersonalProfileFormType;

export type FormField = {
  name: FormFieldName;
  label: string;
  placeholder: string;
  rules: {
    required: string | boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
    validate?: Record<string, (value: string) => boolean | string>;
  };
  type: string;
  rightAddon?: string;
  leftAddon?: string;
  onChangeTransformer?: (v: string) => string;
  fields?: { value: string; label: string }[];
  selector: (state: RootState, userId: string) => string | number | undefined;
  disabled?: boolean;
  styles?: string;
};

export const PERSONAL_PROFILE_FORM_FIELDS: FormField[] = [
  {
    name: "height",
    label: "Altezza",
    placeholder: "Inserisci l'altezza",
    rules: {
      required: false,
      validate: {
        height: (value: string) => {
          if (!value) return true;

          const height = parseInt(value);
          return height >= 100 && height <= 250 && value.match(/^[0-9]*$/)
            ? true
            : "Inserisci un valore compreso tra 100 e 250";
        },
      },
    },
    type: "text",
    rightAddon: "cm",
    selector: selectHeight,
  },
  {
    name: "leanBodyMass",
    label: "Massa Magra",
    placeholder: "Inserisci la massa magra",
    rules: {
      required: false,
      validate: {
        leanBodyMass: (value: string) => {
          if (!value) return true;

          const leanBodyMass = parseInt(value);
          return leanBodyMass >= 10 &&
            leanBodyMass <= 150 &&
            value.match(/^[0-9]*$/)
            ? true
            : "Inserisci un valore compreso tra 10 e 150";
        },
      },
    },
    type: "text",
    rightAddon: "kg",
    selector: selectLeanBodyMass,
  },
  {
    name: "weight",
    label: "Peso",
    placeholder: "Inserisci il peso",
    rules: {
      required: false,
      validate: {
        weight: (value: string) => {
          if (!value) return true;

          const weight = parseInt(value);
          return weight >= 30 && weight <= 400 && value.match(/^[0-9]*$/)
            ? true
            : "Inserisci un valore compreso tra 30 e 400";
        },
      },
    },
    type: "text",
    rightAddon: "cm",
    selector: selectWeight,
  },
  {
    name: "birthdate",
    label: "Data di nascita",
    placeholder: "gg/mm/aaaa",
    rules: {
      required: false,
      pattern: {
        value: /(\d{2})\/(\d{2})\/(\d{4})/,
        message: "Inserisci una data valida (gg/mm/aaaa)",
      },
      validate: {
        birthdate: (value?: string) => {
          const splitted = value?.split("/");
          if (!value) return true;
          if (splitted?.length !== 3) {
            return "Inserisci una data valida";
          }
          const dt = new Date(`${splitted[1]}/${splitted[0]}/${splitted[2]}`);
          return !isNaN(dt.getTime()) &&
            dt < new Date() &&
            dt > new Date(1900, 0, 1)
            ? true
            : "Inserisci una data valida";
        },
      },
    },
    type: "text",
    selector: selectBirthDate,
  },

  {
    name: "email",
    label: "Email",
    placeholder: "Inserisci l'email",
    rules: {
      required: false,
      pattern: {
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Inserisci un'email valida",
      },
    },
    type: "text",
    selector: selectEmail,
  },
  {
    name: "phoneNumber",
    label: "Numero di telefono*",
    placeholder: "Inserisci il numero di telefono",
    rules: {
      required: "Campo obbligatorio",
      pattern: {
        value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        message: "Inserisci un numero di telefono valido",
      },
      validate: {
        phoneNumber: (value?: string) => {
          return value?.startsWith("+39")
            ? true
            : "Inserisci un numero italiano (+39)";
        },
      },
    },
    type: "text",
    selector: selectPhoneNumber,
  },
  {
    name: "name",
    label: "Nome*",
    placeholder: "Inserisci il nome",
    rules: {
      required: "Campo obbligatorio",
      validate: {
        name: (value?: string) => {
          if (!value) return "Inserisci un nome valido";
          return value.length > 2 && value.length < 25
            ? true
            : "Inserisci un nome valido";
        },
      },
    },
    type: "text",
    selector: selectName,
  },
  {
    name: "surname",
    label: "Cognome*",
    placeholder: "Inserisci il cognome",
    rules: {
      required: "Campo obbligatorio",
      validate: {
        name: (value?: string) => {
          if (!value) return "Inserisci un cognome valido";
          return value.length > 1 && value.length < 25
            ? true
            : "Inserisci un cognome valido";
        },
      },
    },
    type: "text",
    selector: selectSurname,
  },
  {
    name: "gender",
    label: "Sesso*",
    placeholder: "",
    rules: {
      required: "Campo obbligatorio",
    },
    type: "radio",
    fields: [
      { value: "M", label: "M" },
      { value: "F", label: "F" },
    ],
    selector: selectGender,
  },

  {
    name: "address",
    label: "Indirizzo",
    placeholder: "Inserisci l'indirizzo",
    rules: {
      required: false,
      validate: {
        address: (value: string) => {
          if (!value) return true;
          return value?.length > 5 ? true : "Inserisci un indirizzo valido";
        },
      },
    },
    type: "text",
    selector: selectAddress,
  },
  {
    name: "fiscalCode",
    label: "Codice fiscale",
    placeholder: "Inserisci il codice fiscale",
    rules: {
      required: false,
      pattern: {
        value: /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
        message: "Inserisci un codice fiscale valido",
      },
    },
    type: "text",
    onChangeTransformer: (value: string) => value.toUpperCase(),
    selector: selectFiscalCode,
  },
  {
    name: "activityLevel",
    label: "Livello di attività quotidiana",
    placeholder: "",
    rules: {
      required: false,
    },
    type: "radio",
    fields: [
      { value: ACTIVITY_LEVELS.VERY_ACTIVE, label: "Molto attivo" },
      { value: ACTIVITY_LEVELS.MODERATELY_ACTIVE, label: "Attivo" },
      { value: ACTIVITY_LEVELS.SEDENTARY, label: "Sedentario" },
      { value: "", label: "" },
    ],
    selector: selectActivityLevel,
  },
  {
    name: "weeklyWorkoutDays",
    label: "Giorni di allenamento settimanali",
    placeholder: "",
    rules: {
      required: false,
    },
    type: "radio",
    fields: [
      {
        value: "",
        label: "",
      },
    ].concat(WEEKLY_WORKOUT_DAYS.map((d) => ({ value: d, label: d }))),
    selector: selectWeeklyWorkoutDays,
  },
  {
    name: "workoutDuration",
    label: "Durata dell'allenamento",
    placeholder: "",
    rules: {
      required: false,
    },
    type: "radio",
    fields: [
      {
        value: "",
        label: "",
      },
    ].concat(WORKOUT_DURATION.map((d) => ({ value: d, label: d }))),
    selector: selectWorkoutDuration,
  },
  {
    name: "workoutTimeSlot",
    label: "Fascia oraria preferita per l'allenamento",
    placeholder: "",
    rules: {
      required: false,
    },
    type: "radio",
    fields: [
      { value: "", label: "" },
      { value: WORKOUT_TIME_SLOT.MORNING, label: "Mattina" },
      { value: WORKOUT_TIME_SLOT.AFTERNOON, label: "Pomeriggio" },
      { value: WORKOUT_TIME_SLOT.EVENING, label: "Sera" },
    ],
    selector: selectWorkoutTimeSlot,
  },
  {
    name: "stepsPerDay",
    label: "Numero di passi giornalieri*",
    placeholder: "",
    rules: {
      required: "Campo obbligatorio",
    },
    type: "radio",
    fields: [
      { value: STEPS_PER_DAY.LOW, label: "<7000" },
      { value: STEPS_PER_DAY.MEDIUM, label: "7000-14000" },
      { value: STEPS_PER_DAY.HIGH, label: ">14000" },
    ],
    selector: selectStepsPerDay,
  },
  {
    name: "workoutSince",
    label: "Da quanti anni ti alleni",
    placeholder: "",
    rules: {
      required: false,
    },
    type: "radio",
    fields: [
      { value: WORKOUT_SINCE.LOW, label: "<1" },
      { value: WORKOUT_SINCE.MEDIUM, label: "2-4" },
      { value: WORKOUT_SINCE.HIGH, label: ">5" },
    ],
    selector: selectWorkoutSince,
  },
  {
    name: "status",
    label: "Status",
    placeholder: "",
    rules: {
      required: false,
    },
    type: "radio",
    fields: [
      { value: "ACTIVE", label: "ATTIVO" },
      { value: "DISABLED", label: "DISABILITATO" },
    ],
    selector: selectUserStatus,
  },
  {
    name: "goal",
    label: "Obiettivi",
    placeholder: "",
    rules: {
      required: false,
      validate: {
        name: (value: string) => {
          return (value?.length >= 0 && value?.length < 200) || !value
            ? true
            : "Lunghezza massima 200 caratteri";
        },
      },
    },
    type: "textarea",
    styles: "h-[200px] w-full",
    selector: selectGoal,
  },
  {
    name: "paymentNotes",
    label: "Pagamenti",
    placeholder: "",
    rules: {
      required: false,
      validate: {
        name: (value: string) => {
          return (value?.length >= 0 && value?.length < 400) || !value
            ? true
            : "Lunghezza massima 400 caratteri";
        },
      },
    },
    type: "textarea",
    styles: "h-[200px] w-[200px]",
    selector: selectPaymentNotes,
  },
  {
    name: "profileNotes",
    label: "Problemi/Appunti",
    placeholder: "",
    rules: {
      required: false,
      validate: {
        name: (value: string) => {
          return (value?.length >= 0 && value?.length < 400) || !value
            ? true
            : "Lunghezza massima 400 caratteri";
        },
      },
    },
    type: "textarea",
    styles: "h-[200px] w-[400px]",
    selector: selectProfileNotes,
  },
];
