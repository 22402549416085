import { transform } from "typescript";
import { api } from "../api";
import { HistoryData, HistoryResponse } from "./types";
import {
  transformHistoryMeasureBody,
  transformHistoryMeasureBodyImgs,
  transformHistoryMeasureBodyRequest,
  transformHistoryMeasureWeight,
  transformHistoryMeasureWeightRequest,
} from "../measures/api";
import { transformHistoryNutrition } from "../nutrition/api";
import {
  BodyMeasuresRequest,
  WeightMeasuresRequest,
  WeightMeasuresResponse,
} from "../measures/types";

const transformHistoryData = (response: HistoryResponse): HistoryData => {
  return {
    measureHistoryList: transformHistoryMeasureBody(response),
    imgHistoryList: transformHistoryMeasureBodyImgs(response),
    weightHistoryList: transformHistoryMeasureWeight(response),
    nutritionHistoryList: transformHistoryNutrition(response),
  };
};

const historyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query<HistoryData, number>({
      query: (userId) => ({
        url: `/history/${userId}`,
        method: "GET",
      }),
      providesTags: ["History"],
      transformResponse: transformHistoryData,
    }),
    setWeightMeasures: builder.mutation<
      void,
      { weightMeasures: WeightMeasuresRequest; userId: number }
    >({
      query: ({ weightMeasures, userId }) => ({
        url: `/history/`,
        method: "POST",
        body: transformHistoryMeasureWeightRequest(weightMeasures, userId),
      }),
      invalidatesTags: ["History"],
    }),
    setBodyMeasures: builder.mutation<
      void,
      { bodyMeasures: BodyMeasuresRequest; userId: number }
    >({
      query: ({ bodyMeasures, userId }) => {
        const bodyMeasuresReq = transformHistoryMeasureBodyRequest(
          bodyMeasures,
          userId
        );
        return {
          url: "/history/",
          method: "POST",
          body: bodyMeasuresReq,
        };
      },
      invalidatesTags: ["History"],
    }),
    deleteHistory: builder.mutation<void, number>({
      query: (historyId) => ({
        url: `/history/${historyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["History"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetHistoryQuery,
  useSetBodyMeasuresMutation,
  useSetWeightMeasuresMutation,
  useDeleteHistoryMutation,
  endpoints,
} = historyApi;
