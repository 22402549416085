import { NutritionItem } from "../../libs/nutrition/types";
import { Skeleton } from "../Skeleton";
import {
  useDeleteHistoryMutation,
  useGetHistoryQuery,
} from "../../libs/history/api";

import { MeasureCalculatedLeanBodyMassRow, MeasureRow } from "./Rows";
import {
  BodyMeasureKeys,
  PlicometryMeasureKeys,
} from "../../libs/measures/types";
import { DeleteButton, PlusButton } from "../Button";
import { useState } from "react";
import { SetHistoryModal } from "./SetHistoryModal";

type HistoryTableProps = {
  userId?: number | null;
};

export const HistoryTable: React.FC<HistoryTableProps> = ({ userId }) => {
  const [showModal, setShowModal] = useState(false);
  const { data: history, isLoading, error } = useGetHistoryQuery(userId || -1);

  const [deleteHistory, { isLoading: isLoadingDeleteHistory }] =
    useDeleteHistoryMutation();

  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !history) {
    return <div>Error...</div>;
  }
  const { measureHistoryList, weightHistoryList } = history;
  const dates = [
    ...Object.keys(measureHistoryList),
    ...Object.keys(weightHistoryList),
  ];

  const handleDelete = (date: string) => {
    measureHistoryList[date] &&
      deleteHistory(measureHistoryList[date].historyId || -1);
    weightHistoryList[date] &&
      deleteHistory(weightHistoryList[date].historyId || -1);
  };

  const uniqueDates = new Set(dates.sort().reverse());
  const columns = ["INFO"].concat(...uniqueDates);

  return (
    <div className="relative overflow-x-auto rounded-lg my-8">
      <div className="mb-8">
        <PlusButton variant="default" onClick={() => setShowModal(true)}>
          Aggiungi misurazione
        </PlusButton>
      </div>
      {dates.length === 0 ? (
        <div className="text-xs text-gray-700 uppercase dark:text-gray-400 mt-8 ">
          Non ci sono misurazioni
        </div>
      ) : (
        <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="text-md">
              {columns.map((column, i) => {
                return (
                  <th
                    key={column + "DELETE"}
                    scope="col"
                    className="px-6 py-3 text-center "
                  >
                    {i !== 0 && (
                      <DeleteButton
                        onClick={() => handleDelete(column)}
                        isPending={isLoadingDeleteHistory}
                        variant="icon"
                      />
                    )}
                  </th>
                );
              })}
            </tr>
            <tr className="text-md">
              {columns.map((column, i) => (
                <th key={column} scope="col" className="px-6 py-3 text-center ">
                  {i === 0
                    ? column
                    : column.split("-")[2] +
                      "/" +
                      column.split("-")[1] +
                      "/" +
                      column.split("-")[0]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <MeasureRow
              index={0}
              dates={uniqueDates}
              name="weight"
              historyList={weightHistoryList}
            />
            {Object.keys(BodyMeasureKeys).map((key, index) => (
              <MeasureRow
                key={index}
                index={index + 1}
                dates={uniqueDates}
                name={key as keyof typeof BodyMeasureKeys}
                historyList={measureHistoryList}
              />
            ))}
            <tr className="border-2 border-primary-100">
              <td className="py-2 font-bold text-gray-900">PLICHE</td>
            </tr>
            {Object.keys(PlicometryMeasureKeys).map((key, index) => (
              <MeasureRow
                key={index}
                index={index + 1}
                dates={uniqueDates}
                name={key as keyof typeof PlicometryMeasureKeys}
                historyList={measureHistoryList}
              />
            ))}
            <MeasureCalculatedLeanBodyMassRow
              dates={uniqueDates}
              index={0}
              measureHistoryList={measureHistoryList}
              weightHistoryList={weightHistoryList}
            />
          </tbody>
        </table>
      )}
      <SetHistoryModal
        closeModal={() => setShowModal(false)}
        showModal={showModal}
      />
    </div>
  );
};
