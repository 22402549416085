import { api } from "../api";
import { transformUserResponse } from "../user/api";
import { User } from "../user/types";
import { UsersResponse } from "./types";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => ({
        url: `/user`,
        method: "GET",
      }),
      transformResponse: transformUsersResponse,
      providesTags: ["Users"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetUsersQuery } = extendedApi;

function transformUsersResponse(users: UsersResponse) {
  return users.map(transformUserResponse);
}
