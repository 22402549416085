import { DarkThemeToggle, useTheme, Navbar } from "flowbite-react";
import { HiMoon, HiSun } from "react-icons/hi";
import { ROUTES } from "../../constants/routes";
import { useEffect, useLayoutEffect } from "react";
import FitShapeLogo from "../../fitshapelogo.png";

const NAVBAR_ROUTES = [
  {
    route: ROUTES.DASHBOARD,
    title: "Dashboard",
  },
  {
    route: ROUTES.WORKOUTS,
    title: "Workout",
  },
  {
    route: ROUTES.EXERCISES,
    title: "Esercizi",
  },
];
type Props = {
  isLoggedIn: boolean;
  logout: () => void;
};

// var themeToggleDarkIcon = document.getElementById("theme-toggle-dark-icon");
// var themeToggleLightIcon = document.getElementById("theme-toggle-light-icon");

// Change the icons inside the button based on previous settings
// if (
//   localStorage.getItem("color-theme") === "dark" ||
//   (!("color-theme" in localStorage) &&
//     window.matchMedia("(prefers-color-scheme: dark)").matches)
// ) {
//   themeToggleLightIcon?.classList.remove("hidden");
// } else {
//   themeToggleDarkIcon?.classList.remove("hidden");
// }

export const NavBar: React.FC<Props> = ({ isLoggedIn, logout }) => {
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl mx-auto p-4">
        <span className="flex items-center">
          <img src={FitShapeLogo} className="h-10 mr-3" alt="Fitshape Logo" />
        </span>
        {isLoggedIn && (
          <>
            <div className="inline-flex md:w-auto md:flex items-center md:order-2">
              <button
                type="button"
                onClick={logout}
                className=" text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
              >
                Logout
              </button>
            </div>
            <div
              id="mega-menu"
              className="items-center justify-center flex w-full mt-2 md:mt-0 md:w-auto md:order-1"
            >
              <ul className="flex flex-row font-medium md:flex-row md:space-x-8 md:mt-0">
                {NAVBAR_ROUTES.map(({ route, title }) => (
                  <li key={title}>
                    <a
                      href={route}
                      className="block py-2 pl-3 pr-4 text-blue-600 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 dark:text-blue-500 md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700"
                      aria-current="page"
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {/* <div className="hidden md:block">
          <button
            id="theme-toggle"
            type="button"
            className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
          >
            <svg
              id="theme-toggle-dark-icon"
              className="w-5 h-5 hidden"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
            </svg>
            <svg
              id="theme-toggle-light-icon"
              className="w-5 h-5 hidden"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div> */}
        {/* {mode === "dark" ? (
            <button
              onClick={() => setMode("light")}
              className="focus:outline-none"
            >
              <HiSun className="text-2xl text-yellow-500" />
            </button>
          ) : (
            <button
              onClick={() => setMode("dark")}
              className="focus:outline-none"
            >
              <HiMoon className="text-2xl text-gray-500" />
            </button>
          )} 
          </div> */}
      </div>
    </nav>
    // <Navbar fluid rounded>
    //   <Navbar.Brand href="/">
    //     <img
    //       alt="Flowbite React Logo"
    //       className="mr-3 h-6 sm:h-9"
    //       src={FitShapeLogo}
    //     />
    //     <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
    //       FitShape
    //     </span>
    //   </Navbar.Brand>
    //   <Navbar.Toggle />
    //   {isLoggedIn && (
    //     <Navbar.Collapse>
    //       <div className="flex items-center md:order-2">
    //         <button
    //           type="button"
    //           onClick={logout}
    //           className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
    //         >
    //           Logout
    //         </button>
    //       </div>
    //       {NAVBAR_ROUTES.map(({ route, title }) => (
    //         <Navbar.Link active href={route}>
    //           <p>{title}</p>
    //         </Navbar.Link>
    //       ))}
    //     </Navbar.Collapse>
    //   )}
    // </Navbar>
  );
};
